import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { Spin } from "antd";

const PrivateRoute = ({ component: Component, allowedRoles, ...rest }) => {
  const { isLogged } = useAuth();
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const role = localStorage.getItem("user_role");
    if (role) {
      setUserRole(role);
    }
  }, []);

  if (userRole == null) {
    return <Spin size="large" />;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        
        

        if (!isLogged) {
          return <Redirect to="/login" />;
        } else if (!allowedRoles.includes(userRole)) {
          return <Redirect to="/not-authorized" />;
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};

export default PrivateRoute;
