import React from "react";
import "./HomeCompanies.css";
import { Link } from "react-router-dom";
import { Row, Col, Button } from "antd";
import logo from "../../assets/images/logo orange.png";
import gy from "../../assets/images/brandpart.jpg";
import expertLogo from "../../assets/images/expectBack.jpg";
import expertBack from "../../assets/images/EXI.jpg";
import servihome from "../../assets/images/back-engie.jpg";
import sunlogo from "../../assets/images/back-luminus.jpg";
import sunsymbole from "../../assets/images/sunlight.jpg";
import servisymbol from "../../assets/images/sh.jpg";
import optinergy from "../../assets/images/back-engie.jpg";
import optisymbole from "../../assets/images/optyener.jpg";
import gysymbol from "../../assets/images/gy.jpg";

const CompanyCard = ({ logoFrontSrc, logoBackSrc, buttonText, buttonLink }) => {
	return (
		<Col span={{ lg: 5, md: 12, sm: 24 }}>

			<Link to={buttonLink}>
				<div className="card2">
					<div
						className="content2 front"
						style={{ backgroundImage: `url(${logoFrontSrc})` }}
					></div>
					<div
						className="content2-back back"
						style={{ backgroundImage: `url(${logoBackSrc})` }}
					>
						<Button className="custom-btn">{buttonText}</Button>
					</div>
				</div>
			</Link>
		</Col>
	);
};

const HomeCompanies = () => {
	return (
		<div className="home-companies-container" >
			<header className="header">
				<img src={logo} alt="Logo" className="logo" />
			</header>
			<Row justify="center" gutter={[16,16]} >
				<CompanyCard
					logoFrontSrc={sunsymbole}
					logoBackSrc={sunlogo}
					buttonText="Connexion"
					buttonLink="/login"
				/>
				<CompanyCard
					logoFrontSrc={expertLogo}
					logoBackSrc={expertBack}
					buttonText="Connexion"
					buttonLink="/expertinout"
				/>
				<CompanyCard
					logoFrontSrc={gysymbol}
					logoBackSrc={gy}
					buttonText="Connexion"
					buttonLink="/gandy-invest"
				/>
				<CompanyCard
					logoFrontSrc={servisymbol}
					logoBackSrc={servihome}
					buttonText="Connexion"
					buttonLink="/servihome"
				/>
				<CompanyCard
					logoFrontSrc={optisymbole}
					logoBackSrc={optinergy}
					buttonText="Connexion"
					buttonLink="/optinergy"
				/>
				<CompanyCard
					logoFrontSrc={optisymbole}
					logoBackSrc={optinergy}
					buttonText="Connexion"
					buttonLink="/brandpartners"
				/>
				<CompanyCard
					logoFrontSrc={optisymbole}
					logoBackSrc={optinergy}
					buttonText="Connexion"
					buttonLink="/wizoo"
				/>
				<CompanyCard
					logoFrontSrc={optisymbole}
					logoBackSrc={optinergy}
					buttonText="Connexion"
					buttonLink="/brandassurance"
				/>
			</Row>
		</div>
	);
};

export default HomeCompanies;
