import React, { useState } from "react";
import {
	Form,
	Row,
	Col,
	Card,
	Select,
	message,
	TreeSelect,
} from "antd";
import SaveButton from "../../../constants/SaveButton";

import { axiosClient } from "../../../api/axios";
import AnnulerBtn from "../../../constants/AnnulerBtn";
import { useAuth } from "../../../AuthContext";

const StatuerRdv = ({ rdv, onFormSubmit, setShowStatuerModal,setShowDetailModal, }) => {
	const [form] = Form.useForm();
	const [loadingAnnuler, setLoadingAnnuler] = useState(false);
	const [loadingEnregistrer, setLoadingEnregistrer] = useState(false);
	const [showTreeSelect, setShowTreeSelect] = useState(false);
	const [selectedStatus, setSelectedStatus] = useState("");
	const {userId} = useAuth()

	const rdvList = rdv.rdv || rdv;

	const handleGoback = () => {
    setLoadingAnnuler(true);
    setShowStatuerModal(false)
		setShowDetailModal(true)
};


	const handleFormSubmit = async () => {
		setLoadingEnregistrer(true);

		const formDataToSend = {
			status_ac: selectedStatus == "NOK" ? form.getFieldValue("reason") : "ok",
			modifiedBy: userId,
		};

		try {
			await axiosClient.put(`rdvs/${rdvList.id}/statuer-agent-commercial`, formDataToSend);

			setLoadingEnregistrer(false);
			setShowStatuerModal(false);
			// onFormSubmit(response.data);

			message.success("Statut du rendez-vous mis à jour avec succès !");
		} catch (error) {
			setLoadingEnregistrer(false);
			console.error("Error updating appointment status_ac:", error.response);
		}
	};

	const handleStatusChange = (value) => {
		setSelectedStatus(value)
		setShowTreeSelect(value == "NOK");
	};

	return (
		<Form layout="vertical" form={form} onFinish={handleFormSubmit}>

			<Card style={{ marginBottom: "10px",  }}>
				<Row justify="end" gutter={[16, 16]} >
					<Col >
					<AnnulerBtn
							loading={loadingAnnuler}
							buttonText="Retour"
							onClick={handleGoback}
							danger
						/>
					</Col>
					<Col>
						<SaveButton
							loading={loadingEnregistrer}
							buttonText="Enregistrer"
						/>
					</Col>

				</Row>
			</Card>
			<Card >

				<Row gutter={[16, 16]} >
					
					<Col span={24}>
						<p> Choisir votre statut</p></Col>
					<Col span={24} style={{marginTop:"-25px"}}>
						<Form.Item name="status_ac" >
							<Select onChange={handleStatusChange} placeholder="Statuer RDV" allowClear>
								<Select.Option value="ok">OK</Select.Option>
								<Select.Option value="NOK">NOK</Select.Option>
							</Select>
						</Form.Item>
					</Col>

					<Col span={24} style={{marginTop:"-25px"}}>
						{showTreeSelect && selectedStatus === "NOK" && (
							<Form.Item name="reason" >
								<TreeSelect
									showSearch
									style={{ width: '100%' }}
									dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
									placeholder="Choisir une raison"
									treeDefaultExpandAll
								>
									<TreeSelect.TreeNode value="nok_absent" title="NOK - Absent" />
									<TreeSelect.TreeNode value="nok_verifier" title="NOK - A vérifier" />
									<TreeSelect.TreeNode value="nok_adresse" title="NOK - Pas bonne adresse" />
									<TreeSelect.TreeNode value="nok_ht" title="NOK - HT" />
									<TreeSelect.TreeNode value="nok_tarif_social" title="NOK - Tarif social" />
								</TreeSelect>
							</Form.Item>
						)}
					</Col>


				</Row>
			</Card>
			
		</Form>
	);
};

export default StatuerRdv;
