import React from "react";
import { Card, Col, Row, Avatar, Descriptions, Tag } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import emailIcon from "../../../assets/images/mail.png";
import userIcon from "../../../assets/images/customer-service.png";
import roleIcon from "../../../assets/images/employee.png";
import statusIcon from "../../../assets/images/passive-candidate.png";
// import { baseURL2 } from "../../../api/axios";
import profilePic from '../../../assets/images/profile-user.png'
import { baseURL2 } from "../../../api/axios";


const UserDetail = ({ selectedRowData }) => {
	const { nom, prenom, email, role, image, isActive } = selectedRowData;
	const imageUrl = image ? `${baseURL2}/storage/${image}` : profilePic;

	return (
		<Card bordered={false} style={{ marginTop: 0, backgroundColor: "#F9F9F9" }}>
			<Row justify="center" align="middle" >
				<Col span={24} style={{ textAlign: "center" }}>

					<Avatar size={120}
						// src={profilePic}
						 src={`${baseURL2}/storage/${image}`}
						shape="circle" />
				</Col>
				<Col span={24} style={{ maxWidth: "500px", }}>
					<Descriptions
						bordered
						column={1}
						style={{ textAlign: 'left', backgroundColor: "#f9f9f9", borderRadius: "8px", padding: "20px" }}
					>
						<Descriptions.Item label={
							<span>
								<img
									src={userIcon}
									alt="User Icon"
									style={{ width: 25, height: 25, marginRight: 5 }}
								/>
								Nom
							</span>
						}>
							{nom} {prenom}
						</Descriptions.Item>
						<Descriptions.Item label={
							<span>
								<img
									src={emailIcon}
									alt="Email Icon"
									style={{ width: 25, height: 25, marginRight: 5 }}
								/>
								Email
							</span>
						}>
							{email}
						</Descriptions.Item>
						<Descriptions.Item label={
							<span>
								<img
									src={roleIcon}
									alt="Role Icon"
									style={{ width: 25, height: 25, marginRight: 5 }}
								/>
								Rôle
							</span>
						}>
							<span style={{ color: "#6b7280" }}>{role}</span>
						</Descriptions.Item>
						<Descriptions.Item label={
							<span>
								<img
									src={statusIcon}
									alt="status Icon"
									style={{ width: 25, height: 25, marginRight: 5 }}
								/>
								Statut
							</span>
						}>
							<Tag
								icon={isActive == 1 ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
								color={isActive == 1 ? "success" : "error"}
								style={{ borderRadius: "10px", width: "70px", textAlign: "center" }}
							>
								{isActive == 1 ? "Actif" : "Inactif"}
							</Tag>
						</Descriptions.Item>
					</Descriptions>
				</Col>
			</Row>
		</Card>
	);
};

export default UserDetail;
