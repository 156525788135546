import { Card, Col, Row, Typography } from "antd";
import React, { useEffect, useState } from 'react';
import annulerIcon from '../../../assets/images/delivery.gif';
import confirmerIcon from '../../../assets/images/approved.gif';
import rdvsIcon from '../../../assets/images/calendar.gif';
import clients from '../../../assets/images/calendrier.gif';
import nrpIcon from '../../../assets/images/no-chat.gif';
import encours from '../../../assets/images/wait.gif';
import { ClockCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import {fetchAppointmentStatistics} from '../services/api'
import * as XLSX from 'xlsx';

function StatistiquesDemain() {
	const { Title } = Typography;
	const [appointmentStatistics, setAppointmentStatistics] = useState([]);
	const [formattedDate, setFormattedDate] = useState("");
	const [rdv, setRdv] = useState([]);

	useEffect(() => {
		fetchAppointmentStatistics(setAppointmentStatistics,setRdv);
	}, []);

	useEffect(() => {
		if (appointmentStatistics.length > 0) {
			formatDateTime(appointmentStatistics[0].date);
			console.log('Encours Appointments:', appointmentStatistics.filter(appt => appt.status === 'encours'));
		}
	}, [appointmentStatistics]);

	

	const formatDateTime = (dateStr) => {
		const options = { weekday: 'short', day: 'numeric', month: 'long', year: 'numeric' };
		const date = new Date(dateStr);
		const formatter = new Intl.DateTimeFormat('fr-FR', options);
		const formattedDateString = formatter.format(date);
		setFormattedDate(formattedDateString);
	};
	console.log('appointmentStatistics', appointmentStatistics)

	const count = appointmentStatistics.length > 0 ? [
		{
			today: "Total Rendez-vous Brut",
			title: appointmentStatistics[0].confirmer_count + appointmentStatistics[0].nrp_count + appointmentStatistics[0].encours_count + appointmentStatistics[0].annuler_count,
			icon: rdvsIcon,
			color: '#5AB2FF',
		},
		{
			
			today: "Total Rendez-vous encours",
			title: appointmentStatistics[0].encours_count,
			icon: encours,
			color: '#FF0000',
		},
		{
			today: "Total Rendez-vous confirmés",
			title: appointmentStatistics[0].confirmer_count,
			icon: confirmerIcon,
			color: '#00E396',
		},
		{
			today: "Total Rendez-vous NRP",
			title: appointmentStatistics[0].nrp_count,
			icon: nrpIcon,
			color: '#FF7F3E',
		},
		{
			today: "Total Rendez-vous annulés",
			title: appointmentStatistics[0].annuler_count,
			icon: annulerIcon,
			color: '#FF0000',
		},
		{
			today: "Total Rendez-vous Net",
			title: appointmentStatistics[0].confirmer_count + appointmentStatistics[0].nrp_count,
			icon: clients,
			color: '#5AB2FF',
		},
	] : [];

	const handleDownload = () => {
		try {
			// Generate Excel file
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(rdv);
			XLSX.utils.book_append_sheet(wb, ws, "Rendez-vous");

			// Convert Excel workbook to array buffer
			const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array', bookSST: true });
			const attachment = new Blob([wbout], { type: 'application/octet-stream' });

			// Create download link
			const downloadUrl = URL.createObjectURL(attachment);

			// Trigger download
			const a = document.createElement('a');
			a.href = downloadUrl;
			a.download = `rendez-vous_${formattedDate}.xlsx`;
			a.click();

			// Clean up the URL object after download
			URL.revokeObjectURL(downloadUrl);

		} catch (error) {
			console.error('Error downloading file:', error);
		}
	};

	return (
		<>
			<div className="layout-content">
				<Title level={4} style={{ color: "gray" }}>Statistiques des rendez-vous pour demain</Title>
				<Row className="rowgap-vbox" gutter={[24, 24]}>
					{count.map((c, index) => (
						<Col
							key={index}
							xs={24}
							sm={12}
							md={8}
							lg={8}
							xl={8}
							className="mb-24"
						>
							<Card bordered={false} className="circlebox" style={{ height: '160px', backgroundColor: c.backgroundColor, padding: "15px" }}>
								<div className="number">
									<Row align="middle" gutter={[16, 0]}>
										<Col xs={18}>
											<Title level={5} style={{ fontSize: '14px' }}>
												{c.today}
											</Title>
											<div>
												<Title level={4} style={{ margin: '8px 0', color: c.color }}>
													{c.title !== undefined ? c.title : null} Rendez-vous
												</Title>
												<div style={{ fontSize: "14px" }}>
													<ClockCircleOutlined style={{ marginRight: '8px' }} />
													{formattedDate}
												</div>
											</div>
										</Col>
										<Col xs={6}>
											<div className="icon-box">
												<img src={c.icon} alt={c.today} style={{ width: '40px', marginBottom: '10px' }} />
												{c.today === "Total Rendez-vous Net" && (
													<DownloadOutlined onClick={handleDownload} style={{ color: "#00E396", cursor: 'pointer' }} />
												)}
											</div>
										</Col>
									</Row>
								</div>
							</Card>
						</Col>
					))}
				</Row>
			</div>
		</>
	);
}

export default StatistiquesDemain;
