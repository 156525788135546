import React, { useEffect, useState } from 'react';
import { Layout, Row, Col, Card, Spin, Descriptions, Tag, Space, PageHeader, Button } from 'antd';
import { useParams } from 'react-router-dom';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import {fetchData} from '../services/api'
const { Content } = Layout;

const RdvDetails = () => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState(null);
	const { id } = useParams();
	const history = useHistory();

	useEffect(() => {
		if (id) {
			fetchData(id, setData, setLoading);
		}
	}, [id]);


	if (loading) {
		return <Spin size="large" />;
	}
	const handleRetourClick = () => {
		history.goBack();
	};

	const { last_agent_modification, first_insert, last_supervisor_modification, rdv } = data;

	if (!data) {
		return <p>No data available</p>;
	}
	const agentFullName = first_insert?.user ? `${first_insert?.user.nom} ${first_insert?.user.prenom}` : 'N/A';

	const getFieldStyle = (fieldName, modifiedField, originalField) => {

		if (!modifiedField || !originalField) {
			return {};
		}

		const isModifiedByAgent = originalField[fieldName] != modifiedField[fieldName];
		const isModifiedBySupervisor = last_agent_modification && last_agent_modification[fieldName] != modifiedField[fieldName];


		if (isModifiedByAgent) {
			return { color: '#EF4040' };
		} else if (isModifiedBySupervisor) {
			return { color: '#1890FF' };
		} else {
			return {};
		}
	};

	console.log('data', data)



	return (
		<Layout style={{ width: '100% !important', marginLeft: 0 }}>
			<PageHeader
				className="site-page-header"
				title={`Rendez-vous Modifié - ID: ${id} pour ${first_insert?.agenda?.user?.nom && first_insert?.agenda?.user?.prenom
					? `${first_insert.agenda.user.nom.charAt(0).toUpperCase()}${first_insert.agenda.user.nom.slice(1)} ${first_insert.agenda.user.prenom.charAt(0).toUpperCase()}${first_insert.agenda.user.prenom.slice(1)}`
					: 'N/A'
					}`}
				extra={[
					<Button key="retour" onClick={handleRetourClick}>Retour</Button>
				]}
			/>

			<Content>
				<Row gutter={16} justify="space-between" style={{ display: 'flex' }} >
					{first_insert && (
						<Col xs={24} sm={24} md={24} lg={last_supervisor_modification && last_agent_modification ? 8 : 12}>
							<Card title={<span style={{ fontWeight: 'bold', fontSize: '13px' }}>Créé par : {agentFullName}</span>}>
								<Descriptions column={1}
									bordered labelStyle={{ width: '100px' }}>


									<Descriptions.Item label="Date de rendez-vous" style={getFieldStyle('start_date', first_insert?.start_date)}>
										{first_insert?.start_date && first_insert?.end_date &&
											`${new Date(first_insert?.start_date).toLocaleString('fr-FR', {
												weekday: 'short',
												day: 'numeric',
												month: 'long',
												year: 'numeric',
												hour: 'numeric',
												minute: 'numeric',
											})} - ${new Date(first_insert?.end_date).toLocaleTimeString('fr-FR', {
												hour: 'numeric',
												minute: 'numeric',
											})}`
										}
									</Descriptions.Item>

									<Descriptions.Item label="Crée le">
										{first_insert?.created_at && new Date(first_insert?.created_at).toLocaleString('fr-FR', {
											weekday: 'short',
											day: 'numeric',
											month: 'long',
											year: 'numeric',
											hour: 'numeric',
											minute: 'numeric',
										})}
									</Descriptions.Item>

									<Descriptions.Item label="Statut" style={getFieldStyle('status', first_insert?.status)}>
										<Tag color="gray">RDV : {first_insert?.status || 'encours'}</Tag>
									</Descriptions.Item>

									<Descriptions.Item label="Nom" style={getFieldStyle('nom', first_insert?.nom)}>
										{first_insert?.nom || 'N/A'}
									</Descriptions.Item>
									<Descriptions.Item label="Prénom" style={getFieldStyle('prenom', first_insert?.prenom)}>
										{first_insert?.prenom == "null" ? 'N/A' : first_insert?.prenom || 'N/A'}
									</Descriptions.Item>

									<Descriptions.Item label="Type" style={getFieldStyle('pro', first_insert?.pro)}>
										{first_insert?.nom_ste ? 'Professionnel' : 'Résidentiel'}
									</Descriptions.Item>

									<Descriptions.Item label="Nom Ste" style={getFieldStyle('nom_ste')}>
										{first_insert?.nom_ste ? first_insert?.nom_ste : 'Le client est Résidentiel'}
									</Descriptions.Item>
									<Descriptions.Item label="TVA" style={getFieldStyle('tva')}>
										{first_insert?.nom_ste ? `BEO${first_insert?.tva}` : 'Le client est Résidentiel'}
									</Descriptions.Item>
									<Descriptions.Item label="Tel" style={getFieldStyle('tel')}>
										{first_insert?.tel ? `+32 ${first_insert?.tel}` : 'N/A'}

									</Descriptions.Item>
									<Descriptions.Item label="GSM" style={getFieldStyle('gsm')}>
										{first_insert?.gsm ? `+324 ${first_insert?.gsm}` : 'N/A'}

									</Descriptions.Item>
									<Descriptions.Item label="Postal" style={getFieldStyle('postal')}>
										{first_insert?.postal}
									</Descriptions.Item>
									<Descriptions.Item label="Adresse" style={getFieldStyle('adresse')}>
										{first_insert?.adresse}
									</Descriptions.Item>
									<Descriptions.Item label="Fournisseur" style={getFieldStyle('fournisseur')}>
										{first_insert?.fournisseur}
									</Descriptions.Item>
									<Descriptions.Item label="Nbr Comp Elect" style={getFieldStyle('nbr_comp_elect')}>
										{first_insert?.nbr_comp_elect}
									</Descriptions.Item>
									<Descriptions.Item label="Nbr Comp Gaz" style={getFieldStyle('nbr_comp_gaz')}>
										{first_insert?.nbr_comp_gaz}
									</Descriptions.Item>
									<Descriptions.Item label="PPV">
										<Space>
											{first_insert?.ppv == 1 ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />}
										</Space>
									</Descriptions.Item>
									<Descriptions.Item label="Tarif social">
										{first_insert?.tarif == 1 ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />}
									</Descriptions.Item>
									<Descriptions.Item label="Haute Tension">
										{first_insert?.haute_tension == 1 ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />}
									</Descriptions.Item>
									<Descriptions.Item label="Tarification">
										{first_insert?.tarification}
									</Descriptions.Item>
									<Descriptions.Item label="Commentaire" style={getFieldStyle('commentaire')}>
										<span >
											{first_insert?.commentaire ? first_insert?.commentaire : 'Aucun commentaire'}
										</span>
									</Descriptions.Item>

									<Descriptions.Item label="Note" style={getFieldStyle('note')}>
										<span >
											{first_insert?.note ? first_insert?.note : 'Aucune note'}
										</span>
									</Descriptions.Item>

									<Descriptions.Item label="AC Statut" >
										{rdv.status_ac ? rdv.status_ac : 'N/A'}
									</Descriptions.Item>
								</Descriptions>
							</Card>
						</Col>
					)}
					{last_agent_modification && (
						<Col xs={24} sm={24} md={24} lg={last_supervisor_modification ? 8 : 12}>
							<Card title={<span style={{ fontWeight: 'bold', fontSize: '13px' }}>
								Agent : {last_agent_modification?.modifier?.nom || 'N/A'}{' '}
								{last_agent_modification?.modifier?.prenom || 'N/A'}
							</span>}>


								<Descriptions column={1}
									bordered labelStyle={{ width: '100px' }}>

									<Descriptions.Item label="Date de rendez-vous">
										<span style={{
											...getFieldStyle('start_date', last_agent_modification, first_insert),
											...getFieldStyle('end_date', last_agent_modification, first_insert)
										}}>
											{last_agent_modification?.start_date && last_agent_modification?.end_date &&
												`${new Date(last_agent_modification?.start_date).toLocaleString('fr-FR', {
													weekday: 'short',
													day: 'numeric',
													month: 'long',
													year: 'numeric',
													hour: 'numeric',
													minute: 'numeric',
												})} - ${new Date(last_agent_modification?.end_date).toLocaleTimeString('fr-FR', {
													hour: 'numeric',
													minute: 'numeric',
												})}`
											}
										</span>
									</Descriptions.Item>


									<Descriptions.Item label="Modifé le" >
										<span style={getFieldStyle('updated_at', last_agent_modification, first_insert?.created_at)}>
											{last_agent_modification?.updated_at && new Date(last_agent_modification?.updated_at).toLocaleString('fr-FR', {
												weekday: 'short',
												day: 'numeric',
												month: 'long',
												year: 'numeric',
												hour: 'numeric',
												minute: 'numeric',
											})}
										</span>

									</Descriptions.Item>
									<Descriptions.Item label="Statut" >
										<Tag color={
											last_agent_modification?.status === 'confirmer' ? 'green' :
												last_agent_modification?.status === 'NRP' ? 'blue' :
													'gray'
										}>
											RDV : {last_agent_modification?.status || 'encours'}
										</Tag>
									</Descriptions.Item>

									<Descriptions.Item label="Nom" >
										<span style={getFieldStyle('nom', last_agent_modification, first_insert)}>
											{last_agent_modification?.nom || 'N/A'}
										</span>

									</Descriptions.Item>



									<Descriptions.Item label="Prénom" >
										<span style={getFieldStyle('prenom', last_agent_modification, first_insert)}>
											{last_agent_modification?.prenom == "null" ? 'N/A' : last_agent_modification?.prenom || 'N/A'}

										</span>


									</Descriptions.Item>
									<Descriptions.Item label="Type">
										<span style={getFieldStyle('pro', last_agent_modification, first_insert)}>
											{last_agent_modification?.pro == 1 ? 'Professionnel' : 'Résidentiel'}
										</span>

									</Descriptions.Item>


									<Descriptions.Item label="Nom Ste" >
										<span style={getFieldStyle('nom_ste', last_agent_modification, first_insert)}>
											{last_agent_modification?.pro == 1 ? last_agent_modification?.nom_ste : 'Le client est Résidentiel'}
										</span>

									</Descriptions.Item>
									<Descriptions.Item label="TVA" >
										<span style={getFieldStyle('tva', last_agent_modification, first_insert)}>
											{last_agent_modification?.pro == 1 ? `BEO${last_agent_modification?.tva}` : 'Le client est Résidentiel'}
										</span>

									</Descriptions.Item>
									<Descriptions.Item label="Tel">
										<span style={getFieldStyle('tel', last_agent_modification, first_insert)}>
											{last_agent_modification?.tel ? `+32 ${last_agent_modification?.tel}` : 'N/A'}

										</span>

									</Descriptions.Item>
									<Descriptions.Item label="GSM" >
										<span style={getFieldStyle('gsm', last_agent_modification, first_insert)}>

											{last_agent_modification?.gsm ? `+324 ${last_agent_modification?.gsm}` : 'N/A'}


										</span>

									</Descriptions.Item>
									<Descriptions.Item label="Postal" >
										<span style={getFieldStyle('postal', last_agent_modification, first_insert)}>
											{last_agent_modification?.postal}
										</span>

									</Descriptions.Item>
									<Descriptions.Item label="Adresse" >
										<span style={getFieldStyle('adress', last_agent_modification, first_insert)}>
											{last_agent_modification?.adresse}
										</span>

									</Descriptions.Item>
									<Descriptions.Item label="Fournisseur" >
										<span style={getFieldStyle('fournisseur', last_agent_modification, first_insert)}>
											{last_agent_modification?.fournisseur}
										</span>

									</Descriptions.Item>
									<Descriptions.Item label="Nbr Comp Elect" >
										<span style={getFieldStyle('nbr_comp_elect', last_agent_modification, first_insert)}>
											{last_agent_modification?.nbr_comp_elect}
										</span>
									</Descriptions.Item>
									<Descriptions.Item label="Nbr Comp Gaz" >
										<span style={getFieldStyle('nbr_comp_gaz', last_agent_modification, first_insert)}>
											{last_agent_modification?.nbr_comp_gaz}
										</span>
									</Descriptions.Item>
									<Descriptions.Item label="PPV">
										<Space>
											{last_agent_modification?.ppv == 1 ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />}
										</Space>
									</Descriptions.Item>
									<Descriptions.Item label="Tarif social">
										{last_agent_modification?.tarif == 1 ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />}
									</Descriptions.Item>
									<Descriptions.Item label="Haute Tension">
										{last_agent_modification?.haute_tension == 1 ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />}
									</Descriptions.Item>
									<Descriptions.Item label="Tarification" >
										<span style={getFieldStyle('tarification', last_agent_modification, first_insert)}>{last_agent_modification?.tarification}</span>

									</Descriptions.Item>
									<Descriptions.Item label="Commentaire" >
										<span style={getFieldStyle('commentaire', last_agent_modification, first_insert)}>
											{last_agent_modification?.commentaire ? last_agent_modification?.commentaire : 'Aucun commentaire'}
										</span>
									</Descriptions.Item>
									<Descriptions.Item label="Note" >
										<span style={getFieldStyle('note', last_agent_modification, first_insert)}>
											{last_agent_modification?.note ? last_agent_modification?.note : 'Aucune note'}
										</span>
									</Descriptions.Item>
									<Descriptions.Item label="AC Statut">
										{rdv.status_ac ? rdv.status_ac : 'N/A'}
									</Descriptions.Item>




								</Descriptions>

							</Card>
						</Col>
					)}
					{last_supervisor_modification && (
						<Col xs={24} sm={24} md={24} lg={last_agent_modification ? 8 : 12}>
							<Card title={<span style={{ fontWeight: 'bold', fontSize: '13px' }}> Superviseur :  {last_supervisor_modification?.modifier_user?.nom || last_supervisor_modification?.modifier?.nom || 'N/A'} {' '}
								{last_supervisor_modification?.modifier_user?.prenom || last_supervisor_modification?.modifier?.prenom || 'N/A'}</span>}>
								<Descriptions column={1}
									bordered labelStyle={{ width: '100px' }}>

									<Descriptions.Item label="Date de rendez-vous">
										<span style={getFieldStyle('start_date', last_supervisor_modification, last_agent_modification)}>
											{last_supervisor_modification?.start_date && last_supervisor_modification?.end_date &&
												`${new Date(last_supervisor_modification?.start_date).toLocaleString('fr-FR', {
													weekday: 'short',
													day: 'numeric',
													month: 'long',
													year: 'numeric',
													hour: 'numeric',
													minute: 'numeric',
												})} - ${new Date(last_supervisor_modification?.end_date).toLocaleTimeString('fr-FR', {
													hour: 'numeric',
													minute: 'numeric',
												})}`
											}
										</span>
									</Descriptions.Item>



									<Descriptions.Item label="Modifé le" >
										<span style={getFieldStyle('updated_at', last_supervisor_modification, last_agent_modification ? last_agent_modification.created_at : first_insert.created_at)}>
											{last_supervisor_modification?.updated_at && new Date(last_supervisor_modification?.updated_at).toLocaleString('fr-FR', {
												weekday: 'short',
												day: 'numeric',
												month: 'long',
												year: 'numeric',
												hour: 'numeric',
												minute: 'numeric',
											})}
										</span>
									</Descriptions.Item>
									<Descriptions.Item label="Statut" >
										<Tag color={
											last_supervisor_modification?.status == 'confirmer' ? 'green' :
												last_supervisor_modification?.status == 'NRP' ? 'blue' :
													'gray'
										}>
											RDV : {last_supervisor_modification?.status || 'encours'}
										</Tag>
									</Descriptions.Item>



									<Descriptions.Item label="Nom" >
										<span style={getFieldStyle('nom', last_supervisor_modification, last_agent_modification ? last_agent_modification : first_insert)}>
											{last_supervisor_modification?.nom || 'N/A'}
										</span>

									</Descriptions.Item>
									<Descriptions.Item label="Prénom">
										<span style={getFieldStyle('prenom', last_supervisor_modification, last_agent_modification ? last_agent_modification : first_insert)}>
											{last_supervisor_modification?.prenom && last_supervisor_modification.prenom.toLowerCase() !== 'null'
												? last_supervisor_modification.prenom
												: last_supervisor_modification?.nom}
										</span>
									</Descriptions.Item>

									<Descriptions.Item label="Type" >
										<span style={getFieldStyle('pro', last_supervisor_modification, last_agent_modification ? last_agent_modification : first_insert)}>
											{last_supervisor_modification?.pro == 1 ? 'Professionnel' : 'Résidentiel'}
										</span>

									</Descriptions.Item>

									<Descriptions.Item label="Nom Ste" >
										<span style={getFieldStyle('nom_ste', last_supervisor_modification, last_agent_modification ? last_agent_modification : first_insert)}>
											{last_supervisor_modification?.pro == 1 ? last_supervisor_modification?.nom_ste : 'Le client est Résidentiel'}
										</span>

									</Descriptions.Item>
									<Descriptions.Item label="TVA">
										<span style={getFieldStyle('tva', last_supervisor_modification, last_agent_modification ? last_agent_modification : first_insert)}>{last_supervisor_modification?.pro == 1 ? `BEO${last_supervisor_modification?.tva}` : 'Le client est Résidentiel'}
										</span>

									</Descriptions.Item>
									<Descriptions.Item label="Tel">
										<span style={getFieldStyle('tel', last_supervisor_modification, last_agent_modification ? last_agent_modification : first_insert)}>
											{last_supervisor_modification?.tel ? `+32 ${last_supervisor_modification?.tel}` : 'N/A'}
										</span>


									</Descriptions.Item>
									<Descriptions.Item label="GSM" >
										<span style={getFieldStyle('gsm', last_supervisor_modification, last_agent_modification ? last_agent_modification : first_insert)}>
											{last_supervisor_modification?.gsm ? `+324 ${last_supervisor_modification?.gsm}` : 'N/A'}
										</span>

									</Descriptions.Item>
									<Descriptions.Item label="Postal" >
										<span style={getFieldStyle('postal', last_supervisor_modification, last_agent_modification ? last_agent_modification : first_insert)}>
											{last_supervisor_modification?.postal}
										</span>

									</Descriptions.Item>
									<Descriptions.Item label="Adresse" >
										<span style={getFieldStyle('adresse', last_supervisor_modification, last_agent_modification ? last_agent_modification : first_insert)}>
											{last_supervisor_modification?.adresse}
										</span>

									</Descriptions.Item>
									<Descriptions.Item label="Fournisseur" >
										<span style={getFieldStyle('fournisseur', last_supervisor_modification, last_agent_modification ? last_agent_modification : first_insert)}>
											{last_supervisor_modification?.fournisseur}
										</span>

									</Descriptions.Item>
									<Descriptions.Item label="Nbr Comp Elect" >
										<span style={getFieldStyle('nbr_comp_elect', last_supervisor_modification, last_agent_modification ? last_agent_modification : first_insert)}>
											{last_supervisor_modification?.nbr_comp_elect}
										</span>

									</Descriptions.Item>
									<Descriptions.Item label="Nbr Comp Gaz">
										<span style={getFieldStyle('nbr_comp_gaz', last_supervisor_modification, last_agent_modification ? last_agent_modification : first_insert)}>
											{last_supervisor_modification?.nbr_comp_gaz}
										</span>

									</Descriptions.Item>
									<Descriptions.Item label="PPV">
										<Space>
											{last_supervisor_modification?.ppv == 1 ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />}
										</Space>
									</Descriptions.Item>
									<Descriptions.Item label="Tarif social">
										{last_supervisor_modification?.tarif == 1 ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />}
									</Descriptions.Item>
									<Descriptions.Item label="Haute Tension">
										{last_supervisor_modification?.haute_tension ==1 ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />}
									</Descriptions.Item>
									<Descriptions.Item label="Tarification" >
										<span style={getFieldStyle('tarification', last_supervisor_modification, last_agent_modification ? last_agent_modification : first_insert)}>
											{last_supervisor_modification?.tarification}
										</span>

									</Descriptions.Item>
									<Descriptions.Item label="Commentaire" >
										<span style={getFieldStyle('commentaire', last_supervisor_modification, last_agent_modification ? last_agent_modification : first_insert)}>

											{last_supervisor_modification?.commentaire ? last_supervisor_modification?.commentaire : 'Aucun commentaire'}



										</span>
									</Descriptions.Item>

									<Descriptions.Item label="Note">

										<span style={getFieldStyle('note', last_supervisor_modification, last_agent_modification ? last_agent_modification : first_insert)}>
											{last_supervisor_modification?.note ? last_supervisor_modification?.note : 'Aucune note'}

										</span>


									</Descriptions.Item>
									<Descriptions.Item label="AC Statut" style={getFieldStyle('status_ac')}>
										{rdv.status_ac ? rdv.status_ac : 'N/A'}
									</Descriptions.Item>

								</Descriptions>
							</Card>
						</Col>
					)}
				</Row>
			</Content>
		</Layout>
	);
};

export default RdvDetails;
