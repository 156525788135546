import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";

const CountdownTimer = ({ appointmentId, onDelete }) => {
  const initialEndTime = parseInt(Cookies.get(`timerEndTime_${appointmentId}`), 10);
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(initialEndTime || (Date.now() + 15 * 60 * 1000)));
  const [isFlashing, setIsFlashing] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(prevTimeLeft => {
        const newTimeLeft = calculateTimeLeft(parseInt(Cookies.get(`timerEndTime_${appointmentId}`), 10));
        
        if (newTimeLeft.total <= 0) {
          clearInterval(intervalId);
          if (onDelete) {
            onDelete();
          }
          return { minutes: 0, seconds: 0, total: 0 };
        }

        setIsFlashing(prevIsFlashing => !prevIsFlashing);

        return newTimeLeft;
      });
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (!initialEndTime) {
      const endTime = Date.now() + 15 * 60 * 1000;
      Cookies.set(`timerEndTime_${appointmentId}`, endTime.toString(), { expires: 7 });
    }
  }, [appointmentId, initialEndTime]);

  return (
    <div className={`countdown-timer ${isFlashing ? "flash-animation" : ""}`} style={{ color: "#61559F",  fontWeight: "bold",  }}>
      {timeLeft.minutes < 10 ? `0${timeLeft.minutes}` : timeLeft.minutes}:{timeLeft.seconds < 10 ? `0${timeLeft.seconds}` : timeLeft.seconds} s
    </div>
  );
};

const calculateTimeLeft = (endTime) => {
  const total = endTime - Date.now();
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const seconds = Math.floor((total / 1000) % 60);
  return {
    total,
    minutes,
    seconds,
  };
};

export default CountdownTimer;
