import React, { useState, useEffect } from 'react';
import { Card, Select, Spin, Empty, DatePicker, Form, Typography } from 'antd';
import Chart from 'react-apexcharts';
import { fetchAgents, fetchAppointments } from '../services/api';
import moment from 'moment';

const { Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const AppointmentsChart = () => {
	const [agents, setAgents] = useState([]);
	const [selectedAgent, setSelectedAgent] = useState(null);
	const [startDate, setStartDate] = useState(moment().startOf('month'));
	const [endDate, setEndDate] = useState(moment());
	const [appointmentsData, setAppointmentsData] = useState({ dates: [], counts: [] });
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const getAgents = async () => {
			try {
				const agents = await fetchAgents();
				console.log('agents', agents)
				setAgents(agents);
			} catch (error) {
				console.error('Error fetching agents:', error);
			}
		};
		getAgents();
	}, []);

	useEffect(() => {
		const getAppointments = async () => {
			console.log('selectedAgent', selectedAgent)
			console.log('type of selectedAgent ', typeof selectedAgent)


			if (selectedAgent) {
				try {
					setLoading(true);
					const data = await fetchAppointments(selectedAgent, startDate, endDate);
					console.log('data', data)

					setAppointmentsData(data);
				} catch (error) {
					console.error('Error fetching appointments:', error);
				} finally {
					setLoading(false);
				}
			}
		};
		getAppointments();
	}, [selectedAgent, startDate, endDate]);

	const chartOptions = {
		chart: {
			id: 'appointments-chart',
			type: 'line',
		},
		xaxis: {
			categories: appointmentsData.dates,
			tickAmount: appointmentsData.dates.length,
			labels: {
				formatter: function (value) {
					return value;
				},
			},
		},
		yaxis: {
			min: 0,
			forceNiceScale: true,
			labels: {
				formatter: (value) => Math.floor(value) == value ? value : '',
			},
		},
	};

	const chartSeries = [
		{
			name: 'Appointments',
			data: appointmentsData.counts,
		},
	];

	return (
		<Card>
			<Title level={4}>Production des agents</Title>
			<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
				<Form layout="inline" style={{ flexGrow: 1 }}>
					<Form.Item label="Choisir un Agent" style={{ marginRight: 20, fontWeight: "600" }}>
						<Select
							placeholder="Choisir un Agent"
							style={{ width: 200 }}
							onChange={(value) => setSelectedAgent(value)}
							showSearch
							allowClear
							filterOption={(inputValue, option) =>
								option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
							}
						>
							{agents.map(agent => (
								<Option key={agent.id} value={agent.id}>
									{`${agent.nom} ${agent.prenom}`}
								</Option>
							))}
						</Select>
					</Form.Item>
					{/* <Form.Item label="Sélectionner la Date" style={{ marginRight: 20, fontWeight: "600" }}>
						<RangePicker
							style={{ width: 300 }}
							value={[startDate, endDate]}
							onChange={(dates) => {
								if (dates && dates.length == 2) {
									setStartDate(dates[0]);
									setEndDate(dates[1]);
								} else {
									setStartDate(moment().startOf('month'));
									setEndDate(moment());
								}
							}}
						/>
					</Form.Item> */}
				</Form>
			</div>
			{loading ? (
				<Spin />
			) : (
				selectedAgent ? (
					<Chart
						options={chartOptions}
						series={chartSeries}
						type="line"
						height={400}
						width="100%"
					/>
				) : (
					<Empty description="Veuillez choisir un agent." />
				)
			)}
		</Card>
	);
};

export default AppointmentsChart;
