import { axiosClient } from "../../../api/axios";



export const handleAppointmentClickAC = async (
	event,
	userContext,
	setAgentId,
	setAppointmentDetails,
	setSelectedRowData,
	setShowDetailModal,
	setSelectedAppointment,
	axiosClient,
	

) => {

	try {
		// Make a GET request to fetch the agent ID by appointment ID
		const response = await axiosClient.get(
			`appointments/${event.id}/agent`
		);

		// Extract the agent ID from the response data
		const agentId = response.data.agent.id;

		setAgentId(agentId);

		// Compare the agent ID with the logged-in user's ID
		{
			const response = await axiosClient.get(`rdvs/${event.id}`);
			const appointmentDetails = response.data;
			setAppointmentDetails(appointmentDetails);
			setSelectedRowData(appointmentDetails);
			setShowDetailModal(true);
			setSelectedAppointment(event);
			

		}
	} catch (error) {
		console.error("Error fetching agent ID:", error);
	}
};

// Fetches agendas and appointments for all agendas, updates state with them
export const fetchAcAgendasAndAppointments = async (
	setUserAgenda,
	setUserAppointments,
	userId,
	setShowRdvPrvCalednar
) => {
	try {
		
		setShowRdvPrvCalednar(false)
		// Fetch all agendas
		const agendasResponse = await axiosClient.get(`users/${userId}/agenda-with-appointments`);
		const agendas = agendasResponse.data.user.rdvs[0].agenda;
		setUserAgenda(agendas);
		setUserAppointments(agendasResponse.data.user.rdvs);
	} catch (error) {
		console.error("Error fetching agendas and appointments:", error.message);
	}
};



export const fetchACRdv = async (userId, setUserAgenda, setRdv, selectedAgent, selectedDateRange) => {
	try {
			const queryParams = {};
		
			
			if (selectedDateRange && selectedDateRange.length == 1) {
				const startDate = selectedDateRange[0].startOf('day').toISOString();
				queryParams.start_date = startDate;
			}
	
			if (selectedDateRange && selectedDateRange.length == 2) {
				const startDate = selectedDateRange[0].startOf('day').toISOString();
				const endDate = selectedDateRange[1].endOf('day').toISOString();
				queryParams.start_date = startDate;
				queryParams.end_date = endDate;
			}
	

			const response = await axiosClient.get(`users/${userId}/agenda-with-appointments`, {
					params: queryParams,
			});



			const filteredAppointments = response.data.user.rdvs.filter(appointment => {
				return appointment.status == "NRP" || appointment.status == "confirmer";
		});

			setRdv(filteredAppointments);
			
	} catch(error) {
			console.error("Error fetching user agenda:", error);
	}
}





export const fetchAgentOptions = async (setAgentOptions) => {
	try {
		const response = await axiosClient.get("superviseur-and-agent-users");
		setAgentOptions(response.data.users);
	} catch (error) {
		console.error("Error fetching agent options:", error);
	}
};

export const fetchAgendaOptions = async (setAgendaOptions) => {
	try {
		const response = await axiosClient.get("agendas");
		const agendas = response.data.agendas;

		// Map through agendas and add contact names from the response
		const agendaOptionsWithContactNames = agendas.map((agenda) => {
			const contact = agenda.user;
			return {
				...agenda,
				contact_nom: contact.nom,
				contact_prenom: contact.prenom
			};
		});

		// Set the agenda options with contact names
		setAgendaOptions(agendaOptionsWithContactNames);
	} catch (error) {
		console.error("Error fetching agenda options:", error);
	}
};