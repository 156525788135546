import React, { useState, useRef, useEffect } from "react";
import { Card, Col, Row, Space, Typography, Tag } from "antd";
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import "../views/rdv.css";

const { Text } = Typography;

const RdvDetail = ({ rdv }) => {
	const clientInfoCardRef = useRef(null);
	const appointmentInfoCardRef = useRef(null);
	const notesCardRef = useRef(null);
	const [cardHeight, setCardHeight] = useState("auto");
	
	const updateCardHeight = () => {
		const clientInfoCardHeight = clientInfoCardRef?.current?.offsetHeight;
		const appointmentInfoCardHeight = appointmentInfoCardRef?.current?.offsetHeight;
		const notesCardHeight = notesCardRef?.current?.offsetHeight;
		const maxHeight = Math.max(clientInfoCardHeight, appointmentInfoCardHeight, notesCardHeight);
		setCardHeight(maxHeight + "px");
	};

	useEffect(() => {
		updateCardHeight();
	}, [rdv]);

	if (!rdv) {
		return <div>No appointment data available</div>
	}

	const { id, start_date, end_date, note, commentaire, nom, prenom, pro, status, nom_ste, tva, tel, gsm, adresse, postal, fournisseur, nbr_comp_elect, nbr_comp_gaz, ppv, tarif, tarification, haute_tension, status_ac, agent, porte, agentCommercial } = rdv;
	const agentName = `${agent.nom} ${agent.prenom}`;

	let statusColor = "#FF8A08";
	const clientType = nom_ste ? "Professionnel" : "Residential";
	switch (status) {
		case "encours":
			statusColor = "#373A40";
			break;
		case "confirmer":
			statusColor = "#00E396";
			break;
		case "annuler":
			statusColor = "red";
			break;
		default:
			break;
	}
	const clientTypeColor = pro ? "geekblue" : "gold";

	const formatDate = (date, includeDate = true) => {
		if (!date) return "-";
		const formattedTime = new Date(date).toLocaleTimeString('fr-FR', { hour: 'numeric', minute: 'numeric', hour12: false });
		const formattedDate = includeDate ? new Date(date).toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' }) : '';
		return `${includeDate ? formattedDate + ' à ' : ''}${formattedTime}`;
	};
	return (
		<div>
			<Card>
				<Row justify="space-between" align="middle" style={{ marginTop: "10px" }}>
					<Col >
						<Text strong>Date du rendez-vous : </Text>
						<Text>{formatDate(start_date)} - {formatDate(end_date, false)}</Text>

					</Col>


					<Col >
						<Tag color={statusColor}>
							RDV : {status_ac ? `${status_ac} - ${status}` : status}
						</Tag>
					</Col>
				</Row>
			</Card>
			<Row gutter={[16, 16]} >
				<Col span={9}>
					<Card size="small" className="carddisplayrdv" ref={clientInfoCardRef} style={{ height: cardHeight }}>
						<Row >
							<Col span={24}>
								<p><strong>Agent :</strong> {agentName}</p>
							</Col>
							<Col span={24}>
								<p><strong>Agenda :</strong> {agentCommercial.toUpperCase()}</p>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<p><strong>Client :</strong> {rdv && nom && nom.toLowerCase() !== 'null' ? `${nom} ${prenom && prenom.toLowerCase() !== 'null' ? prenom : ''}` : 'N/A'}</p>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<p><strong>Tél :</strong> +32{tel}</p>
							</Col>
							<Col span={24}>
								<p><strong>GSM :</strong>{gsm ? `+324${gsm}` : ' N/A'}</p>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<p><strong>Rue :</strong> {adresse}</p>
							</Col>
							<Col span={24}>
								<p><strong>Numéro :</strong> {porte}</p>
							</Col>
							<Col span={24} >
								<p> <strong>Code Postal :</strong> {postal}</p>
							</Col>
						</Row>
						{clientType === "Professionnel" && (
							<Row>
								<Col span={24}>
									<p><strong>Société :</strong> {nom_ste ? nom_ste : "N/A"}</p>
								</Col>
								<Col span={24}>
									<p><strong>TVA :</strong>{tva ? `BE0${tva}` : "N/A"}</p>
								</Col>
							</Row>
						)}
					</Card>
				</Col>
				<Col span={15}>
					<Card size="small" className="carddisplayrdv" ref={appointmentInfoCardRef} style={{ height: cardHeight }}>
						<Row>
							<Col span={6} offset={18}>
								<Tag color={clientTypeColor}><strong>Type :</strong> {clientType}</Tag>
							</Col>
							<Col span={24}>
								<p><strong>Fournisseur:</strong> {fournisseur}</p>
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<p><strong>Nombre de compteurs électriques :</strong> {nbr_comp_elect}</p>
							</Col>
							<Col span={12}>
								<p><strong>Nombre de compteurs gazs :</strong> {nbr_comp_gaz}</p>
							</Col>
						</Row>
						<Row gutter={[16, 16]}>
							<Col span={24}>
								{commentaire ? (
									<Card title="Commentaire" size="small" bordered={false} className="carddisplayrdvnote" style={{ backgroundColor: "#E6F4FF" }}>
										<p>{commentaire}</p>
									</Card>
								) : (
									<Card title="Commentaire" size="small" bordered={false} className="carddisplayrdvnote" style={{ backgroundColor: "#FFFAB7" }}>
										<p>Aucun commentaire</p>
									</Card>
								)}
							</Col>
						</Row>
						<Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
							<Col span={24}>
								<Space size="large">
									<Space align="baseline">
										<Text strong>PPV :</Text>
										{ppv == 1 ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />}
									</Space>
									<Space align="baseline">
										<Text strong>Tarif Social :</Text>
										{tarif == 1 ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />}
									</Space>
									<Space align="baseline">
										<Text strong>Haute Tension :</Text>
										{haute_tension == 1 ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />}
									</Space>
									<Space align="baseline">
										<Text strong>Tarification :</Text>
										<Text type={tarification === "fixe" ? "default" : tarification === "Variable" ? "success" : "default"}>{tarification}</Text>
									</Space>
								</Space>
							</Col>
						</Row>
					</Card>
				</Col>
				<Col span={24}>
					{note ? (
						<Card title="Notes" size="small" bordered={false} className="carddisplayrdvnote" ref={notesCardRef} style={{ backgroundColor: "#FFF2F0" }}>
							<p>{note}</p>
						</Card>
					) : (
						<Card title="Notes" size="small" bordered={false} className="carddisplayrdvnote" ref={notesCardRef} style={{ backgroundColor: "#FFF2F0" }}>
							<p>Aucune note</p>
						</Card>
					)}
				</Col>
			</Row>
		</div>
	);
};

export default RdvDetail;