import React, { useState, useEffect } from 'react';
import { axiosClient } from '../../../api/axios';
import ReactApexChart from 'react-apexcharts';
import { Card, Typography } from "antd";
import { useAuth } from '../../../AuthContext';

const { Title } = Typography;

const AppointmentChart = () => {
	const [chartData, setChartData] = useState({});
	const [loading, setLoading] = useState(true);
	const [startOfMonthDate, setStartOfMonthDate] = useState('');
	const { userId } = useAuth();

	useEffect(() => {
		const currentDateObj = new Date();
		const startOfMonth = new Date(currentDateObj.getFullYear(), currentDateObj.getMonth(), 1);
		const startOfMonthStr = new Intl.DateTimeFormat('fr-FR', { day: '2-digit', month: 'long' }).format(startOfMonth);
		
		setStartOfMonthDate(startOfMonthStr);

	}, []);

	useEffect(() => {
		const fetchChartData = async () => {
			try {
				console.log('fetching chart data')

				const response = await axiosClient.get(`rdvs/agent-commercial/${userId}`);
				const data = response.data;
				console.log('data', data)

				setChartData(data);
				setLoading(false);
			} catch (error) {
				console.error('Error fetching chart data:', error);
			}
		};

		if (userId) {
			fetchChartData();
		}
	}, [userId]);

	// Convert chartData object to an array of objects with 'x' and 'y' properties
	const chartDataArray = Object.keys(chartData).map(day => ({ x: parseInt(day), y: chartData[day] }));

	// Filter data to display until the current day
	const currentDay = new Date().getDate();
	const displayedData = chartDataArray.filter(data => data.x <= currentDay);

	// Format dates for x-axis categories
	const formattedDates = displayedData.map(data => {
		const date = new Date();
		date.setDate(data.x); // Set date to the day of the month
		const day = date.getDate().toString().padStart(2, '0'); // Get day in DD format
		const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get month in MM format
		const year = date.getFullYear(); // Get full year
		return `${day}-${month}-${year}`; // Combine into DD-MM-YYYY format
	});

	const options = {
		chart: {
			id: 'appointments-chart',
			toolbar: {
				show: false
			}
		},
		xaxis: {
			type: 'category',
			categories: formattedDates
		},
		yaxis: {
			labels: {
				formatter: function (val) {
					return Math.floor(val);
				},
			},
			forceNiceScale: true
		}
	};

	const series = [{
		name: 'Rendez-vous',
		data: displayedData.map(data => data.y)
	}];

	return (
		<div>
			<Title level={5} style={{ color: "#4F1787", fontSize: "18px" , margin:'8px 0 30px 0' }}>
    Nombres des rendez-vous Confirmés et NRP du {startOfMonthDate} jusqu'à aujourd'hui
</Title>

			<Card>
				<ReactApexChart options={options} series={series} type="bar" height={350} />
			</Card>
		</div>
	);
};

export default AppointmentChart;
