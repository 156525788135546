import React, { useEffect, useState } from 'react';
import { List, Spin, Divider } from 'antd';
import { axiosClient,baseURL } from '../../api/axios';
import { useAuth } from '../../AuthContext';

const BackupFilesPage = () => {
	const [backupFiles, setBackupFiles] = useState([]);
	const [loading, setLoading] = useState(true); // State to manage loading status
	const [currentPage, setCurrentPage] = useState(1); // State to manage current page
	const [path, setPath] = useState('');
	const { userRole } = useAuth();

	useEffect(() => {
		const fetchBackupFiles = async () => {
			try {
				const response = await axiosClient.get('notification/files');

				setBackupFiles(response.data.backup_filenames);
				setPath(response.data.backup_path);
				try {
					const response = await axiosClient.post('notification/mark-read');
				} catch (error) {
					console.error('Error marking notification as read:', error);
				}
			} catch (error) {
				console.error('Error fetching backup files:', error);
			} finally {
				setLoading(false);
			}
		};

		fetchBackupFiles();
	}, []);

	// Calculate items to display for pagination
	const itemsPerPage = 6;
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = backupFiles.slice(indexOfFirstItem, indexOfLastItem);

	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<div style={{ textAlign: 'center', }}>
			<h1>
				Bases de données de sauvegarde
				{userRole === 'superadmin' && path && (
					<span style={{ display: 'block', fontSize: 'smaller', color: 'gray', marginTop: '8px' }}>
						{path}
					</span>
				)}
			</h1>
			{loading ? (
				<Spin size="large" />
			) : (
				<List
					size="large"
					bordered
					dataSource={currentItems}
					renderItem={(item, index) => (
						<>
							<List.Item
								style={{
									padding: '14px 16px', 
								}}
							>
								<a
									// href={`http://localhost:8000/api/download-backup/${item}`}
									href={`${baseURL}/download-backup/${item}`}
									target="_blank"
									rel="noopener noreferrer"
									style={{
										display: 'block',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap',
									}}
								>
									{item}
								</a>
							</List.Item>
							{index !== currentItems.length - 1 && (
								<Divider style={{ margin: '4px 0', borderColor: 'lightgray', borderWidth: '1px' }} />
							)}
						</>
					)}
					pagination={{
						pageSize: itemsPerPage,
						total: backupFiles.length,
						onChange: paginate,
					}}
				/>

			)}
		</div>
	);
};

export default BackupFilesPage;
