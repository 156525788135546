import React, { createContext, useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { message } from "antd"; // Importing message from antd

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
	const [isLogged, setIsLogged] = useState(localStorage.getItem("auth_token") !== null);
	const [userId, setUserId] = useState(parseInt(localStorage.getItem("user_id")));

	const [username, setUsername] = useState(localStorage.getItem("auth_name"));
	const [email, setEmail] = useState(localStorage.getItem("email"));
	const [image, setImage] = useState(localStorage.getItem("image"));
	const [userRole, setUserRole] = useState(null);
	const [userData, setUserData] = useState(null);

	const history = useHistory();

	const handleLoginSuccess = ({ token, id, name, role, email, image }) => {

		setIsLogged(true);
		setUserId(id);
		setUsername(name);
		setUserRole(role);
		setEmail(email);
		setImage(image);

		localStorage.setItem("auth_token", token);
		localStorage.setItem("user_id", id);
		localStorage.setItem("auth_name", name);
		localStorage.setItem("user_role", role);
		localStorage.setItem("email", email);
		localStorage.setItem("image", image);
		// localStorage.setItem("is_logged_in", "true");

	};

	const handleLogout = () => {
		// Clear all relevant localStorage items
		localStorage.removeItem("auth_token");
		localStorage.removeItem("user_id");
		localStorage.removeItem("auth_name");
		localStorage.removeItem("user_role");
		localStorage.removeItem("email");
		localStorage.removeItem("image");
		// localStorage.removeItem("is_logged_in"); // Remove the logged-in flag
		// localStorage.setItem("is_logged_in", "false");


		// Reset state variables
		setIsLogged(false);
		setUserId(null);
		setUsername(null);
		setUserRole(null);
		setEmail(null);
		setImage(null);

		// Redirect the user to the login page
		history.push("/login");

		// Display a logout success message
		message.success("Vous êtes déconnecté.");
		
	};

	useEffect(() => {
		const fetchUserRole = async () => {
			try {
				const role = localStorage.getItem("user_role");
				if (role) {
					setUserRole(role);
				}
			} catch (error) {
				console.error("Error fetching user role:", error);
			}
		};

		fetchUserRole();
	}, [isLogged]);

	useEffect(() => {
		const currentPath = history.location.pathname;
		const allowedPaths = ["/login", "/not-authorized", "/"];
		if (!isLogged && !allowedPaths.includes(currentPath)) {
			history.push("/not-authorized");
		}
	}, [isLogged, history]);

	const value = {
		isLogged,
		userId,
		username,
		userRole,
		handleLoginSuccess,
		handleLogout,
		email,
		userData,
		setUserData,
		image,
		setImage,
	};

	return (
		<AuthContext.Provider value={value}>
			{children}
		</AuthContext.Provider>
	);
};
