import React, { useState, useEffect } from "react";
import { Table, Select, DatePicker, Card, Row, Col, Button, Input, Tag,Typography } from "antd";
import { fetchRdvData, fetchAgentOptions, fetchAgendaOptions, handleDownloadClick2 } from "../../rdv/services/api";
import { useCalendar } from "../../../CalendarContext";
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { DownloadOutlined } from '@ant-design/icons';
import {
	EyeOutlined
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Title } = Typography;

const History = () => {
	const [tableData, setTableData] = useState({

	});
	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchedColumn] = useState('');

	const {
		rdvLoading, setRdvLoading,
		selectedAgent, setSelectedAgent,
		selectedAgenda, setSelectedAgenda,
		selectedDateRange, setSelectedDateRange,
		agentOptions, setAgentOptions,
		agendaOptions, setAgendaOptions, setModifiedAgent, setHistFullData, appointments
	} = useCalendar();

	const [filtersChanged, setFiltersChanged] = useState(false);
	const history = useHistory();

	useEffect(() => {
		fetchRdvData(selectedAgent, selectedAgenda, selectedDateRange, setTableData, setRdvLoading, tableData,);

		fetchAgentOptions(setAgentOptions);
		fetchAgendaOptions(setAgendaOptions);
		setHistFullData(true)
	}, [appointments]);

	useEffect(() => {
		if (filtersChanged) {
			fetchRdvData(selectedAgent, selectedAgenda, selectedDateRange, setTableData, setRdvLoading, tableData);
			setFiltersChanged(false);
			setHistFullData(true)
		}
	}, [filtersChanged, setHistFullData]);
	useEffect(() => {

		// Clear selectedAgent and selectedAgenda when component unmounts
		return () => {
			setSelectedAgent([]);
			setSelectedAgenda([]);
			setSelectedDateRange(null);
		};
	}, []);

	const columnStyle = {
		fontSize: "12px !important",
		verticalAlign: "middle",
		wordBreak: "normal",
		fontWeight: "bold !important",
	};

	const getColumnSearchProps = dataIndex => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, }) => (
			<div style={{ padding: 8 }}>
				<Input.Search
					placeholder={`Recherche ${dataIndex}`}
					onSearch={value => {
						setSelectedKeys([value]);
						confirm();
					}}
					onInput={e => setSearchText(e.target.value)}
					enterButton
					value={selectedKeys[0]}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
					id="searchInput"
					allowClear
				/>
			</div>

		),

		onFilter: (value, record) =>
			record[dataIndex]
				? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
				: '',
		onFilterDropdownVisibleChange: visible => {
			if (visible) {
				setTimeout(() => document.getElementById('searchInput').select(), 100);
			}
		},
		render: text =>
			searchedColumn == dataIndex ? (
				<span className="columnStyle">
					{text.split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')).map((fragment, i) => (
						fragment.toLowerCase() == searchText.toLowerCase()
							? <span key={i} className="highlight columnStyle">{fragment}</span> : fragment
					))}
				</span>
			) : (
				text
			),
		style: {
			fontSize: "12px !important",
			verticalAlign: "middle",
			wordBreak: "normal",
			fontWeight: "bold !important",
		},
	});

	const columns = [
		{
			title: "Agent",
			dataIndex: "agentName",
			key: "agent",
			width: 50,
			render: (agentName) => (
				<span style={columnStyle}>
					{agentName}
				</span>
			),
			...getColumnSearchProps('agentName'),

		},
		{
			title: "Agenda",
			dataIndex: "agendaContactName",
			key: "agentCommercial",
			render: (agendaContactName) => (
				<span style={columnStyle}>
					{agendaContactName}
				</span>
			),
			...getColumnSearchProps('agendaContactName'),
		},
		{
			title: 'Statut',
			dataIndex: 'status',
			key: 'status',
			render: (status) => (
				<Tag
					color={
						status === 'NRP' ? '#FF7F3E' :
							status === 'confirmer' ? '#00E396' :
								status === 'encours' ? '#373A40' : // Add the new status 'encours' with the color '#FFD700'
									'#FFFFFF'
					}
					style={{ fontWeight: '400', fontSize: '12px', lineHeight: '1.5', marginBottom: '0' }}
				>
					{status}
				</Tag>
			),
		},


		{
			title: "Date",
			dataIndex: "date",
			key: "start_date",
			render: (date) => (
				<span style={columnStyle}>
					{date ? new Date(date).toLocaleString(undefined, {
						year: 'numeric',
						month: '2-digit',
						day: '2-digit',
						hour: '2-digit',
						minute: '2-digit'
					}) : "-"}
				</span>
			),
			...getColumnSearchProps('date'),
		},

		{
			title: "Dernière modification",
			dataIndex: "updateAt",
			key: "updated_at",
			render: (updatedAt) => (
				<span style={columnStyle}>
					{updatedAt ? new Date(updatedAt).toLocaleString(undefined, {
						year: 'numeric',
						month: '2-digit',
						day: '2-digit',
						hour: '2-digit',
						minute: '2-digit'
					}) : "-"}
				</span>
			),
			...getColumnSearchProps('updateAt'),

		},
		{
			title: "Modifié par",
			dataIndex: "modifiedByName",
			key: "modifiedBy",
			render: (modifiedByName) => (
				<span style={{
					...columnStyle,
					color: modifiedByName === "Pas encore modifié" ? "#BEBEBE" : "inherit",
					whiteSpace: 'pre-wrap', // Ensures new lines are respected
				}}>
					{Array.isArray(modifiedByName) ? modifiedByName.map(user => `${user.nom} ${user.prenom}`).join('\n') : modifiedByName}
				</span>
			),
			...getColumnSearchProps('modifiedByName'),
		}
		,
		{
			title: "Action",
			key: "action",
			render: (_, record) => (
				<div>
					<Button
						type="link"
						onClick={() => handleDetailsClick(record)}
					>
						<EyeOutlined />
					</Button>
				</div>
			),
		},
	];

	const handleDetailsClick = (id) => {
		setModifiedAgent(id.agent)
		history.push(`/details-rdv-modifie/${id.id}`);
	};

	const downloadExcel = async () => {
		await handleDownloadClick2(tableData)

	}
	const isDataAvailable = Array.isArray(tableData) && tableData.length > 0;
	return (
		<>
			<Card style={{ marginBottom: "20px" }}>
				<Row style={{ alignItems: "center" }}>
					<Col span={10}>
					<Title level={4}>
					Tableau des Rendez-vous modifiés
				</Title>
					</Col>
				</Row>
				<Row style={{ alignItems: "center", marginBottom: "10px" }} gutter={[16, 16]}>


					<Col lg={5} xs={24} md={8} style={{ verticalAlign: "middle", }}>
						<Select
							mode="multiple"
							style={{ width: 200, marginRight: "10px" }}
							placeholder="Sélectionner un Agent"
							onChange={(value) => {
								setSelectedAgent(value);
								setFiltersChanged(true);
							}}
							allowClear
							showSearch
							filterOption={(input, option) =>
								(option?.children ?? '').toLowerCase().includes(input.toLowerCase())
							}
							value={selectedAgent}
						>
							{agentOptions.map(agent => (
								<Option key={agent.id} value={agent.id}>{`${agent.nom} ${agent.prenom}`}</Option>
							))}
						</Select>

					</Col>
					<Col lg={5} xs={24} md={8} style={{ verticalAlign: "middle" }}>
						<Select
							style={{ width: 200, marginRight: "10px" }}
							mode="multiple"
							placeholder="Sélectionner un Agenda"
							onChange={(value) => {
								setSelectedAgenda(value);
								setFiltersChanged(true);
							}}
							allowClear
							value={selectedAgenda}
							showSearch
							filterOption={(input, option) =>
								(option?.children ?? '').toLowerCase().includes(input.toLowerCase())
							}
						>
							{agendaOptions.map(agenda => (
								<Option key={agenda.id} value={agenda.id}>
									{`${agenda.contact_nom} ${agenda.contact_prenom}`}
								</Option>
							))}
						</Select>
					</Col>
					<Col lg={6} xs={24} md={8} style={{ verticalAlign: "middle" }}>
						<RangePicker
							style={{ marginRight: "10px" }}
							showTime={{ format: 'DD/MM/YYYY' }}

							placeholder={['Date de début', 'Date de fin']}
							onChange={(dates) => {
								setSelectedDateRange(dates)
								setFiltersChanged(true);
							}}
							value={selectedDateRange}
							disabledDate={(current) => current && (current.day() == 0 || current.day() == 6)}
						/>
					</Col>
					<Col lg={4} xs={24} md={24} style={{ verticalAlign: "middle" }} offset={4}>
						<Button type="primary" onClick={downloadExcel} style={{ width: "100%" }} icon={<DownloadOutlined />}>
							Télécharger
						</Button>
					</Col>
				</Row>

				<Table columns={columns} dataSource={isDataAvailable ? tableData : []} loading={rdvLoading} pagination={{ pageSize: 5 }} className="table-container" />

			</Card>



		</>
	);
};


export default History;