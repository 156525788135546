import React, { useState, useEffect } from "react";
import {
	Form,
	DatePicker,
	Row,
	Col,
	Card,
	ConfigProvider,
	Alert,
} from "antd";
import frFR from "antd/lib/locale/fr_FR";
import SaveButton from "../../../constants/SaveButton";
import moment from "moment";
import { fetchAgents } from "../services/api";
import { useAuth } from "../../../AuthContext";
import { handleClick } from "../services/api";

const ReporterRdv = ({ initialValues, onFormSubmit, setReporterModalVisible, handleRollback }) => {
	const [form] = Form.useForm();
	const [loadingEnregistrer, setLoadingEnregistrer] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [isRunning, setIsRunning] = useState(false);
	const [agents, setAgents] = useState([]);
	const [formattedAgentInfo, setFormattedAgentInfo] = useState(null);
	const [selectedStartDate, setSelectedStartDate] = useState(null);
	const [selectedEndDate, setSelectedEndDate] = useState(null);
	const { userId } = useAuth();

	useEffect(() => {
		fetchAgents(setAgents, initialValues, setFormattedAgentInfo);
	}, []);

	const [formData, setFormData] = useState({
		title: "",
		nom: "",
		prenom: "",
		nom_ste: "",
		postal: "",
		adresse: "",
		tva: "",
		tel: "",
		gsm: "",
		fournisseur: "",
		nbr_comp_elect: "",
		nbr_comp_gaz: "",
		ppv: false,
		tarif: false,
		pro: false,
		haute_tension: false,
		tarification: "",
		commentaire: "",
		note: "",
		bloquer: false,
		appointment_date: null,
	});
	const rdvList = initialValues.rdv || initialValues;

	useEffect(() => {
		if (rdvList && Object.keys(rdvList).length > 0) {
			const formattedInitialValues = {
				...rdvList,
				nom_prenom: `${rdvList.nom} ${rdvList.prenom}`,
				startTime: moment(rdvList.start_date),
				endTime: moment(rdvList.end_date),
				ppv: Boolean(rdvList.ppv),
				bloquer: Boolean(rdvList.bloquer),
				pro: Boolean(rdvList.pro),
				tarif: Boolean(rdvList.tarif),
				haute_tension: Boolean(rdvList.haute_tension),
				tarification: rdvList.tarification === "Variable" ? true : false,
				commentaire: rdvList.commentaire,
				note: rdvList.note,
			};
			form.setFieldsValue(formattedInitialValues);
			setFormData(formattedInitialValues);
		}
	}, [rdvList, form]);

	const handleStartDateChange = (date) => {
		setSelectedStartDate(date);

		if (date) {
			const newEndDate = moment(date).add(1, 'hour');
			setSelectedEndDate(newEndDate);

			setFormData({
				...formData,
				appointment_date: [
					date.toDate(),
					newEndDate.toDate()
				]
			});
		} else {
			setFormData({
				...formData,
				appointment_date: [
					null,
					selectedEndDate ? selectedEndDate.toDate() : null
				]
			});
		}
	};

	const disabledDate = (current) => {
		// Disable today and any past dates
		return current && (current < moment().startOf("day") || current.isSame(moment().startOf("day"), 'day'));
	};

	const disabledTime = (date) => {
		if (date) {
			const hour = date.hour();
			return {
				disabledHours: () => Array.from({ length: 24 }, (_, i) => i).filter(h => h < 9 || h > 18),
				disabledMinutes: () => [],
				disabledSeconds: () => [],
			};
		}
		return {
			disabledHours: () => [],
			disabledMinutes: () => [],
			disabledSeconds: () => [],
		};
	};


	return (
		<Form layout="vertical" form={form} onFinish={() => handleClick(
			isRunning, setIsRunning, setShowAlert, formData, rdvList, setLoadingEnregistrer, userId,
			onFormSubmit,
			setReporterModalVisible
		)}>
			{showAlert && (
				<Alert
					message="La date sélectionnée est déjà réservée."
					type="warning"
					showIcon
					closable
					onClose={() => setShowAlert(false)}
				/>
			)}
			<Card style={{ marginBottom: "10px" }}>
				<Row justify="end" gutter={[16, 16]}>
					<Col>
						<SaveButton

							loading={loadingEnregistrer}
							buttonText="Enregistrer"
						/>
					</Col>
				</Row>
			</Card>
			<Card style={{ marginBottom: "10px" }}>
				<Row gutter={[16, 16]}>
					<Col span={12}>
						<ConfigProvider locale={frFR}>
							<DatePicker
								defaultValue={moment(rdvList?.start_date, "YYYY-MM-DD HH:mm:ss")}
								onChange={handleStartDateChange}
								clearIcon={null}
								format="YYYY-MM-DD HH:mm"
								showTime={{ format: "HH:mm", minuteStep: 15 }}
								disabledDate={disabledDate}
								disabledTime={disabledTime}
							/>
						</ConfigProvider>
					</Col>
					<Col span={12}>
						<ConfigProvider locale={frFR}>
							<DatePicker
								value={selectedEndDate ? moment(selectedEndDate, "YYYY-MM-DD HH:mm:ss") : null}
								clearIcon={null}
								format="YYYY-MM-DD HH:mm"
								showTime={{ format: "HH:mm", minuteStep: 15 }}
								disabledDate={disabledDate}
								disabledTime={disabledTime}
								disabled={true}
							/>
						</ConfigProvider>
					</Col>
				</Row>
			</Card>
		</Form>
	);
};

export default ReporterRdv;
