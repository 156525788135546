import React from 'react';
import { Button } from 'antd';
import './ButtonStyle.css';
import { CloseOutlined } from "@ant-design/icons";

const ModifierButton = ({ onClick, loading, buttonText }) => {
	return (
		<div>
			<Button className="ann-button" onClick={onClick} color="processing" loading={loading} type="primary" ghost icon={<CloseOutlined />}>
				{buttonText}
			</Button>

		</div>
	);
};

export default ModifierButton;
