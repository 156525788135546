import React, { useState, useEffect } from 'react';
import { Button, Card, Table, Tag,  Space, DatePicker } from 'antd';
import { axiosClient } from '../../../api/axios';
import { useParams, useHistory } from 'react-router-dom';
import { ExclamationCircleOutlined, DownloadOutlined } from '@ant-design/icons'; 
import SingleRdvSupprimerDetail from "../RdvSupprimerHistoriaue/SingleRdvSupprimerDetail"
import moment from "moment";
import {handleDownloadAppointment} from '../services/api'


const UserAppointments = () => {
	const [appointments, setAppointments] = useState([]);
	const { userId } = useParams();
	const [loading, setLoading] = useState(false);
	const [agentName, setAgentName] = useState('');
	const history = useHistory();

	const [modalVisible, setModalVisible] = useState(false);
	const [selectedAppointment, setSelectedAppointment] = useState(null);
	const [startDateFilter, setStartDateFilter] = useState(null);
	const [endDateFilter, setEndDateFilter] = useState(null);

	const handleDownload = (appointment) => {
    handleDownloadAppointment(appointment);
  };

	useEffect(() => {
		// Fetch appointments for the specified user ID
		fetchAppointments();
	}, []);

	const fetchAppointments = async () => {
		try {
			setLoading(true);
			const response = await axiosClient.get(`rdvs/users/${userId}`);
			const formattedAppointments = response.data.map(appointment => ({
				...appointment,
				start_date: moment(appointment.start_date).format('ddd, DD MMM YYYY HH:mm'),
				deleted_at: moment(appointment.deleted_at).format('ddd, DD MMM YYYY HH:mm'),
				end_date: moment(appointment.end_date).format('HH:mm')
		}));
		setAppointments(formattedAppointments);
			if (response.data.length > 0) {
				const agent = response.data[0].user;
				setAgentName(`${agent.nom} ${agent.prenom}`);
			}
		} catch (error) {
			console.error('Error fetching appointments:', error);
		} finally {
			setLoading(false);
		}
	};

	const columns = [
		{
			title: 'Client',
			dataIndex: 'nom',
			key: 'client',
			render: (text, record) => {
				if (record.prenom) {
					return `${record.nom} ${record.prenom}`;
				} else {
					return record.nom;
				}
			},
		},
		{
			title: 'Agenda',
			dataIndex: ['agenda', 'user', 'nom'],
			key: 'agenda',
			render: (text, record) => `${record.agenda.user.nom} ${record.agenda.user.prenom}`,
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: (status) => {
				let color = '';
				switch (status) {
					case 'encours':
						color = 'orange';
						break;
					case 'confirmer':
						color = 'green';
						break;
					case 'NRP':
						color = 'blue';
						break;
					default:
						color = 'default';
				}
				return <Tag color={color}>{status}</Tag>;
			},
		},
		{
			title: 'TEL',
			dataIndex: 'tel',
			key: 'tel',
			render: (tel) => `+32 ${tel}`,
		},
		{
			title: 'Postal',
			dataIndex: 'postal',
			key: 'postal',
		},
		{
			title: 'Date de début',
			dataIndex: 'start_date',
			key: 'start_date',
			render: (start_date) => start_date.substring(0, 16),
		},
		{
			title: 'Date de fin',
			dataIndex: 'end_date',
			key: 'end_date',
			render: (end_date) => end_date.substring(0, 16),
		},
		{
			title: 'Date de création',
			dataIndex: 'created_at',
			key: 'created_at',
			render: (created_at) => {
				const date = new Date(created_at);
				const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
				return formattedDate;
			},
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<Space size="middle">
					<Button type="link" onClick={() => handleViewDetails(record)}>
						<ExclamationCircleOutlined />
					</Button>
					<Button type="link" onClick={() => handleDownload(record)} icon={<DownloadOutlined />} />
				</Space>
			),
		},
	];


	const handleViewDetails = (appointment) => {
		setSelectedAppointment(appointment);
		setModalVisible(true);
	};

	const handleModalCancel = () => {
		setModalVisible(false);
	};


	

	const handleDateFilterChange = (dates) => {
		const [start, end] = dates;
		setStartDateFilter(start);
		setEndDateFilter(end);
	};

	// Filter appointments based on created_at date range
	const filteredAppointments = appointments.filter((appointment) => {
		if (!startDateFilter || !endDateFilter) {
			return true; 
		}

		const appointmentDate = new Date(appointment.created_at);
		return (
			appointmentDate >= startDateFilter.startOf('day') && appointmentDate <= endDateFilter.endOf('day')
		);
	});

	return (
		<>
			<Button onClick={() => history.goBack()} style={{ marginBottom: 16 }}>
				Retour
			</Button>
			<Card title={agentName}>
				<DatePicker.RangePicker
					onChange={handleDateFilterChange}
					format="YYYY-MM-DD"
					style={{ marginBottom: 16 }}
				/>
				<Table
					dataSource={filteredAppointments}
					columns={columns}
					rowKey="id"
					pagination={{ pageSize: 6 }}
					className="table-container"
					loading={loading}
				/>
			</Card>

			<SingleRdvSupprimerDetail
				appointment={selectedAppointment}
				visible={modalVisible}
				onClose={handleModalCancel}
				onDownload={handleDownload}
			/>

		</>
	);
};

export default UserAppointments;

