import React from 'react';
import { Button } from 'antd';
import './ButtonStyle.css';
import { DeliveredProcedureOutlined,CopyOutlined } from "@ant-design/icons";

const DupliquerBtn = ({ onClick, loading, buttonText }) => {
	return (
		<div>
			<Button className="dup-button" onClick={onClick}  loading={loading} ghost icon={<CopyOutlined />}>
				{buttonText}
			</Button>

		</div>
	);
};

export default DupliquerBtn;
