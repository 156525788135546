import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Typography, Select, DatePicker, Col, Row, Empty } from 'antd';
import { axiosClient } from '../../api/axios';
import moment from "moment";
import { useCalendar } from '../../CalendarContext';
import { fetchContactsUsers } from '../../pages/contacts/services/api';

const { MonthPicker } = DatePicker;
const { Title, Paragraph } = Typography;
const { Option } = Select;

function LineChart() {
	const [appointments, setAppointments] = useState([]);
	const [selectedAgent, setSelectedAgent] = useState(null);
	const [selectedMonth, setSelectedMonth] = useState(null);
	const { setLineData, contacts, setContacts, setAgentCommercialUsers } = useCalendar();

	useEffect(() => {
		fetchData();
		fetchContactsUsers(setAgentCommercialUsers, setContacts);
		const currentDate = new Date();
		const currentMonth = currentDate.getMonth() + 1;
		setSelectedMonth(currentMonth.toString());
	}, []);
	console.log('contacts', contacts)

const fetchData = async () => {
	try {
		console.log('fetch data called')

		const response = await axiosClient.get('rdvs');
		const currentDate = new Date();
		const currentMonth = currentDate.getMonth() + 1;
		const currentYear = currentDate.getFullYear();
		console.log('Appointments:', response.data);

		const filteredAppointments = response.data.filter(appointment => {
			console.log('appointment', appointment)
			const appointmentDate = new Date(appointment.start_date);
			console.log('appointmentDate', appointmentDate)

			const appointmentMonth = appointmentDate.getMonth() + 1; 

			const appointmentYear = appointmentDate.getFullYear();
			
			console.log('appointment prop reserve, prive, bloquer', appointment.reserver_plage_horaire,appointment.rdv_prive, appointment.bloquer  )
			console.log('appointment types prop reserve, prive, bloquer', typeof appointment.reserver_plage_horaire,typeof appointment.rdv_prive, typeof appointment.bloquer  )
			console.log('appointmentMonth', appointmentMonth, typeof appointmentMonth)
			console.log('currentMonth', currentMonth, typeof currentMonth)
			console.log('appointmentYear', appointmentYear, typeof appointmentYear)
			console.log('currentYear', currentYear, typeof currentYear)

			const returnedAppointment = appointment.reserver_plage_horaire == 0 &&
			appointment.rdv_prive == 0 &&
			appointment.bloquer == 0 &&
			appointmentMonth == currentMonth &&
			appointmentYear == currentYear
			console.log('returned value for reservere', appointment.reserver_plage_horaire == 0)
			console.log('returned value for rdv_prive', appointment.rdv_prive == 0)
			console.log('returned value for bloquer', appointment.bloquer == 0)
			console.log('returned value for appointmentMonth == currentMonth', appointmentMonth == currentMonth)
			console.log('returned value for appointmentYear == currentYear',appointmentYear == currentYear)


			return returnedAppointment
		});

		console.log('Filtered appointments for current month:', filteredAppointments);
		setAppointments(filteredAppointments); 
		setLineData(true);
	} catch (error) {
		console.error('Error fetching appointments:', error.response);
	}
};


	const capitalizeFirstLetter = (string) => {
		return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
	};

	const handleAgentChange = (value) => {
		console.log('SelectedAgent', value)
		console.log('type of SelectedAgent ', typeof value)
		setSelectedAgent(value);
	};

	const handleMonthChange = (date, dateString) => {
		const formattedMonth = parseInt(dateString.split('-')[1]).toString();
		setSelectedMonth(formattedMonth);
		console.log('formattedMonth', formattedMonth)
		console.log('type of formattedMonth ', typeof formattedMonth)


	};

	const getAppointmentsCountByAgentAndDay = (selectedAgent, selectedMonth) => {
		const appointmentsCountByAgentAndDay = {};
		if (selectedAgent) {
			appointmentsCountByAgentAndDay[selectedAgent] = {};
	
			appointments.forEach((appointment) => {
				const appointmentDate = new Date(appointment.start_date);
				const appointmentMonth = appointmentDate.getMonth() + 1;
				const appointmentDay = appointmentDate.getDate();
				const agentName = appointment.agenda?.user ? `${capitalizeFirstLetter(appointment.agenda.user.prenom)} ${capitalizeFirstLetter(appointment.agenda.user.nom)}` : '';
	
				if (
					selectedAgent == agentName &&
					(!selectedMonth || appointmentMonth.toString() == selectedMonth)
				) {
					if (!appointmentsCountByAgentAndDay[selectedAgent][appointmentDay]) {
						appointmentsCountByAgentAndDay[selectedAgent][appointmentDay] = { confirmer: 0, nrp: 0, total: 0 };
					}
	
					if (appointment.status === 'confirmer') {
						console.log('appointmentsCountByAgentAndDay[selectedAgent][appointmentDay]', appointmentsCountByAgentAndDay[selectedAgent][appointmentDay])
						console.log('appointmentsCountByAgentAndDay[selectedAgent][appointmentDay]', typeof appointmentsCountByAgentAndDay[selectedAgent][appointmentDay])


						appointmentsCountByAgentAndDay[selectedAgent][appointmentDay].confirmer++;
						appointmentsCountByAgentAndDay[selectedAgent][appointmentDay].total++;
					} else if (appointment.status === 'NRP') {
						appointmentsCountByAgentAndDay[selectedAgent][appointmentDay].nrp++;
						appointmentsCountByAgentAndDay[selectedAgent][appointmentDay].total++;
					}
				}
			});
		}
		return appointmentsCountByAgentAndDay;
	};
	
	const getTotalAppointmentsCount = (selectedAgent, selectedMonth) => {
		const total = {};
	
		if (selectedAgent) {
			total[selectedAgent] = { confirmer: 0, nrp: 0, annuler: 0, encours: 0 };
	
			appointments.forEach((appointment) => {
				const appointmentDate = new Date(appointment.start_date);
				const appointmentMonth = appointmentDate.getMonth() + 1;
				const agentName = appointment.agenda?.user ? `${capitalizeFirstLetter(appointment.agenda.user.prenom)} ${capitalizeFirstLetter(appointment.agenda.user.nom)}` : '';
	
				if (
					selectedAgent === agentName &&
					(!selectedMonth || appointmentMonth.toString() === selectedMonth)
				) {
					if (appointment.status === 'confirmer') {
						total[selectedAgent].confirmer++;
					} else if (appointment.status === 'NRP') {
						total[selectedAgent].nrp++;
					} else if (appointment.status === 'annuler') {
						total[selectedAgent].annuler++;
					} else if (appointment.status === 'encours') {
						total[selectedAgent].encours++;
					}
				}
			});
		}
	
		return total;
	};
	

	const appointmentsCountByAgentAndDay = getAppointmentsCountByAgentAndDay(selectedAgent, selectedMonth);
	const totalAppointments = getTotalAppointmentsCount(selectedAgent, selectedMonth);

	const chartData = selectedAgent ? [
		{
			name: `${selectedAgent} - Total`,
			data: Array.from({ length: 31 }, (_, i) => appointmentsCountByAgentAndDay[selectedAgent][i + 1]?.total || 0),
		}
	] : [];

	const chartOptions = {
		chart: {
			height: 350,
			type: 'line',
			toolbar: {
				show: false,
			},
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			curve: 'smooth',
		},
		xaxis: {
			type: 'category',
			categories: Array.from({ length: 31 }, (_, i) => (i + 1).toString()),
		},
		yaxis: {
			title: {
				text: 'Nombre de rendez-vous',
			},
			labels: {
				formatter: function (value) {
					return value == Math.floor(value) ? value.toFixed(0) : '';
				},
			},
		},
		legend: {
			show: true,
		},
		colors: ['#55AD9B'],
		tooltip: {
			shared: true,
			custom: function({ series, seriesIndex, dataPointIndex, w }) {
				const day = dataPointIndex + 1;
				const agentData = appointmentsCountByAgentAndDay[selectedAgent][day] || { confirmer: 0, nrp: 0 };
				return (
					'<div class="tooltip">' +
					'<span style="color: #4FE572;">Confirmé : ' + agentData.confirmer + ' </span><br/>' +
					'<span style="color: #FF7F3E;">NRP : ' + agentData.nrp + '</span>' +
					'</div>'
				);
			}
		}
	};

	return (
		<>
			<div className="linechart">
				<div>
					<Title level={4}>Statistiques du nombre des rendez-vous (Confirmé + NRP) par mois : {selectedAgent ? selectedAgent.toUpperCase() : ''}</Title>
					
					<Select
						allowClear
						style={{ width: 400 }}
						placeholder="Sélectionner un agent commercial"
						onChange={handleAgentChange}
						value={selectedAgent}
					>
						{contacts.map((contact) => {
							const fullName = `${capitalizeFirstLetter(contact.prenom)} ${capitalizeFirstLetter(contact.nom)}`;
							return (
								<Option key={contact.id} value={fullName}> 
									{fullName}
								</Option>
							);
						})}
					</Select>
					<MonthPicker
						style={{ width: 200, marginLeft: 10 }}
						placeholder="Sélectionner un mois"
						onChange={handleMonthChange}
						value={selectedMonth ? moment(`${new Date().getFullYear()}-${selectedMonth.padStart(2, '0')}-01`, 'YYYY-MM-DD') : null}
					/>
				</div>
			</div>
			{selectedAgent ? (
				<Row>
					<Col span={20}>
						<ReactApexChart options={chartOptions} series={chartData} type="line" height={350} />
					</Col>
					<Col span={4}>
						<div style={{ marginLeft: 10, marginTop: "50%",padding: 10, border: '1px solid #ddd', borderRadius: 4, backgroundColor: 'rgba(110, 185, 170, 0.5)', minWidth: '100px', fontSize: '12px' }}>
							<Paragraph strong>{selectedAgent.toUpperCase()}</Paragraph>
							<Paragraph>En cours: {totalAppointments[selectedAgent]?.encours}</Paragraph>
							<Paragraph>Confirmer: {totalAppointments[selectedAgent]?.confirmer}</Paragraph>
							<Paragraph>NRP: {totalAppointments[selectedAgent]?.nrp}</Paragraph>
							<Paragraph>Annulé: {totalAppointments[selectedAgent]?.annuler}</Paragraph>
						</div>
					</Col>
				</Row>
			) : (
				<Empty description="Aucun agent sélectionné" />
			)}
		</>
	);
}

export default LineChart;
