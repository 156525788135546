// src/components/List/AgendaList.js
import React, { useEffect, useState } from 'react';
import { Spin, Card, Typography, message } from 'antd';
import AgendaTable from './Components/AgendaTable';
import { fetchAgendas, handleHideAgenda, handleShowAgenda } from '../Servives/api';
import { fetchAgentCommercialUsers } from '../../caledrier/services/api';
import { useCalendar } from '../../../CalendarContext';
import { useAuth } from '../../../AuthContext';

const { Title } = Typography;

const AgendaList = () => {
	const [calendrier, setCalendrier] = useState([]);
	const [loading, setLoading] = useState(true);
	const { agentCommercialUsers, setAgentCommercialUsers } = useCalendar();
	const {role} = useAuth()

	useEffect(() => {
		const loadAgendas = async () => {
			try {
				const agendas = await fetchAgendas();
				setCalendrier(agendas);
			} catch (error) {
				console.error('Error fetching agendas:', error);
			} finally {
				setLoading(false);
			}
		};

		loadAgendas();
	}, []);

	const handleHideAgendaWrapper = async (agendaId, contactId) => {
		try {
			
			await handleHideAgenda(agendaId);
			setCalendrier((prevCalendrier) =>
				prevCalendrier.map((agenda) =>
					agenda.id == agendaId ? { ...agenda, isDisabled: true } : agenda
				)
			);
			// await fetchAgentCommercialUsers(setAgentCommercialUsers,agentCommercialUsers, role);

			message.success('Agenda désactivé avec succès');
		} catch (error) {
			console.error('Error disabling agenda:', error);
			message.error('Erreur lors de la désactivation de l\'agenda');
		}
	};

	const handleShowAgendaWrapper = async (agendaId) => {
		try {
			await handleShowAgenda(agendaId);
			setCalendrier((prevCalendrier) =>
				prevCalendrier.map((agenda) =>
					agenda.id == agendaId ? { ...agenda, isDisabled: false } : agenda
				)
			);
			await fetchAgentCommercialUsers(setAgentCommercialUsers,agentCommercialUsers, role);
			message.success('Agenda réactivé avec succès');
		} catch (error) {
			console.error('Error enabling agenda:', error);
			message.error('Erreur lors de la réactivation de l\'agenda');
		}
	};

	return (
		<>
			{loading ? (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						height: '80vh',
					}}
				>
					<Spin size="large" />
				</div>
			) : (
				<>
					<Title level={3}>Cacher un Agenda</Title>
					<Card>
						<AgendaTable
							calendrier={calendrier}
							handleHideAgenda={handleHideAgendaWrapper}
							handleShowAgenda={handleShowAgendaWrapper}
						/>
					</Card>
				</>
			)}
		</>
	);
};

export default AgendaList;
