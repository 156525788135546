import { Layout, Row, Col } from "antd";
import "../../assets/styles/main.css";
import "../../assets/styles/responsive.css";

function Footer() {
  const { Footer: AntFooter } = Layout;

  return (
    <AntFooter style={{ background: "#fafafa", padding: '10px',  }}>
      <Row justify="center">
        <Col xs={24} md={12} lg={12} style={{ textAlign: 'center' }}>
          <div className="copyright">
            &copy; {new Date().getFullYear()} 
            <a href="#pablo" className="font-weight-bold" target="_blank" rel="noopener noreferrer">
              G&Y
            </a>
            All rights reserved.
          </div>
        </Col>
      </Row>
    </AntFooter>
  );
}

export default Footer;
