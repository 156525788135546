// utils.js
export const highlightText = (text, searchText) => {
  if (!searchText) return text;
  const regex = new RegExp(`(${searchText})`, 'gi');
  return text.split(regex).map((part, index) => {
    return part.toLowerCase() === searchText.toLowerCase() ? <span key={index} style={{ backgroundColor: "#FB6D48", fontWeight: "bold" }}>{part}</span> : part;
  });
};

export const filterUsers = (users, searchText) => {
  return searchText ? users.filter(user =>
    user.nom.toLowerCase().includes(searchText.toLowerCase()) ||
    user.email.toLowerCase().includes(searchText.toLowerCase()) ||
    user.role.toLowerCase().includes(searchText.toLowerCase())
  ) : users;
};

export const modifyUsers = (users) => {
  return users.map(user => {
    return {
      ...user,
      name: `${user?.nom} ${user?.prenom}`
    };
  });
};
