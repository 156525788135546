import React, { useEffect, useState } from "react";
import { DatePicker, Input, Form, ConfigProvider, Card, Row, Col, message, Alert } from "antd";
import frFR from "antd/lib/locale/fr_FR";
import moment from "moment";
import NewButton from "../../../constants/NewButton";
import { axiosClient } from "../../../api/axios";
import { CalendarOutlined } from "@ant-design/icons";

const AddPrivateAppointmentModal = ({ userId, agendaId, onFormSubmit, selectedDate, setShowRdvPrvCalednar }) => {
    const [postal, setPostal] = useState(null);
    const [formData, setFormData] = useState({
        appointment_date: selectedDate
            ? [
                moment(selectedDate.date).utcOffset("+0100"),
                moment(selectedDate.date).add(1, "hour").utcOffset("+0100"),
            ]
            : null,
    });

    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [selectedStartDate, setSelectedStartDate] = useState(null);

    useEffect(() => {
        if (selectedDate && selectedDate.date) {
            setSelectedStartDate(null);
        }
    }, [selectedDate]);

    const handleClick = () => {
        setLoading(true);
        handleFormSubmit();
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    };

    const handleFormSubmit = async () => {
        setLoading(true);
        if (selectedStartDate === null) {
            setLoading(false);
            return;
        }

        const startDate = selectedStartDate.toDate();
        const endDate = selectedStartDate.clone().add(1, 'hour').toDate();


        if (startDate && endDate && !isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
            const startDateFormatted = moment(startDate).format('YYYY-MM-DD HH:mm:ss');
            const endDateFormatted = moment(endDate).format('YYYY-MM-DD HH:mm:ss');

            try {
                const formDataToSend = {
                    start_date: startDateFormatted,
                    end_date: endDateFormatted,
                    id_agent: userId,
                    id_agenda: agendaId,
                    postal: postal,
                };

                const response = await axiosClient.post("rdvs/add-rdv-prv", formDataToSend);
                const newAppointment = {
                    ...response.data,
                    id: response.data.rdv.id,
                };
                setLoading(false);
                // onFormSubmit({ ...response.data, newAppointment });
                setShowRdvPrvCalednar(false);
                message.success("Rendez-vous ajouté avec succès !");
                setShowAlert(false);
                setPostal(null);
                setSelectedStartDate(null);
            } catch (error) {
                if (error.response && error.response.status === 409) {
                    console.error("Error adding appointment:", error);
                    setShowAlert(true);
                    setLoading(false);
                    return;
                }

                setLoading(false);
                console.error("Error adding appointment:", error);
            }
        } else {
            setLoading(false);
        }
    };

    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
    };

    return (
        <Form layout="vertical" onFinish={handleFormSubmit}>
            {showAlert && (
                <Alert
                    message="La date sélectionnée est déjà réservée."
                    type="warning"
                    showIcon
                    closable
                    onClose={() => setShowAlert(false)}
                />
            )}
            <Card style={{ marginTop: "40px" }}>
                <Row gutter={[16, 16]} style={{ marginBottom: "40px" }}>
                    <Col span={9}>
                        <ConfigProvider locale={frFR}>
                            <DatePicker
                                value={selectedStartDate}
                                onChange={handleStartDateChange}
                                format="YYYY-MM-DD HH:mm"
                                showTime={{
                                    format: "HH:mm",
                                    minuteStep: 15,
                                    disabledHours: () => {
                                        const disabledHours = [];
                                        for (let i = 0; i < 9; i++) {
                                            disabledHours.push(i);
                                        }
                                        for (let i = 20; i < 24; i++) {
                                            disabledHours.push(i);
                                        }
                                        return disabledHours;
                                    },
                                }}
                                clearIcon={null}
                                disabledDate={(current) => {
                                    if (current && (current.day() === 0 || current.day() === 6)) {
                                        return true;
                                    }
                                    return current && current < moment().startOf("day");
                                }}
                            />
                        </ConfigProvider>
                    </Col>
                    <Col span={9}>
                        <p style={{ border: '1px solid #d9d9d9', padding: '4px 11px', borderRadius: '5px', backgroundColor: '#f5f5f5', height: "2rem" }}>
                            <Row>
                                <Col span={22}>
                                    {selectedStartDate ? selectedStartDate.clone().add(1, 'hour').format('YYYY-MM-DD HH:mm') : ''}
                                </Col>
                                <Col span={2}>
                                    <CalendarOutlined style={{ color: "#BFBFBF" }} />
                                </Col>
                            </Row>
                        </p>
                    </Col>
                    <Col span={6}>
                        <NewButton
                            onClick={handleClick}
                            loading={loading}
                            buttonText="Ajouter"
                        />
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{ marginBottom: "40px" }}>
                    <Col span={24}>
                        <p><span style={{ color: 'red' }}>* </span> Entrez le code postal :</p>
                        <Form.Item name="postal" rules={[{ required: true, message: 'Veuillez entrer le code postal' }]}>
                            <Input
                                placeholder="Code Postal"
                                value={postal}
                                onChange={(e) => setPostal(e.target.value)}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Card>
        </Form>
    );
};

export default AddPrivateAppointmentModal;
