import axios from 'axios';

const baseURL =  'https://myguestapp.com/api';
const baseURL2 =  'https://myguestapp.com';

// const baseURL =  'http://localhost:8000/api';
// const baseURL2 =  'http://localhost:8000';

axios.defaults.timeout = 60000; // Set global timeout to 60 seconds

// Create an instance of axios with retry functionality
const axiosClient = axios.create({
    baseURL: baseURL,
    withCredentials: true
});

// Create an instance of axios for sanctum/csrf-cookie

const axiosSanctum = axios.create({
	baseURL: 'https://myguestapp.com',
	// baseURL: 'http://localhost:8000',
	withCredentials: true
});

// Configure axios-retry to retry failed requests
// axiosRetry(axiosClient, {
//     retries: 3,
//     retryDelay: axiosRetry.exponentialDelay
// });

axios.interceptors.response.use(
    response => response,
    error => {
      // Show an alert for Axios errors
      // alert(`Axios error: ${error.message}`);
      return Promise.reject(error);
    }
  );

export { axiosClient, axiosSanctum,baseURL,baseURL2 };
