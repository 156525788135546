// src/components/services/api.js
import { axiosClient } from '../../../api/axios';


export const fetchAgendas = async (userId) => {
  try {
    const response = await axiosClient.get(`users/${userId}/agendas`);
    return response.data.agendas;
  } catch (error) {
    console.error('Error fetching agendas:', error);
    throw error;
  }
};

export const handleCheckboxClick = async (
  userId,
  role,
  agentSelectedItemId,
  setAgentSelectedItemId,
  setAgentId,
  selectedItems,
  setSelectedItems,
  setContactAgendas
) => {
  try {
    let updatedSelectedItems = [...selectedItems];

    if (role === "Agent") {
      if (agentSelectedItemId == userId) {
        setAgentSelectedItemId(null);
        setAgentId(null);
        updatedSelectedItems = [];
      } else {
        setAgentSelectedItemId(userId);
        setAgentId(userId);
        updatedSelectedItems = [userId];
      }
    } else {
      setAgentSelectedItemId(null);

      if (selectedItems.includes(userId)) {
        updatedSelectedItems = selectedItems.filter((id) => id != userId);
      } else {
        updatedSelectedItems.push(userId);
      }
    }

    setSelectedItems(updatedSelectedItems);

    const allAgendas = await Promise.allSettled(
      updatedSelectedItems.map(async (id) => await fetchAgendas(id))
    );

    const flattenedAgendas = allAgendas.flatMap((result) =>
      result.status === "fulfilled" ? result.value : []
    );

    setContactAgendas(flattenedAgendas);
  } catch (error) {
    console.error("Error handling checkbox click:", error);
  }
};


export const handleSelectAll = async (
  selectAllChecked,
  agentCommercialUsers,
  setSelectedItems,
  setSelectAllChecked,
  setContactAgendas
) => {
  try {
    let updatedSelectedItems = [];

    if (!selectAllChecked) {
      const allUserIds = agentCommercialUsers.map((user) => user.id);
      updatedSelectedItems = [...allUserIds];
    }

    // Update the state immediately for better UX
    setSelectAllChecked(!selectAllChecked);
    setSelectedItems(updatedSelectedItems);

    const allAgendas = await Promise.allSettled(
      updatedSelectedItems.map(async (id) => await fetchAgendas(id))
    );

    const flattenedAgendas = allAgendas.flatMap((result) =>
      result.status === "fulfilled" ? result.value : []
    );

    setContactAgendas(flattenedAgendas);
  } catch (error) {
    console.error("Error selecting all:", error);
  }
};

export const fetchContactsUsers = async (setAgentCommercialUsers, setContacts) => {
  try {
		console.log('fetch contact called')

    // Make a GET request to the /users endpoint
    const response = await axiosClient.get('users');
    // Filter the users based on the role
    const agentCommercialUsers = response.data.users.filter(user => user.role === 'Agent Commercial');
		setAgentCommercialUsers(agentCommercialUsers)
		setContacts(agentCommercialUsers)
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error; 
  }
};
