// api.js

import { axiosClient } from "../../../api/axios";
import { fullCalendarConfig } from "../services/calendarConfig";

export const handleDeleteAppointment = (appointmentId, appointments, setAppointments, setShowUpdateModal) => {
	try {
		// Update the appointments state to remove the appointment
		const updatedAppointments = appointments.filter(
			(appointment) => appointment.id != appointmentId
		);
		setAppointments(updatedAppointments);
		// Close the update modal
		setShowUpdateModal(false);
	} catch (error) {
		console.error("Error deleting appointment:", error);
	}
};


// Fetches the agenda for the logged-in user (agent) and sets agenda ID and appointments
export const fetchAgentAgenda = async (
	userContext,
	setAgendaId,
	setAppointments
) => {
	if (userContext.userId) {
		try {
		console.log('fetchAgentAgenda called')

			const response = await axiosClient.get(`users/${userContext.userId}/agendas`);
			console.log('response', response.data)
			const agenda = response.data.agenda;
			console.log('agenda', agenda)
			console.log('agenda.id', agenda.id)

			if (agenda && agenda.id) {
				setAgendaId(agenda.id);
				fetchAppointments(agenda.id, setAppointments);
			} else {
				console.error("Agenda or agenda id not found in response data");
			}
		} catch (error) {
			console.error("Error fetching agent agenda:", error);
		}
	} else {
		console.error("User ID not found in userContext");
	}

};

// Fetches appointments for the specified agenda ID and updates the state
export const fetchAppointments = async (agendaId, setAppointments) => {
	try {
		console.log('fetchAppointments called')

		const response = await axiosClient.get(
			`agendas/${agendaId}/appointments`
		);
		const appointmentsData = response.data.rdvs.map((appointment) => ({
			id: appointment.id,
			title: `${appointment.nom} ${appointment.prenom}`,
			start: appointment.start_date
				? new Date(appointment.start_date.replace(" ", "T"))
				: null,
			end: appointment.end_date
				? new Date(appointment.end_date.replace(" ", "T"))
				: null,
			postal: appointment.postal,
			status: appointment.status,
		}));
		setAppointments(appointmentsData);
	} catch (error) {
		console.error("Error fetching appointments:", error);
	}
};


// Fetches agent commercial users and updates the state
export const fetchAgentCommercialUsers = async (setAgentCommercialUsers,agentCommercialUsers, role) => {
	try {
		const response = await axiosClient.get(`users/with-agendas/${role}`);

		console.log('fetchAgentCommercialUsers', response.data)
		setAgentCommercialUsers(response.data);
	} catch (error) {
		console.error("Error fetching agent commercial users:", error);
	}
};

// Fetches agendas and appointments for all agendas, updates state with them
export const fetchAgendasAndAppointments = async (setAgendas, setAppointments) => {
  try {
		console.log('fetchAgendasAndAppointments called')

    const { data: { agendas } } = await axiosClient.get("agendas");
    const allAppointments = agendas.flatMap(agenda => 
      agenda.rdvs.map(appointment => ({
        ...appointment,
        start: new Date(appointment.start_date.replace(" ", "T")),
        end: new Date(appointment.end_date.replace(" ", "T")),
        agendaId: agenda.id,
        id_agent: appointment.id_agent,
        status: appointment.status,
      }))
    );
    setAgendas(agendas);
    setAppointments(allAppointments);
  } catch (error) {
    console.error("Error fetching agendas and appointments:", error);
  }
};

// Handles agenda creation, updates state with FullCalendar data in api.js
export const handleAgendaCreated = async (
	agendaId,
	contactId,
	appointments,
	handleAddAppointment,
	agentId,
	handleEventDrop,
	axiosClient,
	setAppointments
) => {
	try {
		// Combine the default event with existing events from the database
		const updatedEvents = [...appointments];
		const config = fullCalendarConfig(
			appointments,
			handleAddAppointment,
			agentId,
			agendaId,
			handleEventDrop
		);
		console.log('handleAgendaCreated called')


		// Update existing agenda with FullCalendar data
		const response = await axiosClient.put(`agendas/${agendaId}`, {
			fullcalendar_config: config,
			contact_id: contactId,
		});
		if (response && response.data) {
			setAppointments(updatedEvents);
		} else {
			console.error("Unexpected response from server:", response);
		}
		setAppointments(updatedEvents);
	} catch (error) {
		console.error("Error updating agenda with FullCalendar data:", error);
	}
};

// Handles appointment click event, fetches agent ID and appointment details
export const handleAppointmentClick = async (
	event,
	userContext,
	setAgentId,
	setAppointmentDetails,
	setSelectedRowData,
	setShowDetailModal,
	setSelectedAppointment,
	axiosClient,
) => {

	try {
		console.log('handleAppointmentClick called')

		const response = await axiosClient.get(
			`appointments/${event.id}/agent`
		);
		const agentId = response.data.agent.id;
		setAgentId(agentId);
		if (
			agentId == userContext.userId ||
			userContext.userRole === "Superviseur" || userContext.userRole === "Agent" || userContext.userRole === "Admin"
		) {
			const response = await axiosClient.get(`rdvs/${event.id}`);
			const appointmentDetails = response.data;
			setAppointmentDetails(appointmentDetails);
			setSelectedRowData(appointmentDetails);
			setShowDetailModal(true);
			setSelectedAppointment(event);

		}
	} catch (error) {
		console.error("Error fetching agent ID:", error);
	}
};

// Handles adding a new appointment
export const handleAddAppointment = (
	agentId,
	agendaId,
	arg,
	setSelectedDate,
	setShowAddModal
) => {

	setSelectedDate({ date: arg.date, agentId, agendaId });
	setShowAddModal(true);
};

export const handleBlockAppointment = (
	agentId,
	agendaId,
	arg,
	setSelectedDate,
	setShowBlockModal,
) => {
	setSelectedDate({ date: arg.date, agentId, agendaId });
	setShowBlockModal(true);
};


// Handles form submission for new appointment
export const handleFormSubmit = async (
	newAppointment,
	setAppointments,
	agendaId,
	setAgendas,
	handleCloseModal,
	handleCloseBlockModal,
	setShowUpdateModal,
	appointments,
	setSelectedDate
) => {
	try {
		// Update appointments state using the state updater function
		setAppointments((prevAppointments) => [
			...prevAppointments,
			newAppointment,
		]);

		// Fetch agendas and appointments after updating appointments state
		fetchAgendasAndAppointments(setAgendas, setAppointments, agendaId);
		handleCloseModal();
		handleCloseBlockModal()
		setShowUpdateModal(false);
		setSelectedDate(null);
	} catch (error) {
		console.error("Error handling form submission:", error);
	}
};

// Handles event drop (drag-and-drop) for appointments
export const handleEventDrop = async (info, appointments, setAppointments) => {
	try {
		const { event } = info;
		const updatedAppointment = {
			id: event.id,
			start_date: event.start.toISOString(),
			end_date: event.end.toISOString(),
		};

		// Update appointment in the database
		const response = await axiosClient.put(
			`rdvs/${event.id}`,
			updatedAppointment
		);

		// Update appointments state with the updated event
		const updatedAppointments = appointments.map((appointment) => {
			if (appointment.id == event.id) {
				return {
					...appointment,
					start_date: updatedAppointment.start_date,
					end_date: updatedAppointment.end_date,
				};
			}
			return appointment;
		});
		setAppointments(updatedAppointments);
	} catch (error) {
		console.error("Error updating appointment:", error);
	}
};
