import React, { useEffect, useState } from "react";
import { Card, Input, Form, Row, Col, ConfigProvider, DatePicker, message, Alert } from "antd";
import frFR from "antd/lib/locale/fr_FR";
import moment from "moment";
import { axiosClient } from "../../../api/axios";
import { StopOutlined } from "@ant-design/icons";
import SaveButton from "../../../constants/SaveButton";

const UpdateSupBlock = ({ initialValues, onFormSubmit }) => {
	const [loading, setLoading] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [formKey, setFormKey] = useState(0);
	const [selectedStartDate, setSelectedStartDate] = useState(null);
	const [selectedEndDate, setSelectedEndDate] = useState(null);
	const [titre, setTitre] = useState('');
	const [postal, setPostal] = useState('');
	const [note, setNote] = useState('');

	useEffect(() => {
    if (initialValues) {
        setSelectedStartDate(initialValues.start_date ? moment(initialValues.start_date) : null);
        setSelectedEndDate(initialValues.end_date ? moment(initialValues.end_date) : null);
        setTitre(initialValues.titre || '');
        setPostal(initialValues.postal || ''); // Ensure postal is set correctly
    }
}, [initialValues]);


	const resetForm = () => {
		setFormKey((prevKey) => prevKey + 1);
		setSelectedStartDate(null);
		setSelectedEndDate(null);
		setNote('')
		setTitre('')
		setPostal(null)
	};

	const handleBloquerRdv = async () => {
		setLoading(true);

		if (!selectedStartDate || !selectedEndDate) {
			setLoading(false);
			return;
		}

		const startDate = selectedStartDate.toDate();
		const endDate = selectedEndDate.toDate();

		if (startDate && endDate && !isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
			try {
				const startDateFormatted = moment(startDate).format('YYYY-MM-DD HH:mm:ss');
				const endDateFormatted = moment(endDate).format('YYYY-MM-DD HH:mm:ss');

				const formDataToSend = {
					reserver_plage_horaire: true,
					start_date: startDateFormatted,
					end_date: endDateFormatted,
					id_agent: initialValues.id_agent,
					id_agenda: initialValues.id_agenda,
					postal: postal,
					note: note,
					titre: titre
				};

				const response = await axiosClient.put(`rdvs/update-rdv-bloquer/${initialValues.id}`, formDataToSend);
				const newAppointment = {
					...response.data,
					id: response.data.rdv.id,
				};
				setLoading(false);
				onFormSubmit({ ...response.data, newAppointment });
				message.success("Rendez-vous bloqué avec succès !");
				resetForm();
				setShowAlert(false);

			} catch (error) {
				if (error.response && error.response.status == 409) {
					console.error("Error adding appointment:", error);
					resetForm();
					setShowAlert(true);
					setLoading(false);
					return;
				}
				setLoading(false);
				resetForm();
				setShowAlert(false);
				console.error("Error adding appointment:", error);
			}
		} else {
			resetForm();
			setShowAlert(false);
			setLoading(false);
		}
	};


	const disabledDate = (current) => {
    // Disable weekends (Saturday and Sunday)
    if (current && (current.day() == 0 || current.day() == 6)) {
        return true;
    }

    // Enable today and future dates
    return current && current < moment().startOf('day');
};


	const handleStartDateChange = (date) => {
		setSelectedStartDate(date);
	};

	const handleEndDateChange = (date) => {
		setSelectedEndDate(date);
	};

	const disabledDateTime = () => {
		return {
			disabledHours: () => {
				const hours = [];
				for (let i = 0; i < 24; i++) {
					if (i < 9 || i > 18) {
						hours.push(i);
					}
				}
				return hours;
			}
		};
	};

	return (
		<Form
			onFinish={handleBloquerRdv}
			key={formKey}
			initialValues={{
				titre: initialValues?.titre,
				postal: initialValues?.postal,
				// Other fields as needed
			}}
			
		>
			{showAlert && (
				<Alert
					message="La date sélectionnée est déjà réservée."
					type="warning"
					showIcon
					closable
					onClose={() => setShowAlert(false)}
				/>
			)}
			<Card>
				<Row gutter={[16, 16]} style={{ marginBottom: "40px" }}>
					<Col span={9}>
						<ConfigProvider locale={frFR}>
							<DatePicker
								value={selectedStartDate}
								clearIcon={null}
								format="YYYY-MM-DD HH:mm"
								showTime={{ format: "HH:mm", minuteStep: 15 }}
								// disabledDate={disabledDate}
								onChange={handleStartDateChange}
								disabledTime={disabledDateTime}
								disabledDate={disabledDate}


							/>
						</ConfigProvider>
					</Col>
					<Col span={9}>
						<ConfigProvider locale={frFR}>
							<DatePicker
								value={selectedEndDate}
								clearIcon={null}
								format="YYYY-MM-DD HH:mm"
								showTime={{ format: "HH:mm", minuteStep: 15 }}
								onChange={handleEndDateChange}
								disabledTime={disabledDateTime}
								disabledDate={disabledDate}



							/>
						</ConfigProvider>
					</Col>
					<Col span={6}>
						<SaveButton icon={<StopOutlined />}
							loading={loading}
							buttonText="Enregistrer"

						/>

					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<p> Entrez le titre:</p>
						<Form.Item name="titre">
							<Input
								placeholder="Titre"
								defaultValue={titre}
								onChange={(e) => {
									setTitre(e.target.value);
							}}

								

							/>
						</Form.Item>
					</Col>
					<Col span={24}>
						<p> Entrez le code postal :</p>
						<Form.Item name="postal">
							<Input
								placeholder="Code Postal"
								defaultValue={postal}

								onChange={(e) => {
									setPostal(e.target.value);
							}}
							/>
						</Form.Item>
					</Col>
					{/* <Col span={24}>
						<p style={{ marginTop: "10px", width: "90%" }}> Ajoutez une note :</p>
						<Form.Item name="note" >
							<Input.TextArea
								rows={4}
								placeholder="note"
								defaultValue={initialValues.note}
								value={note}
								onChange={(e) => {
									setNote(e.target.value);
							}}
							/>
						</Form.Item>

					</Col> */}
				</Row>

			</Card>
		</Form>
	);
};

export default UpdateSupBlock;
