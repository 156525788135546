// src/components/List/services/agendaService.js
import { Modal } from 'antd';
import { axiosClient } from '../../../api/axios';

export const fetchAgendas = async () => {
	try {
		const response = await axiosClient.get('agendas-with-appointments');
		return response.data;
	} catch (error) {
		throw new Error('Error fetching agendas');
	}
};

export const handleHideAgenda = async (agendaId) => {
	return new Promise((resolve, reject) => {
		Modal.confirm({
			title: 'Désactiver Agenda',
			content: 'Êtes-vous sûr de vouloir désactiver cet agenda?',
			onOk: async () => {
				try {
					await axiosClient.put(`agendas/${agendaId}/disable`);
					resolve();
				} catch (error) {
					reject(error);
				}
			},
			onCancel: () => {
				reject('Action cancelled');
			},
		});
	});
};

export const handleShowAgenda = async (agendaId) => {
	return new Promise((resolve, reject) => {
		Modal.confirm({
			title: 'Réactiver Agenda',
			content: 'Êtes-vous sûr de vouloir réactiver cet agenda?',
			onOk: async () => {
				try {
					await axiosClient.put(`agendas/${agendaId}/enable`);
					resolve();
				} catch (error) {
					reject(error);
				}
			},
			onCancel: () => {
				reject('Action cancelled');
			},
		});
	});
};
