import { message } from "antd";
import { axiosClient } from "../../../api/axios";
import moment from "moment";

export const fetchRdvData = async (selectedAgent, selectedAgenda, selectedDateRange, setTableData, setRdvLoading) => {
	setRdvLoading(true);
	console.log('Starting fetchRdvData');

	try {
		const queryParams = {};
		if (selectedAgent && selectedAgent.length > 0) {
			queryParams['all_modified_by'] = selectedAgent;
		}
		if (selectedAgenda && selectedAgenda.length > 0) {
			queryParams['id_agenda'] = selectedAgenda;
		}

		if (selectedDateRange && selectedDateRange.length == 2) {
			const startDate = selectedDateRange[0].startOf('day').format('YYYY-MM-DD');
			const endDate = selectedDateRange[1].startOf('day').format('YYYY-MM-DD');
			queryParams['start_date'] = startDate;
			queryParams['end_date'] = endDate;
		}

		const baseURL = axiosClient.defaults.baseURL;
		console.log('baseURL:', baseURL);
		const response = await axiosClient.get("rdvs/rdv-modified", {
			params: queryParams,
		});

		console.log('Response:', response);
		const { appointments } = response.data;
		console.log('Appointments:', appointments);

		const modifiedAppointments = appointments
  .filter(appointment => appointment.modifiedBy)
  .map(appointment => {
    // Filter out users with 'Superadmin' or 'Admin' roles
    const modifiedByUsers = (appointment.modifiedByUsers || []).filter(user => 
      user.role === 'Agent' || user.role === 'Superviseur'
    );

    // Only proceed if there are users after filtering
    if (modifiedByUsers.length > 0) {
      const modifiedByName = modifiedByUsers.map(user => user.nom).join(', ');
      const date = new Date(appointment.updated_at);
      
      // Format the date to YYYY-MM-DD HH:MM
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;

      console.log('Appointment ID:', appointment.id);
      console.log('Modified By Name:', modifiedByName.toUpperCase());
      console.log('Formatted Date:', formattedDate);

      return {
        id: appointment.id,
        appointment: appointment,
        status: appointment?.status,
        updateAt: formattedDate,
        date: appointment?.start_date,
        agendaContactName: appointment?.agenda?.user?.prenom.toUpperCase(),
        agentName: appointment?.user?.nom + ' ' + appointment?.user?.prenom,
        modifiedByName: modifiedByName.toUpperCase(),
      };
    }
    return null; // Return null if no valid users are found
  })
  .filter(item => item !== null); // Remove null entries


		console.log('Modified Appointments:', modifiedAppointments);
		setTableData(modifiedAppointments);
	} catch (error) {
		console.error("Error fetching appointments:", error.response);
	} finally {
		setRdvLoading(false);
		console.log('fetchRdvData completed');
	}
};


export const fetchRdv = async (selectedAgent, selectedAgenda, selectedDateRange, setTableData, setRdvLoading) => {
	setRdvLoading(true);
	try {
		const queryParams = {};
		if (selectedAgent && selectedAgent.length > 0) {
			selectedAgent.forEach(agentId => {
				queryParams['agent_id[]'] = queryParams['agent_id[]'] || [];
				queryParams['agent_id[]'].push(agentId);
			});
		}
		if (selectedAgenda && selectedAgenda.length > 0) {
			selectedAgenda.forEach(agendaId => {
				queryParams['agenda_id[]'] = queryParams['agenda_id[]'] || [];
				queryParams['agenda_id[]'].push(agendaId);
			});
		}
		if (selectedDateRange && selectedDateRange.length == 1) {
			const startDate = selectedDateRange[0].startOf('day').toISOString();
			queryParams.start_date = startDate;
		}
		if (selectedDateRange && selectedDateRange.length == 2) {
			const startDate = selectedDateRange[0].startOf('day').toISOString();
			const endDate = selectedDateRange[1].endOf('day').toISOString();
			queryParams.start_date = startDate;
			queryParams.end_date = endDate;
		}

		const response = await axiosClient.get("rdvs", {
			params: queryParams,
		});

		const appointments = response.data;
		console.log('appointments', appointments);

		// Debugging: Log field values of the first few appointments
		appointments.slice(0, 5).forEach(appointment => {
			console.log(`bloquer: ${appointment.bloquer}, reserver_plage_horaire: ${appointment.reserver_plage_horaire}, rdv_prive: ${appointment.rdv_prive}`);
		});

		// Ensure field values are treated as numbers for comparison
		const filteredAppointments = appointments.filter(appointment => {
			const bloquer = Number(appointment.bloquer);
			const reserverPlageHoraire = Number(appointment.reserver_plage_horaire);
			const rdvPrive = Number(appointment.rdv_prive);
			const deletedAt = appointment.deleted_at;

			return bloquer == 0 && reserverPlageHoraire == 0 && rdvPrive == 0 &&
				!deletedAt;
		});

		console.log('filteredAppointments', filteredAppointments);


		// Extract unique agenda names (this might be redundant if only filtered results are needed)
		const uniqueAgendaNames = new Set();
		filteredAppointments.forEach(appointment => {
			if (appointment.agenda && appointment.agenda.name) {
				uniqueAgendaNames.add(appointment.agenda.name);
			}
		});

		const appointmentsWithAgentsAndCommercials = filteredAppointments.map(appointment => {
			return {
				...appointment,
				modifiedBy: appointment.modifiedBy,
				agent: {
					nom: appointment.user.nom,
					prenom: appointment.user.prenom,
				},
				agentCommercial: appointment.agenda ? `${appointment?.agenda?.user?.nom} ${appointment?.agenda?.user?.prenom}` : "Agent commercial non trouvé",
			};
		});

		const totalAppointmentsByAgentAndAgenda = [];
		appointmentsWithAgentsAndCommercials.forEach(appointment => {
			const { agent, agentCommercial } = appointment;
			const existingAgentIndex = totalAppointmentsByAgentAndAgenda.findIndex(item => item.agentCommercial === agentCommercial && item.agent.nom === agent.nom && item.agent.prenom === agent.prenom);
			if (existingAgentIndex == -1) {
				totalAppointmentsByAgentAndAgenda.push({
					agent: { nom: agent.nom, prenom: agent.prenom },
					agentCommercial,
					totalAppointments: 1,
				});
			} else {
				totalAppointmentsByAgentAndAgenda[existingAgentIndex].totalAppointments++;
			}
		});

		setTableData({
			appointmentsWithAgentsAndCommercials,
			totalAppointmentsByAgentAndAgenda,
		});
	} catch (error) {
		console.error("Error fetching appointments:", error);
	} finally {
		setRdvLoading(false);
	}
};


export const fetchAgentOptions = async (setAgentOptions) => {
	try {
		const response = await axiosClient.get("superviseur-and-agent-users");
		setAgentOptions(response.data.users);
	} catch (error) {
		console.error("Error fetching agent options:", error);
	}
};

export const fetchAgendaOptions = async (setAgendaOptions) => {
	try {
		const response = await axiosClient.get("agendas");
		const agendas = response.data.agendas;

		// Filter out agendas where contact is undefined and map through the valid agendas
		const agendaOptionsWithContactNames = agendas
			.filter(agenda => agenda.user)  // Ensure contact is defined
			.map(agenda => {
				const contact = agenda.user;
				return {
					...agenda,
					contact_nom: contact.nom.toUpperCase(),
					contact_prenom: contact.prenom.toUpperCase(),
				};
			});

		// Set the agenda options with contact names
		setAgendaOptions(agendaOptionsWithContactNames);
	} catch (error) {
		console.error("Error fetching agenda options:", error);
	}
};

export const handleDownloadClick = (tableData) => {
	const customHeaders = [
		"Status",
		"Client",
		"Agent",
		"Agent Commercial",
		"Type",
		"Nom Ste",
		"TVA",
		"Adresse",
		"Code Postal",
		"TEL",
		"GSM",
		"Nombre de Compteurs Électriques",
		"Nombre de Compteurs Gaz",
		"Commentaire",
		"Fournisseur",
		"PPV",
		"Tarif",
		"Haute Tension",
		"Tarification",
		"Note"
	];

	// Map data fields to custom headers
	const dataFieldMapping = {
		"Status": "status",
		"Client": row => `"${row.nom} ${row.prenom}"`,
		"Agent": row => `${row.agent.nom} ${row.agent.prenom}`,
		"Agent Commercial": "agentCommercial",
		"Professionnel/Residentiel": row => row.pro == 0 || row.pro == false ? "Residentiel" : "Professionnel",
		"Nom Ste": "nom_ste",
		"TVA": row => row.tva ? `BE0${row.tva}` : "",
		"Adresse": "adresse",
		"Code Postal": "postal",
		"TEL": row => row.tel ? `"+32${row.tel}"` : "",
		"GSM": row => row.gsm ? `"+324${row.gsm}"` : "",
		"Nombre de Compteurs Électriques": "nbr_comp_elect",
		"Nombre de Compteurs Gaz": "nbr_comp_gaz",
		"Commentaire": "commentaire",
		"Fournisseur": "fournisseur",
		"PPV": row => row.ppv == 0 || row.ppv == false ? "non" : "oui",
		"Tarif": row => row.tarif == 0 || row.tarif == false ? "non" : "oui",
		"Haute Tension": row => row.haute_tension == 0 || row.haute_tension == false ? "non" : "oui",
		"Tarification": "tarification",
		"Note": "note"
	};

	// Construct header row with custom headers
	const headerRow = customHeaders?.map(header => `"${header}"`).join(",");

	// Construct data rows
	const dataRows = tableData.modifiedAppointments?.map(row => {
		return customHeaders?.map(header => {
			const field = dataFieldMapping[header];
			if (typeof field === "function") {
				return `"${field(row)}"`;
			} else {
				return `"${row[field] || ""}"`;
			}
		}).join(",");
	});

	// Construct CSV content
	const csvContent = "data:text/csv;charset=utf-8," + headerRow + "\n" + dataRows?.join("\n");
	// Create a link element
	const encodedUri = encodeURI(csvContent);
	const link = document.createElement("a");
	link.setAttribute("href", encodedUri);
	link.setAttribute("download", "appointments.csv");
	document.body.appendChild(link);
	link.click();
	const formData = new FormData();
	formData.append('csvContent', csvContent);
	axiosClient.post('save-csv', formData)
		.then(response => {
		})
		.catch(error => {
			console.error('Error:', error.response);
		});
};
export const handleDownloadClick2 = (tableData) => {
	const customHeaders = [
		"Status",
		"Client",
		"Agent",
		"Agent Commercial",
		"Date",
		"Type",
		"Nom Ste",
		"TVA",
		"Adresse",
		"Code Postal",
		"TEL",
		"GSM",
		"Nombre de Compteurs Électriques",
		"Nombre de Compteurs Gaz",
		"Commentaire",
		"Fournisseur",
		"PPV",
		"Tarif Social",
		"Haute Tension",
		"Tarification",
		"Note",
		"modifié à",
		"modifié par"
	];

	// Map data fields to custom headers
	const dataFieldMapping = {
		"Status": "status",
		"Client": row => `"${row.appointment.nom} ${row.appointment.prenom}"`,
		"Agent": row => `${row.agentName}`,
		"Agent Commercial": row => `${row.agendaContactName}`,
		"Date": row => `${row.date}`,
		"Type": row => row.appointment.pro == 0 || row.appointment.pro == false ? "Residentiel" : "Professionnel",
		"Nom Ste": row => row.appointment.nom_ste || "N/A",
		"TVA": row => row.appointment.tva ? `"BE0${row.appointment.tva}"` : "",
		"Adresse": row => row.appointment.adresse,
		"Code Postal": row => row.appointment.postal,
		"TEL": row => row.appointment.tel,
		"GSM": row => row.appointment.gsm ? `"${row.appointment.gsm}"` : "N/A",
		"Nombre de Compteurs Électriques": row => row.appointment.nbr_comp_elect,
		"Nombre de Compteurs Gaz": row => row.appointment.nbr_comp_gaz,
		"Commentaire": row => row.appointment.commentaire || "N/A",
		"Fournisseur": row => row.appointment.fournisseur,
		"PPV": row => row.appointment.ppv == 0 || row.appointment.ppv == false ? "non" : "oui",
		"Tarif Social": row => row.appointment.tarif == 0 || row.appointment.tarif == false ? "non" : "oui",
		"Haute Tension": row => row.appointment.haute_tension == 0 || row.appointment.haute_tension == false ? "non" : "oui",
		"Tarification": row => row.appointment.tarification,
		"Note": row => row.appointment.note || "N/A",
		"modifie a": row => `${row.updateAt}`,
		"modifie par": row => `"${row.modifiedByName || "N/A"}"`
	};

	// Construct header row with custom headers
	const headerRow = customHeaders?.map(header => `"${header}"`).join(",");

	// Construct data rows
	const dataRows = tableData.map(row => {
		return customHeaders?.map(header => {
			const field = dataFieldMapping[header];
			if (typeof field === "function") {
				return `"${field(row)}"`;
			} else {
				return `"${row[field] || ""}"`;
			}
		}).join(",");
	});

	// Construct CSV content
	const csvContent = "data:text/csv;charset=utf-8," + headerRow + "\n" + dataRows.join("\n");
	// Create a link element
	const encodedUri = encodeURI(csvContent);
	const link = document.createElement("a");
	link.setAttribute("href", encodedUri);
	link.setAttribute("download", "appointments.csv");
	document.body.appendChild(link);

	link.click();
};

// Duplicate rdv api

export const fetchAgents = async (setAgents, initialValues, setFormattedAgentInfo) => {
	try {
		const response = await axiosClient.get("agendas");
		const allAgendas = response.data.agendas;
		setAgents(response.data.agendas);

		const matchingAgenda = allAgendas.find(agenda => agenda.contact_id == initialValues.agenda.contact_id);
		if (matchingAgenda) {
			const { nom, prenom, email } = matchingAgenda.user;
			const formattedInfo = `${nom} ${prenom} (${email})`;
			setFormattedAgentInfo(formattedInfo);
		}

	} catch (error) {
		console.error("Error fetching agents:", error);
	}
};
export const fetchAgents2 = async (setAgents, initialValues, setFormattedAgentInfo) => {
	try {
		const response = await axiosClient.get("agendas");
		const allAgendas = response.data.agendas;

		// Exclude the current agenda from the list
		const filteredAgendas = allAgendas.filter(agenda => agenda.contact_id !== initialValues.agenda.contact_id);

		setAgents(filteredAgendas);

		// Set formatted agent info for the current agenda
		const matchingAgenda = allAgendas.find(agenda => agenda.contact_id == initialValues.agenda.contact_id);
		if (matchingAgenda) {
			const { nom, prenom, email } = matchingAgenda.user;
			const formattedInfo = `${nom} ${prenom} (${email})`;
			setFormattedAgentInfo(formattedInfo);
		}

	} catch (error) {
		console.error("Error fetching agents:", error);
	}
};



export const checkAppointmentOverlap = async (selectedAgentAgendaId, selectedStartDate, selectedEndDate, initialValues) => {
	try {
		const response = await axiosClient.get(`rdvs/getAppointmentsByAgenda/${selectedAgentAgendaId}`);
		const appointments = response.data;
		console.log('appointments', appointments)
		console.log('selectedAgentAgendaId', selectedAgentAgendaId)

		const [startDate, endDate] = selectedStartDate && selectedEndDate ? [selectedStartDate, selectedEndDate] : [moment(initialValues.start_date), moment(initialValues.end_date)];

		// const isOverlap = appointments.some(appointment => {
		// 	const appStartDate = new Date(appointment.start_date);
		// 	const appEndDate = new Date(appointment.end_date);

		// 	return (
		// 		(startDate >= appStartDate && startDate <= appEndDate) ||
		// 		(endDate >= appStartDate && endDate <= appEndDate) ||
		// 		(startDate <= appStartDate && endDate >= appEndDate)
		// 	);
		// });

		const conflictingAppointments = appointments.filter(appointment => {
			const appStartDate = new Date(appointment.start_date);
			const appEndDate = new Date(appointment.end_date);

			return (
				(startDate >= appStartDate && startDate <= appEndDate) ||
				(endDate >= appStartDate && endDate <= appEndDate) ||
				(startDate <= appStartDate && endDate >= appEndDate)
			);
		});
		const isOverlap = conflictingAppointments.length > 0;
		console.log('isOverlap', isOverlap)
		console.log('conflictingAppointments', conflictingAppointments)
		console.log('conflictingAppointments', conflictingAppointments)
		console.log('isOverlap', isOverlap)


		return isOverlap;
	} catch (error) {
		console.error("Error checking appointment conflicts:", error);
		throw error;
	}
};



export const updateAppointment = async (rdvId, formDataToSend, rdvList, selectedAgentAgendaId, userId, onFormSubmit, setShowDuplicateModal) => {
	try {
		const response = await axiosClient.put(`rdvs/${rdvId}`, formDataToSend);

		const newAppointment = {
			...response.data,
			id: response.data.rdv.id,
			bloquer: response.data.rdv.bloquer,
		};

		try {
			await axiosClient.delete(`rdvs/${rdvList.id_agenda}/${rdvList.id}`);
		} catch (deleteError) {
			console.error("Error deleting appointment from the first agenda:", deleteError.response);
		}

		message.success("Rendez-vous modifié avec succès !");
		onFormSubmit({ ...response.data, newAppointment });
		setShowDuplicateModal(false);
	} catch (error) {
		if (error.response && error.response.status === 409) {
			console.error("Error adding appointment:", error);
			throw error;
		}
		console.error("Error updating appointment:", error.response);
		throw error;
	}
};

export const handleFormDuplicate = async (formData, rdvList, selectedAgentAgendaId, userId, onFormSubmit, setShowDuplicateModal, isRunning, setLoadingEnregistrer, setIsRunning, setShowAlert) => {
	if (isRunning) return null;
	setLoadingEnregistrer(true)
	setIsRunning(true);

	let startDate, endDate;

	if (formData && formData.appointment_date && formData.appointment_date.length == 2) {
		startDate = new Date(formData.appointment_date[0]);
		endDate = new Date(formData.appointment_date[1]);
	} else {
		startDate = new Date(rdvList.start_date);
		endDate = new Date(rdvList.end_date);
	}

	const startDateFormatted = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000);
	const endDateFormatted = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);

	const formDataToSend = {
		...formData,
		bloquer: false,
		start_date: startDateFormatted.toISOString().slice(0, 19).replace("T", " "),
		end_date: endDateFormatted.toISOString().slice(0, 19).replace("T", " "),
		id_agent: rdvList.id_agent,
		id_agenda: selectedAgentAgendaId,
		ppv: rdvList.ppv,
		tarif: rdvList.tarif,
		tarification: rdvList.tarification,
		haute_tension: rdvList.haute_tension,
		note: formData.note,
		commentaire: formData.commentaire,
		modifiedBy: userId,
	};
	console.log('formDataToSend', formDataToSend)

	try {
		await updateAppointment(rdvList.id, formDataToSend, rdvList, selectedAgentAgendaId, userId, onFormSubmit, setShowDuplicateModal);
	} catch (error) {
		if (error.response && error.response.status === 409) {
			console.error("Error adding appointment:", error);
			setShowAlert(true);
			setLoadingEnregistrer(false);
			setIsRunning(false);
		}
		console.error("Error updating appointment:", error.response);
		setIsRunning(false);
		setLoadingEnregistrer(false);
	}
};

// Update rdv
export const handleFormSubmit = async (formData, rdvList, userId, onFormSubmit, setShowUpdateModal, message, setLoadingEnregistrer, setShowAlert) => {
	setLoadingEnregistrer(true);
	const { appointment_date } = formData;
	const [startDate, endDate] = appointment_date;

	if (!startDate || !endDate) {
		message.warning("Veuillez sélectionner une date de début et une date de fin.");
		setLoadingEnregistrer(false);
		return;
	}

	const startDateFormatted = moment(startDate).startOf("minute");
	const endDateFormatted = moment(endDate).startOf("minute");
	if (!startDateFormatted.isSame(endDateFormatted, 'day')) {
		message.warning("La date de début et la date de fin doivent être le même jour.");
		setLoadingEnregistrer(false);
		return;
	}

	const hoursDifference = Math.abs(endDateFormatted - startDateFormatted) / 36e5;
	if (hoursDifference < 1) {
		message.warning("Le rendez-vous doit durer au moins une heure.");
		setLoadingEnregistrer(false);
		return;
	}

	const [nom, prenom] = formData.nom_prenom ? formData.nom_prenom.split(' ') : ['', ''];
	const formDataToSend = {
		...formData,
		nom: nom,
		prenom: prenom,
		bloquer: false,
		start_date: startDateFormatted.format("YYYY-MM-DD HH:mm:ss"),
		end_date: endDateFormatted.format("YYYY-MM-DD HH:mm:ss"),
		id_agent: rdvList.id_agent,
		id_agenda: rdvList.id_agenda,
		ppv: formData.ppv,
		tarif: formData.tarif,
		haute_tension: formData.haute_tension,
		tarification: formData.tarification ? "Variable" : "Fixe",
		note: formData.note,
		commentaire: formData.commentaire,
		modifiedBy: userId,
	};
	console.log('formdata to send ', formDataToSend)
	try {
		const response = await axiosClient.put(`rdvs/${rdvList.id}`, formDataToSend);
		const newAppointment = {
			...response.data,
			id: response.data.rdv.id,
			bloquer: response.data.rdv.bloquer,
		};
		setLoadingEnregistrer(false);
		onFormSubmit({ ...response.data, newAppointment });
		setShowUpdateModal(false);
		console.log('response', response.data)
		message.success("Rendez-vous modifié avec succès !");
	} catch (error) {
		if (error.response && error.response.status === 409) {
			setLoadingEnregistrer(false);
			setShowAlert(true);
		}
		setLoadingEnregistrer(false);
		console.error("Error updating appointment:", error);
	}
};

export const handleDelete = async (rdvList, setShowUpdateModal, setLoadingDelete) => {
	try {
		setLoadingDelete(true);
		const response = await axiosClient.delete(`rdvs/${rdvList.id}`);
		console.log('Rdv deleted success ', response);
		setShowUpdateModal(false);
	} catch (error) {
		setLoadingDelete(false);
		setShowUpdateModal(false);
		console.error('Failed to delete Rdv:', error);
	}
};

// Reporter rdv

export const handleClick = async (isRunning, setIsRunning, setShowAlert, formData, rdvList, setLoadingEnregistrer, userId,
	onFormSubmit,
	setReporterModalVisible,) => {
	if (isRunning) return;

	try {
		setIsRunning(true);

		// Fetch all appointments from the API
		const response = await axiosClient.get(`rdvs/getAppointmentsByAgenda/${rdvList.id_agenda}`);
		const appointments = response.data;
		console.log('selectedDate.agendaId', rdvList.agendaId)
		console.log('response', response.data)
		// Check for overlapping appointments
		const [startDate, endDate] = formData.appointment_date;
		
	
		const conflictingAppointments = appointments.filter(appointment => {
			const appStartDate = new Date(appointment.start_date);
			const appEndDate = new Date(appointment.end_date);
			

			return (
        (startDate > appStartDate && startDate < appEndDate) || // Start date overlaps with existing appointment
        (endDate > appStartDate && endDate < appEndDate) ||     // End date overlaps with existing appointment
        (startDate <= appStartDate && endDate >= appEndDate)    // New appointment completely covers existing appointment
      );
		});
		console.log('formData', formData)
		console.log('conflictingAppointments', conflictingAppointments)
		const isOverlap = conflictingAppointments.length > 0;

		console.log('isOverlap', isOverlap)

		if (isOverlap) {
			setShowAlert(true);
			setIsRunning(false);
		} else {
			handleFormReporter(isRunning,
				setLoadingEnregistrer,
				setIsRunning,
				formData,
				rdvList,
				userId,
				onFormSubmit,
				setReporterModalVisible,
				setShowAlert);
		}
	} catch (error) {
		setIsRunning(false);
		console.error("Error checking appointment conflicts:", error);
	}
};

export const handleFormReporter = async (
	isRunning,
	setLoadingEnregistrer,
	setIsRunning,
	formData,
	rdvList,
	userId,
	onFormSubmit,
	setReporterModalVisible,
	setShowAlert
) => {
	if (isRunning) return;
	setLoadingEnregistrer(true);
	setIsRunning(true);

	let startDate, endDate;

	if (formData && formData.appointment_date && formData.appointment_date.length == 2) {
		// If new dates are selected, use them
		startDate = new Date(formData.appointment_date[0]);
		endDate = new Date(formData.appointment_date[1]);
	} else {
		// Otherwise, use initial values
		startDate = new Date(rdvList.start_date);
		endDate = new Date(rdvList.end_date);
	}

	const startDateFormatted = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000);
	const endDateFormatted = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);

	const formDataToSend = {
		...formData,
		bloquer: false,
		start_date: startDateFormatted.toISOString().slice(0, 19).replace("T", " "),
		end_date: endDateFormatted.toISOString().slice(0, 19).replace("T", " "),
		id_agent: rdvList.id_agent,
		id_agenda: rdvList.id_agenda,
		note: formData.note,
		commentaire: formData.commentaire,
		modifiedBy: userId,
		ppv: rdvList.ppv,
		tarif: rdvList.tarif,
		tarification: rdvList.tarification,
		haute_tension: rdvList.haute_tension,
	};
	console.log('formDataToSend', formDataToSend)

	try {

		const response = await axiosClient.put(
			`rdvs/${rdvList.id}`,
			formDataToSend
		);
		const newAppointment = {
			...response.data,
			id: response.data.rdv.id,
			bloquer: response.data.rdv.bloquer,
		};

		setLoadingEnregistrer(false);
		setIsRunning(false);
		onFormSubmit({ ...response.data, newAppointment });
		setReporterModalVisible(false);
		message.success("Rendez-vous modifié avec succès !");
	} catch (error) {
		if (error.response && error.response.status === 409) {
			console.error("Error adding appointment:", error);
			setShowAlert(true);
			setLoadingEnregistrer(false);
			return;
		}
		setIsRunning(false);
		setLoadingEnregistrer(false);
		console.error("Error updating appointment:", error);
	}
};

// Rdv table view






