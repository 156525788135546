
import React, { useState, useEffect } from "react";
import {
    Form,
    DatePicker,
    Row,
    Col,
    Card,
    ConfigProvider,
    Select,
    Alert,
} from "antd";
import frFR from "antd/lib/locale/fr_FR";
import SaveButton from "../../../constants/SaveButton";
import moment from "moment";
import { fetchAgents2, checkAppointmentOverlap, handleFormDuplicate } from "../services/api";
import { useAuth } from "../../../AuthContext";

const { Option } = Select;

const DuplicateRdv = ({ initialValues, onFormSubmit, setShowDuplicateModal }) => {
    const [form] = Form.useForm();
    const [loadingEnregistrer, setLoadingEnregistrer] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [isRunning, setIsRunning] = useState(false);
    const [agents, setAgents] = useState([]);
    const [selectedAgentAgendaId, setSelectedAgentAgendaId] = useState(null);
    const [formattedAgentInfo, setFormattedAgentInfo] = useState(null);
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const { userId } = useAuth();

    useEffect(() => {
			fetchAgents2(setAgents, initialValues, setFormattedAgentInfo);
    }, []);

    const handleAgentSelect = (agentId) => {
        const selectedAgent = agents.find(agent => agent.user.id == agentId);

        if (selectedAgent) {
            setSelectedAgentAgendaId(selectedAgent.id);
        }
    };

    const [formData, setFormData] = useState({
        title: "",
        nom: "",
        prenom: "",
        nom_ste: "",
        postal: "",
        adresse: "",
        tva: "",
        tel: "",
        gsm: "",
        fournisseur: "",
        nbr_comp_elect: "",
        nbr_comp_gaz: "",
        ppv: false,
        tarif: false,
        pro: false,
        haute_tension: false,
        tarification: "",
        commentaire: "",
        note: "",
        bloquer: false,
        appointment_date: null,
    });

    const rdvList = initialValues.rdv || initialValues;

    useEffect(() => {
        if (rdvList && Object.keys(rdvList).length > 0) {
            const formattedInitialValues = {
                ...rdvList,
                nom_prenom: `${rdvList.nom} ${rdvList.prenom}`,
                startTime: moment(rdvList.start_date),
                endTime: moment(rdvList.end_date),
                ppv: Boolean(rdvList.ppv),
                bloquer: Boolean(rdvList.bloquer),
                pro: Boolean(rdvList.pro),
                tarif: Boolean(rdvList.tarif),
                haute_tension: Boolean(rdvList.haute_tension),
                tarification: rdvList.tarification === "Variable" ? true : false,
                commentaire: rdvList.commentaire,
                note: rdvList.note,
            };
            form.setFieldsValue(formattedInitialValues);
            setFormData(formattedInitialValues);
        }
    }, [rdvList, form]);

    const handleClick = async () => {
        if (isRunning) return;

        try {
            setIsRunning(true);

            const isOverlap = await checkAppointmentOverlap(selectedAgentAgendaId, selectedStartDate, selectedEndDate, initialValues);

            if (isOverlap) {
                setShowAlert(true);
                setIsRunning(false);
            } else {
                await handleFormDuplicate(formData, rdvList, selectedAgentAgendaId, userId, onFormSubmit, setShowDuplicateModal, isRunning, setLoadingEnregistrer,setIsRunning,setShowAlert);
                setLoadingEnregistrer(false);
                setIsRunning(false);
            }
        } catch (error) {
            if (error.response && error.response.status === 409) {
                setShowAlert(true);
                setLoadingEnregistrer(false);
            }
            console.error("Error handling form duplicate:", error);
            setIsRunning(false);
            setLoadingEnregistrer(false);
        }
    };

    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
        const newEndDate = moment(date).add(1, 'hour');
        setSelectedEndDate(newEndDate);
        setFormData({
            ...formData,
            appointment_date: [
                date.toDate(),
                newEndDate.toDate()
            ]
        });
    };

		const disabledTime = () => ({
			disabledHours: () => {
					const hours = [];
					for (let i = 0; i < 9; i++) hours.push(i);
					for (let i = 19; i < 24; i++) hours.push(i);
					return hours;
			},
			disabledMinutes: () => {
					return [];
			},
			disabledSeconds: () => {
					return [];
			}
	});

    return (
        <Form layout="vertical" form={form} onFinish={handleClick}>
            {showAlert && (
                <Alert
                    message="La date sélectionnée est déjà réservée."
                    type="warning"
                    showIcon
                    closable
                    onClose={() => setShowAlert(false)}
                />
            )}
            <Card style={{ marginBottom: "10px" }}>
                <Row justify="end" gutter={[16, 16]} style={{ marginBottom: "10px" }}>
                    <Col>
                        <SaveButton
                            onClick={handleClick}
                            loading={loadingEnregistrer}
                            buttonText="Enregistrer"
                        />
                    </Col>
                </Row>
            </Card>
            <Card style={{ marginBottom: "10px" }}>
                <Row gutter={[30, 16]}>
                    <Col span={24}>
                        <Form.Item label="Choisir une agenda :">
                            <Select onSelect={handleAgentSelect} style={{ width: "100%" }} placeholder={formattedAgentInfo ? formattedAgentInfo : 'Select Agent'} allowClear>
                                {agents.map(agent => (
                                    <Option key={agent?.user?.id} value={agent?.user?.id}>
                                        {`${agent?.user?.nom} ${agent?.user?.prenom}`}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Choisir une date :">
                            <ConfigProvider locale={frFR}>
                                <DatePicker
                                    defaultValue={moment(rdvList?.start_date, "YYYY-MM-DD HH:mm:ss")}
                                    onChange={handleStartDateChange}
                                    clearIcon={null}
                                    format="YYYY-MM-DD HH:mm"
                                    showTime={{ format: "HH:mm", minuteStep: 15 }}
                                    disabledDate={(current) => {
                                        if (current && (current.day() == 0 || current.day() == 6)) {
                                            return true;
                                        }
                                        return current && current < moment().startOf("day");
                                    }}
																		disabledTime={disabledTime}
                                    renderExtraFooter={(mode) => {
                                        if (mode === 'end') return null;
                                        return null;
                                    }}
                                />
                            </ConfigProvider>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Fin du rendez-vous :">
                            <ConfigProvider locale={frFR}>
                                <DatePicker
                                    value={selectedEndDate ? moment(selectedEndDate, "YYYY-MM-DD HH:mm:ss") : null}
                                    clearIcon={null}
                                    format="YYYY-MM-DD HH:mm"
                                    showTime={{ format: "HH:mm", minuteStep: 15 }}
                                    disabledDate={(current) => {
                                        if (current && (current.day() == 0 || current.day() == 6)) {
                                            return true;
                                        }
                                        return current && current < moment().startOf("day");
                                    }}
																		disabledTime={disabledTime}
                                    renderExtraFooter={(mode) => {
                                        if (mode === 'end') return null;
                                        return null;
                                    }}
                                    disabled={true}
                                />
                            </ConfigProvider>
                        </Form.Item>
                    </Col>
                </Row>
            </Card>
        </Form>
    );
};

export default DuplicateRdv;
