import React, { useState } from "react";
import { Layout, Button, Row, Col, Form, Input, Switch, Alert, Card } from "antd";
import sunlogo from "../../assets/images/LOGO_sunlightcall.png";
import login from "../../assets/images/login/img login.jpg";
import logo from "../../assets/images/login/LOGO MGA.png";
import by from "../../assets/images/login/by.png";
import { useAuth } from "../../AuthContext";
import { Link, useHistory } from "react-router-dom";
import { handleForgotPasswordSubmit, handleLogin } from "./services/api";
import "./login.css";

const { Content } = Layout;

const Login = () => {
	const [remember, setRemember] = useState(true);
	const [loading, setLoading] = useState(false);
	const [alertVisible, setAlertVisible] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");
	const { handleLoginSuccess, setUserData } = useAuth();
	const history = useHistory();
	const [form] = Form.useForm();

	const onFinishFailed = (errorInfo) => {
		setAlertVisible(true);
	};

	const handleRememberChange = (checked) => {
		setRemember(checked);
		if (!checked) {
			localStorage.removeItem("auth_token");
			localStorage.removeItem("auth_name");
			localStorage.removeItem("user_role");
		}
	};

	const onFinish = (values) => {

		handleLogin(
			values,
			setLoading,
			handleLoginSuccess,
			history,
			setAlertMessage,
			setAlertVisible
		);
	};

	return (
		<div >
			<Row>
				<Col span={12} xs={{ span: 24 }} lg={{ span: 12 }} className=" login-card">
					<Content >
						<Row justify="center" className="logo-row">
							<Col lg={8} sm={24}>
								<Link to="/login">
									<img
										src={sunlogo}
										alt="Logo 3"
										className="logo-img"
										style={{ width: 170 }}
									/>
								</Link>
							</Col>
							<Col lg={4} sm={24}>
								<img src={by} alt="Logo 2" className="logo-img" />
							</Col>
							<Col lg={8} sm={24}>
								<Link to="/">
									<img
										src={logo}
										alt="Logo 3"
										className="logo-img"
										style={{ width: 170 }}
									/>
								</Link>
							</Col>
						</Row>
						<Row justify="center">
							<Col xs={{ span: 24 }} lg={{ span: 20 }}>
								{alertVisible && (
									<Alert
										message="Erreur de connexion"
										description={alertMessage}
										type="error"
										showIcon
										closable
										onClose={() => setAlertVisible(false)}
									/>
								)}
							</Col>
						</Row>
						<br />
						<br />
						<br />
						<Row justify="center" className="form-column">
							<Col xs={{ span: 24 }} lg={{ span: 18 }}>
								<Card>
									<Form
										form={form}
										onFinish={onFinish}
										onFinishFailed={onFinishFailed}
										layout="vertical"
										className="row-col background-img"
									>
										<Form.Item
											className="username"
											label="Email"
											name="email"
											rules={[
												{
													required: true,
													message: "Veuillez saisir votre adresse e-mail!",
												},
											]}
										>
											<Input placeholder="Email" />
										</Form.Item>

										<Form.Item
											className="username"
											label="Password"
											name="password"
											autocomplete="on"
											rules={[
												{
													required: true,
													message: "Veuillez saisir votre mot de passe!",
												},
											]}
										>
											<Input.Password placeholder="Password" />
										</Form.Item>

										<Row justify="space-between">
											<Col >
												<Form.Item name="remember" className="align-center" valuePropName="checked">
													<Switch defaultChecked={false} onChange={handleRememberChange} />
													Souviens moi
												</Form.Item>

											</Col>
											<Col >
												<Button
													onClick={() => handleForgotPasswordSubmit(form, setUserData)}
													type="link"
													style={{ color: "#61559F" }}
												>
													Mot de passe oublié ?
												</Button>
											</Col>
										</Row>


										<Form.Item>
											<Button
												style={{
													backgroundColor: "#61559F",
													width: "100%",
													borderRadius: "5px",
													color: "white",
													fontWeight: "bold",
												}}
												htmlType="submit"
												loading={loading}
											>
												Se connecter
											</Button>
										</Form.Item>

									</Form>
								</Card>
							</Col>
						</Row>
					</Content>
				</Col>
				<Col span={12} xs={{ span: 24 }} lg={{ span: 12 }} className="image-container">
					<img src={login} alt="" className="login-image" />
				</Col>
			</Row>
		</div>
	);
};

export default Login;
