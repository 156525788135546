import React, { useEffect, useState, useCallback } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Modal, Card, Spin } from "antd";
import { axiosClient } from "../../../api/axios";
import { useCalendar } from "../../../CalendarContext";
import { handleAppointmentClickAC } from "../services/api";
import AddPrivateAppointmentModal from "../AddPrivateRdvModal/AddPrivateRdvModal";
import { useUser } from "../../../GlobalContext";
import AppointmentDetailsCom from "../ACDetails/AppoitmentDet";
import AddRdvPrvCalednar from "../AddRdvPrvCalendar/AddRdvPrvCalendar";
import UpdatePrivateAppointmentModal from "../UpdatePriveRdv/UpdatePrivePrv";
import StatuerRdv from "../statuerRDV/StatuerRdv";
import moment from 'moment';
import { useAuth } from "../../../AuthContext";
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

window.Pusher = Pusher;

window.Echo = new Echo({
	broadcaster: 'pusher',
	key: 'df87a0d076cd20d84204',
	cluster: 'eu',
	encrypted: true,
});
function AgentCommercialCalendar() {
	const {
		setShowAddModal,
		agentId,
		setAgentId,
		agendaId,
		setSelectedAppointment,
		selectedRowData,
		setSelectedRowData,
		setAppointmentDetails,
		selectedDate,
		setSelectedDate,
		userAgenda,
		setUserAgenda,
		userAppointments,
		setUserAppointments,
		appointments,
		setAppointments
	} = useCalendar();

	const [addPrivateModalVisible, setAddPrivateModalVisible] = useState(false);
	const [showDetailsModal, setShowDetailModal] = useState(false);
	const [showRdvPrvCalednar, setShowRdvPrvCalednar] = useState(false);
	const [showUpdate, setShowUpdate] = useState(false);
	const [loading, setLoading] = useState(true);
	const [loading2, setLoading2] = useState(true);
	const userContext = useUser();
	const { userId } = useAuth()
	const [selectedAppointmentDate, setSelectedAppointmentDate] = useState(null);
	const [showStatuerModal, setShowStatuerModal] = useState(null);
	const [refreshNeeded, setRefreshNeeded] = useState(false);


	useEffect(() => {
		const fetchAgendas = async () => {
			try {
				console.log('fetching agent commercial agenda')

				const response = await axiosClient.get("agendas");
				console.log('response', response.data)
				const agendas = response.data.agendas;
				console.log('agendas', response.data.agendas)

				// Find the agenda that matches user_id
				const matchingAgenda = agendas.find((agenda) => {
					console.log('Checking agenda with contact_id:', typeof agenda.contact_id);
					console.log('Comparing with userId:', typeof userId);
					return agenda.contact_id == userId;
				});
				console.log('matchingAgenda', matchingAgenda)


				if (matchingAgenda) {
					console.log('matchingAgenda found')
					setUserAgenda(matchingAgenda);
				} else {
					console.log('matchingAgenda not found')

				}
			} catch (error) {
				console.error("Error fetching agendas:", error);
			} finally {
				setLoading(false);
			}
		};

		if (userId) {
			console.log('user id present starting fetching its agenda')
			fetchAgendas();
		} else {
			console.log('user id not present skipping fetching its agenda')

		}
	}, [userId, setUserAgenda]);

	const fetchUserAgendaAndAppointments = async () => {
		if (userId !== "") {
			try {
				console.log('fetching appointmnets commerciq qgenda')

				const response = await axiosClient.get(`users/${userId}/agenda-with-appointments`);
				const appointments = response.data.user.rdvs;

				// Filter appointments based on desired conditions
				const filteredAppointments = appointments.filter(appointment => {
					return (
						appointment.status === "NRP" ||
						appointment.status === "confirmer" ||
						appointment.reserver_plage_horaire == 1 ||
						appointment.rdv_prive == 1
					);
				});
				setUserAppointments(filteredAppointments);
			} catch (error) {
				console.error("Error fetching user agenda:", error);
			} finally {
				setLoading2(false);
			}
		}
	};

	useEffect(() => {
		// Initialize Pusher
		const pusher = new Pusher('df87a0d076cd20d84204', {
			cluster: 'eu',
			encrypted: true,
			logToConsole: true,
		});

		console.log('Pusher initialized.');

		// Subscribe to the channel and bind to the events
		const channel = pusher.subscribe('rdvs');

		const handleRdvUpdated = (data) => {
			if (data && data.rdv) {
				console.log('Event triggered for rdv.updated.', data.rdv);

				setUserAppointments((prevAppointments) => {
					// Create a map from the previous appointments
					const appointmentMap = new Map(prevAppointments.map(appt => [appt.id, appt]));

					// Add or update the new appointment
					appointmentMap.set(data.rdv.id, data.rdv);

					// Convert the map back to an array
					const updatedAppointments = Array.from(appointmentMap.values());

					console.log('Updated appointments:', updatedAppointments);

					setRefreshNeeded(true);
					return updatedAppointments;
				});


			} else {
				setRefreshNeeded(false);
				console.error('Invalid data received from Pusher:', data);
			}
		};




		const handleRdvDeleted = (data) => {
			if (data && Array.isArray(data.deletedIds)) {
				console.log('Event triggered for rdv.deleted.', data.deletedIds);

				setUserAppointments((prevAppointments) => {
					const updatedAppointments = prevAppointments.filter(appt => !data.deletedIds.includes(appt.id));
					console.log('Updated appointments after deletion:', updatedAppointments);

					return updatedAppointments;
				});
				// window.location.reload();
			} else {
				console.error('Invalid data received from Pusher for deletion:', data);
			}
		};

		// Bind the events
		channel.bind('rdv.updated', handleRdvUpdated);
		channel.bind('rdv.deleted', handleRdvDeleted);

		// Cleanup on component unmount
		return () => {
			channel.unbind('rdv.updated', handleRdvUpdated);
			channel.unbind('rdv.deleted', handleRdvDeleted);
			pusher.unsubscribe('rdvs');
			pusher.disconnect();
		};
	}, [setUserAppointments]);


	useEffect(() => {
		if (refreshNeeded) {
			// Fetch data again
			fetchUserAgendaAndAppointments()
				.finally(() => setRefreshNeeded(false));
		}
	}, [refreshNeeded]);
	useEffect(() => {
		// Fetch initial data
		fetchUserAgendaAndAppointments()
	}, []);


	const getMinimumAllowedDate = () => {
		const currentDate2 = moment().startOf('day');
		let minDate = currentDate2.clone();
		let weekdaysCount = 0;

		while (weekdaysCount < 4) {
			minDate.add(1, 'days');
			if (minDate.day() !== 0 && minDate.day() !== 6) {
				weekdaysCount++;
			}
		}

		return minDate;
	};

	const handleAddAppointmentCallback = (arg, userContext) => {
		const currentDate = new Date();
		const selectedDate = new Date(arg.date);
		const currentDate2 = moment(arg.date).startOf('day');
		const minimumAllowedDate = getMinimumAllowedDate();
		if (selectedDate < currentDate) {
			Modal.warning({
				title: "Impossible d'ajouter un rendez-vous",
				content: "Vous ne pouvez pas ajouter de rendez-vous à des dates passées.",
			});
			return;
		}
		if (currentDate2.isBefore(minimumAllowedDate)) {
			Modal.warning({
				title: "Impossible d'ajouter un rendez-vous",
				content: "Vous ne pouvez pas ajouter de rendez-vous avant 4 jours ouvrables à partir d'aujourd'hui.",
			});
			return;
		}

		setShowRdvPrvCalednar(true)
		setSelectedDate({ date: arg.date, agentId, agendaId });
		setShowAddModal(false);

		Modal.destroyAll();
	};

	const handleFormSubmitCallback = async (newAppointment) => {
		setUserAppointments(prevAppointments => [...prevAppointments, newAppointment]);
		setAddPrivateModalVisible(false);
		setShowRdvPrvCalednar(false)
		fetchUserAgendaAndAppointments();
	};

	const handleAppointmentClickCallback = async (event) => {

		const clickedDate = new Date(event.start);
		const currentDate = new Date();
		currentDate.setHours(0, 0, 0, 0);

		const appointmentDateOnly = new Date(clickedDate);
		appointmentDateOnly.setHours(0, 0, 0, 0);

		const isToday = appointmentDateOnly.getTime() === currentDate.getTime();
		const isTomorrow = appointmentDateOnly.getTime() === new Date(currentDate.setDate(currentDate.getDate() + 1)).setHours(0, 0, 0, 0);
		const isPast = appointmentDateOnly < currentDate;
		const currentHour = new Date().getHours();
		const isOwner = event.extendedProps.agent == userId;

		if (isTomorrow && currentHour >= 18) {
			// Allow viewing appointment details if it's tomorrow and time is after 18:00
			handleAppointmentClickAC(
				event,
				userContext,
				setAgentId,
				setAppointmentDetails,
				setSelectedRowData,
				setShowDetailModal,
				setSelectedAppointment,
				axiosClient,
				agendaId,
				setShowStatuerModal
			);
			setSelectedAppointmentDate(event.start);
		} else if (isToday || isPast) {
			// Allow viewing appointment details for today and past appointments
			handleAppointmentClickAC(
				event,
				userContext,
				setAgentId,
				setAppointmentDetails,
				setSelectedRowData,
				setShowDetailModal,
				setSelectedAppointment,
				axiosClient,
				agendaId,
				setShowStatuerModal
			);
		} else {
			if (isOwner) {
				setShowUpdate(true)

			} else {
				// Show warning modal for appointments on other days
				Modal.warning({
					title: "Impossible de voir ce rendez-vous",
					content: "Vous pouvez uniquement consulter les rendez-vous pour demain après 18h",
				});
			}





		}
	};




	if (loading && loading2) {

		return (
			<div style={{ textAlign: 'center', marginTop: '20%' }}>
				<Spin size="large" />
			</div>
		);

	}

	const handleCloseBlockModal = () => {
		setShowRdvPrvCalednar(false);
	};

	const tomorrowDate = new Date();
	tomorrowDate.setDate(tomorrowDate.getDate() + 1);

	return (
		<div>
			<Card style={{ width: "100%" }}>
				{userAgenda && (
					<FullCalendar
						plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
						{...JSON.parse(userAgenda.fullcalendar_config)}
						dateClick={(arg) => {
							handleAddAppointmentCallback(arg, userId, userAgenda.id);
						}}
						headerToolbar={{
							left: "prev,next today",
							center: "title",
							right: "dayGridMonth,timeGridWeek,timeGridDay",
						}}
						eventClick={(info) => handleAppointmentClickCallback(info.event)}
						events={userAppointments.map((appointment) => {
							// console.log('appointment', appointment)
							const showPrenom = appointment.prenom && appointment.prenom.toLowerCase() !== "null";
							let title;
							const status = appointment.status == "confirmer" ? "Conf" : appointment.status;

							if (appointment.rdv_prive == 1) {
								title = appointment.postal ? `${appointment.postal} - RDV privé` : "RDV privé";
							} else if (appointment.reserver_plage_horaire == 1) {
								title = appointment.postal ? `${appointment.postal} - Pas de RDV` : "Pas de RDV";

							} else {

								title = appointment.tel ?
									`${appointment.postal} / ${appointment.nom}${showPrenom ? ' ' + appointment.prenom : ''}` :
									`RDV privé / ${appointment.postal}`;
							}

							return {
								id: appointment.id,
								title: title,
								start: appointment.start_date,
								end: appointment.end_date,
								status: status,
								bloquer: appointment.bloquer,
								agent: appointment.id_agent,
								status_ac: appointment.status_ac,
								rdv_prive: appointment.rdv_prive
							};
						})}
						eventDidMount={(arg) => {
							arg.el.style.backgroundColor = "#cfdddbdf";
						}}
						views={{
							week: {
								type: "timeGridWeek",
								duration: {
									weeks: 1,
								},
							},
						}}
						initialView="week"
						slotMinTime="09:00"
						slotMaxTime="19:00"
						weekends={false}
						height="auto"
						nowIndicator={true}

						eventContent={(arg) => {
							let statusColor = 'black';


							if (arg.event.extendedProps.status == 'Conf') {
								statusColor = '#40A578';
							} else if (arg.event.extendedProps.status == 'NRP') {
								statusColor = '#FF8A08';
							}

							return (
								<div className="fc-event-title">
									<span>	{arg.event.extendedProps.status_ac ? `${arg.event.extendedProps.status_ac} / ` : ''}</span>
									<span style={{ color: statusColor }}>

										{arg.event.extendedProps.status}
									</span> / {arg.event.title}
								</div>
							);
						}}


					/>

				)}



			</Card>
			<Modal
				visible={addPrivateModalVisible}
				onCancel={() => setAddPrivateModalVisible(false)}
				footer={null}
			>
				<AddPrivateAppointmentModal
					onFormSubmit={handleFormSubmitCallback}
					selectedDate={selectedDate}
					userId={userId}
					agendaId={userAgenda?.id}
					setShowRdvPrvCalednar={setShowRdvPrvCalednar}
					width="40%"
				/>
			</Modal>
			<Modal
				open={showRdvPrvCalednar}
				title="Ajouter RDV"
				onCancel={handleCloseBlockModal}
				footer={null}
				width="45%"
			>
				<AddRdvPrvCalednar
					selectedDate={selectedDate}
					onFormSubmit={handleFormSubmitCallback}
					selectedAppointmentDate={selectedAppointmentDate}
					userId={userId}
					agendaId={userAgenda?.id}
					setShowRdvPrvCalednar={setShowRdvPrvCalednar}
				/>
			</Modal>
			{showDetailsModal && selectedRowData?.rdv.nom && (
				<Modal
					open={showDetailsModal}
					onCancel={() => setShowDetailModal(false)}
					footer={null}
					width={1000}
					style={{ maxHeight: "80vh" }}
				>
					<AppointmentDetailsCom
						rdv={selectedRowData?.rdv}
						agent={selectedRowData?.agent}
						userContext={userContext}
						agentId={agentId}
						selectedRowData={selectedRowData}
						setShowDetailModal={setShowDetailModal}
						appointments={userAppointments}
						setAppointments={setUserAppointments}
						setShowStatuerModal={setShowStatuerModal}
					/>
				</Modal>
			)}
			{showStatuerModal && selectedRowData?.rdv.nom && (
				<Modal
					open={showStatuerModal}
					onCancel={() => setShowStatuerModal(false)}
					footer={null}
					width={500}
					style={{ maxHeight: "80vh" }}
					title="Statuer le rendez-vous : "
				>
					<StatuerRdv
						rdv={selectedRowData?.rdv}
						agent={selectedRowData?.agent}
						userContext={userContext}
						agentId={agentId}
						selectedRowData={selectedRowData}
						setShowDetailModal={setShowDetailModal}
						appointments={userAppointments}
						setAppointments={setUserAppointments}
						setShowStatuerModal={setShowStatuerModal}
						onFormSubmit={handleFormSubmitCallback}

					/>
				</Modal>
			)}
			{showUpdate && selectedRowData?.rdv && (
				<Modal
					title="Modifier RDV"
					width="50%"
					open={showUpdate}
					onCancel={() => setShowUpdate(false)}
					footer={null}
					style={{ maxHeight: "80vh" }}
				>
					<UpdatePrivateAppointmentModal
						appointmentData={selectedRowData?.rdv}
						userId={userId}
						onFormSubmit={handleFormSubmitCallback}
						setShowUpdate={setShowUpdate}
						userAppointments={userAppointments}
						appointments={appointments}
						setAppointments={setAppointments}
						setUserAppointments={setUserAppointments}
					/>
				</Modal>
			)}
		</div>
	);
}

export default AgentCommercialCalendar;
