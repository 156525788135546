import { message, Modal } from "antd"
import emailjs from "emailjs-com";
import { fullCalendarConfig } from "../../caledrier/services/calendarConfig";
import { handleEventDrop } from "../../caledrier/services/api";
import { axiosClient } from '../../../api/axios';

export async function addUser(formData) {
	try {
		console.log('addUser function called');
		console.log('FormData:', formData);
		const response = await axiosClient.post(`users`, formData)
		console.log('POST request successful:', response);
		message.success("User created successfully")
	} catch (error) {
		console.error('Error creating user:', error)
		message.error("Failed to create user")
	}
}

export async function fetchUsers(userRole) {
	try {
		console.log('fetching users ...')
		const response = await axiosClient.get(`users`)
		const users = response.data.users
		console.log('users', users)
		let filteredUsers = users
		if (userRole === 'Admin') {
			filteredUsers = users.filter(user => user.role !== 'superadmin')
		} else if (userRole === 'Superviseur') {
			filteredUsers = users.filter(user => user.role !== 'superadmin' && user.role !== 'Admin')
		}
		return filteredUsers;
	} catch (error) {
		console.error('Failed to fetch users:', error)
		throw error;
	}
}

export async function deleteUser(userId) {
	try {

		const response = await axiosClient.delete(`users/${userId}`)
		return response
	} catch (error) {
		throw new Error("Failed to delete user: " + error.message)
	}
}

// add user

export const handleFileUpload = (e, setFormData, setImagePreview, formData) => {
	console.log('handleFileUpload called ...')
	const file = e.target.files[0];
	setFormData({
		...formData,
		image: file,
	});
	console.log('file ', file)
	console.log('formData ', formData)

	if (file) {
		const reader = new FileReader();
		reader.onloadend = () => {
			setImagePreview(reader.result);
		};
		reader.readAsDataURL(file);
	}
};

export const handleSubmit = async (formData, form, setImagePreview, history, appointments) => {
	try {
		if (formData.password !== formData.confirmPassword) {
			throw new Error("The password confirmation does not match.");
		}
		console.log('formData', formData)
		const submitData = new FormData();
		for (const key in formData) {
			if (formData.hasOwnProperty(key)) {
					
					if (key === 'image' && (formData[key] == "null" || !formData[key])) {
							continue;
					} else {
							submitData.append(key, formData[key]);
					}
			}
	}
		const endpoint = formData.role === "Agent Commercial"
			? "users/create-agenda-with-user"
			: "users";
		if (formData.role === "Agent Commercial") {
			const config = fullCalendarConfig(appointments, handleEventDrop);
			const configString = JSON.stringify(config);
			submitData.append('fullcalendar_config', configString);
		}
		try {
			const res = await axiosClient.post(endpoint, submitData, {
				headers: {
					Accept: "application/json",
					"Content-Type": "multipart/form-data",
				},
			});
		console.log('res', res.data)


			let successContent = "L'utilisateur a été ajouté avec succès.";
			if (formData.role === "Agent Commercial") {
				successContent += " Un agenda sera créé automatiquement.";
			}
			Modal.success({
				title: "Utilisateur ajouté avec succès",
				content: successContent,
			});
			const link = "https://frontend.myguestapp.com/"

			const messageContent = `Votre compte a été créé avec succès. Voici les informations de votre compte :
			Nom: ${formData.nom}
			Prénom: ${formData.prenom}
			Email: ${formData.email}
			Mot de passe: ${formData.password}
			Lien: ${link}
			L'équipe Sunlight Call
			
			`;
			

			const templateParams = {
				to_name: `${formData.nom} ${formData.prenom}`,
				message: messageContent,
				to_email: `${formData.email}`,
				from_email: "contact@sunlight-call.com",
				subject: "Confirmation de création de compte", 
			};

			emailjs.send('service_n3zi7eg', 'template_gcza85i', templateParams, 'Ki-Vc1ucXjgbwgru_')
				.then((result) => {
					message.success('Email de confirmation envoyé avec succès.');
				}, (error) => {
					console.error('Error sending email:', error.text);
				});

			history.goBack();
			form.resetFields();
			setImagePreview(null);
		} catch (error) {
			console.error('Error adding user', error.response);
			throw error;
		}
	} catch (error) {
		if (error.response && error.response.data.message && error.response.data.message.includes("email has already been taken")) {
			form.setFields([
				{
					name: "email",
					errors: ["L'email est déjà pris. Veuillez en choisir un autre."],
				},
			]);
		} else {
			Modal.error({
				title: "Erreur lors de l'ajout",
				content: "Une erreur s'est produite lors de l'ajout de l'utilisateur.",
			});
			console.error("Error:", error.response);
		}
	}
};

// users table

export const fetchUsersData = async (userRole) => {
  try {
    const response = await fetchUsers(userRole);
    if (Array.isArray(response)) {
			console.log('fetching users ... 1')
      return response;
    } else {
      console.error('Invalid response format from fetchUsers:', response);
      return [];
    }
  } catch (error) {
    console.error("Error fetching users:", error);
    return [];
  }
};

// user count stat

export const fetchUserStatistics = async (setUserStat) => {
	try {
			const response = await axiosClient.get('users/getUsersCountByRole');
			setUserStat(response.data.userCounts);
	} catch (error) {
			console.error('Error fetching user statistics:', error);
	}
};

// update user

const tempId = "template_gcza85i";
const serviceId = "service_n3zi7eg";
const key = "Ki-Vc1ucXjgbwgru_";

export const handleUpdateUser = (userData, form, imageFile, onUpdate, onCancel) => {
  form.validateFields().then((values) => {
    const { nom, prenom, email, password, role, isActive } = values;
    const updatedValues = { nom, prenom, email, role, isActive: isActive ? 1 : 0 };
		console.log("Updated Values:", updatedValues); 
    if (password) {
      updatedValues.password = password;
    }
    const formData = new FormData();
    Object.keys(updatedValues).forEach((key) => {
      formData.append(key, updatedValues[key]);
    });
    if (imageFile) {
      formData.append('image', imageFile);
    }

    formData.append('_method', 'PUT');
    axiosClient
      .post(`users/${userData.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
				console.log("Update response:", response);
        if (password) {
          sendPasswordEmail(email, password);
        }
        onUpdate();
        onCancel();
				console.log('res', response.data)
        message.success("Utilisateur mis à jour avec succès.");
      })
      .catch((error) => {
        console.error("Error updating user:", error);
        message.error("Failed to update user: " + error.response.data.message);
      });
  }).catch((info) => {
    message.error("Validation failed: Please check the form fields.");
  });
};

const sendPasswordEmail = (userEmail, newPassword) => {
  emailjs.send(serviceId, tempId, {
    to_email: userEmail,
    from_email: "ilyou.light@gmail.com",
    message: `Votre mot de passe a été modifié. Votre nouveau mot de passe est : ${newPassword}`
  }, key)
    .then((result) => {
      message.success("Un email a été envoyé à l'utilisateur avec le nouveau mot de passe.");
    })
    .catch((error) => {
      console.error("Error sending email to user:", error);
      message.error("Une erreur s'est produite lors de l'envoi de l'email à l'utilisateur.");
    });
};

//delete user api
export const handleDeleteUser = async (id, userRole, setUsers) => {
	// if (userRole !== "Admin" || userRole != "superadmin") {
	// 	console.log('user role', userRole)
	// 	Modal.warning({
	// 		title: "Opération non autorisée",
	// 		content: "Vous n'êtes pas autorisé à supprimer cet utilisateur. Seuls les administrateurs peuvent le faire.",
	// 	});
	// 	return;
	// }

	Modal.confirm({
		title: "Confirmation de suppression",
		content: "Êtes-vous sûr de vouloir supprimer cet utilisateur ?",
		okText: "Oui",
		okType: "danger",
		cancelText: "Annuler",
		onOk: async () => {
			try {
				console.log('deleting users ...')
				// Send a request to delete the user with the specified ID
				const res = await axiosClient.delete(`users/${id}`);
				setUsers(prevUsers => prevUsers.filter(user => user.id !== id));

				// Display success message
				Modal.success({
					title: "Suppression réussie",
					content: "L'utilisateur a été supprimé avec succès.",
				});
				console.log('res', res.data)
			} catch (error) {
				// Display error message
				Modal.error({
					title: "Erreur de suppression",
					content: "Une erreur s'est produite lors de la suppression de l'utilisateur.",
				});
				console.error("Error deleting user:", error);
			}
		},
		onCancel() {
			console.log("Suppression annulée");
		},
	});
};
