import { Card, Col, Row, Spin, Typography } from "antd";
import React, { useEffect, useState } from 'react';
import { axiosClient } from "../../../api/axios";
import home from "../../../assets/images/home.gif";
import absence from "../../../assets/images/absence.gif";
import ok from "../../../assets/images/ok.gif";
import task from "../../../assets/images/task.gif";
import tax from "../../../assets/images/power.gif";
import tarif from "../../../assets/images/payment.gif";
import { ClockCircleOutlined } from '@ant-design/icons';
import EChart from './Echart';

function StatistiqueCount({ userId }) {
    const { Title } = Typography;
    const [appointmentStatistics, setAppointmentStatistics] = useState({});
    const [loading, setLoading] = useState(true);
    const [currentDate, setCurrentDate] = useState('');
		const [startOfMonthDate, setStartOfMonthDate] = useState('');

		useEffect(() => {
			const currentDateObj = new Date();
			const startOfMonth = new Date(currentDateObj.getFullYear(), currentDateObj.getMonth(), 1);
			const startOfMonthStr = new Intl.DateTimeFormat('fr-FR', { day: '2-digit', month: 'long' }).format(startOfMonth);
			
			setStartOfMonthDate(startOfMonthStr);
	
		}, []);

    useEffect(() => {
        const fetchAppointmentStatistics = async () => {
					console.log('fetching app stat')
            try {
                const response = await axiosClient.get(`rdvs/agent-commercial/status-count/${userId}`);
								console.log('fetchAppointmentStatistics', response.data)
                setAppointmentStatistics(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching appointment statistics:', error);
            }
        };

        const formatDate = () => {
            const dateObj = new Date();
            const options = {
                month: 'short',
                year: 'numeric'
            };
            const formattedDate = new Intl.DateTimeFormat('fr-FR', options).format(dateObj);
            setCurrentDate(formattedDate);
        };

        formatDate();

        if (userId) {
            fetchAppointmentStatistics();
        }
    }, [userId]);

    const count = appointmentStatistics ? [
        {
            today: "OK",
            title: appointmentStatistics.ok,
            persent: "",
            icon: ok,
            color: '#00E396', 
        },
        {
            today: "Absent",
            title: appointmentStatistics.nok_absent,
            persent: "",
            icon: absence,
        },
        {
            today: "À vérifier",
            title: appointmentStatistics.nok_verifier,
            persent: "",
            icon: task,
        },
        {
            today: "Adresse erronée ",
            title: appointmentStatistics.nok_adresse,
            persent: "",
            icon: home,
        },
        {
            today: "Haute Tension",
            title: appointmentStatistics.nok_ht,
            persent: "",
            icon: tax,
        },
        {
            today: "Tarif social",
            title: appointmentStatistics.nok_tarif_social,
            persent: "",
            icon: tarif,
        }
    ] : [];

    if (loading) {
        return (
            <div style={{ textAlign: 'center', marginTop: '20%' }}>
                <Spin size="large" />
            </div>
        );
    }

    return (
			<>
			<div className="layout-content">
            <Title level={4} style={{ color: "#4F1787", fontSize: "18px",marginBottom:'30px' }}>Statistiques des rendez-vous du {startOfMonthDate} jusqu'à aujourd'hui</Title>
            <Row className="rowgap-vbox" gutter={[24, 0]}>
                {count.map((c, index) => (
                    <Col
                        key={index}
                        xs={24}
                        sm={24}
                        md={8}
                        lg={8}
                        xl={8}
                        className="mb-24"
                    >
                        <Card bordered={false} className="criclebox " style={{ height: '120px', padding:"15px"  }}>
                            <div className="number">
                                <Row align="middle" gutter={[24, 0]}>
                                    <Col xs={18}>
                                        <span>{c.today}</span>
                                        <Title level={4} style={{ color: c.today === 'OK' ? '#00E396' : '#EE4E4E' }}>
                                            {c.title} Rendez-vous
                                        </Title>
                                        <span style={{ fontSize: '13px', color: 'gray' }}>
																				<ClockCircleOutlined style={{ marginRight: '8px', }} />{currentDate}</span>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="icon-box">
                                            <img src={c.icon} alt={c.today} style={{ width: '40px', marginBottom: '10px' }} />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
				<EChart userId={userId} />

			</>
        
    );
}

export default StatistiqueCount;
