// src/components/List/components/AgendaTable.js
import React from 'react';
import { Table, Space, Tooltip } from 'antd';
import { StopOutlined, CheckOutlined } from '@ant-design/icons';

const AgendaTable = ({ calendrier, handleHideAgenda, handleShowAgenda }) => {
	const columns = [
		{
			title: 'Agent Commercial',
			dataIndex: 'agentCommercial',
			key: 'agentCommercial',
			render: (_, record) => {
				const user = record.user;
				return user ? `${user.nom} ${user.prenom}` : 'Unknown';
			},
		},
		{
			title: 'Total des Rendez-vous',
			dataIndex: 'total_appointments',
			key: 'total_appointments',
		},
		{
			title: 'Action',
			key: 'action',
			render: (_, record) => (
				<Space>
					{record.isDisabled == 1 ? (
						<Tooltip title="Réactiver">
							<CheckOutlined
								onClick={() => handleShowAgenda(record.id)}
								style={{ color: '#00E396' }}
							/>
						</Tooltip>
					) : (
						<Tooltip title="Désactiver">
							<StopOutlined
								onClick={() => handleHideAgenda(record.id, record.contact_id)}
								style={{ color: '#FF0000' }}
							/>
						</Tooltip>
					)}
				</Space>
			),
		},
	];

	return (
		<Table
			dataSource={calendrier}
			columns={columns}
			rowKey="id"
			pagination={{ pageSize: 5 }}
		/>
	);
};

export default AgendaTable;
