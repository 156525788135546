import React, { useState, useRef, useEffect } from "react";
import { Card, Col, Row, Space, Typography, Tag, Button, Modal } from "antd";
import { CheckCircleTwoTone, CloseCircleTwoTone, EditOutlined } from '@ant-design/icons';
import "../../rdv/views/rdv.css";

const { Text } = Typography;

const AppointmentDetailsCom = ({ rdv, setShowStatuerModal, setShowDetailModal, appointments }) => {
	const clientInfoCardRef = useRef(null);
	const appointmentInfoCardRef = useRef(null);
	const notesCardRef = useRef(null);
	const [cardHeight, setCardHeight] = useState("auto");
	const updateCardHeight = () => {
		const clientInfoCardHeight = clientInfoCardRef?.current?.offsetHeight;
		const appointmentInfoCardHeight = appointmentInfoCardRef?.current?.offsetHeight;
		const notesCardHeight = notesCardRef?.current?.offsetHeight;
		const maxHeight = Math.max(clientInfoCardHeight, appointmentInfoCardHeight, notesCardHeight);
		setCardHeight(maxHeight + "px");
	};

	useEffect(() => {
	console.log('rdv', rdv)

		console.log('ppv:', ppv, 'Type:', typeof ppv);
		console.log('tarif:', tarif, 'Type:', typeof tarif);
		console.log('haute_tension:', haute_tension, 'Type:', typeof haute_tension);
	
		updateCardHeight();
	}, [rdv]);

	if (!rdv) {
		return <div>No appointment data available</div>
	}

	const { status, end_date, start_date, note, commentaire, nom, prenom, pro, tel, gsm, postal, adresse, fournisseur, nbr_comp_elect, nbr_comp_gaz, ppv, tarif, tarification, haute_tension, status_ac, porte, nom_ste, tva } = rdv || {};
	const agentName = `${rdv.user.nom} ${rdv.user.prenom}`;

	let statusColor = "#FF8A08";
	const clientType = pro == 1 ? "Professionnel" : "Residential";
	switch (status) {
		case "encours":
			statusColor = "#373A40";
			break;
		case "confirmer":
			statusColor = "#00E396";
			break;
		case "annuler":
			statusColor = "red";
			break;
		default:
			break;
	}
	const clientTypeColor = pro == 1 ? "geekblue" : "gold";
	const formatDate = (date, includeDate = true) => {
		if (!date) return "-";
		const formattedTime = new Date(date).toLocaleTimeString('fr-FR', { hour: 'numeric', minute: 'numeric', hour12: false });
		const formattedDate = includeDate ? new Date(date).toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' }) : '';
		return `${includeDate ? formattedDate + ' à ' : ''}${formattedTime}`;
	};

	const handleOpenStatuerModal = () => {
		console.log('status_ac', status_ac)
		if (!status_ac || status_ac == null) {
			const currentAppointmentDate = new Date(rdv.start_date);
			console.log('currentAppointmentDate', currentAppointmentDate)
			currentAppointmentDate.setHours(0, 0, 0, 0);
			// Check if the appointment is for tomorrow or later
			const isTomorrowOrLater = currentAppointmentDate > new Date();
			console.log('isTomorrowOrLater', isTomorrowOrLater)

			// Display appropriate warning message for future appointments
			if (isTomorrowOrLater) {
				Modal.warning({
					title: 'Impossible de mettre à jour le statut',
					content: 'Vous pouvez seulement statuer un rendez-vous prévu pour aujourd\'hui.',
				});
				return;
			}

			// Filter previous appointments excluding reserver_plage_horaire and rdv_prive
			const previousAppointments = appointments.filter(appointment => {
				const appointmentDate = new Date(appointment.start_date);
				appointmentDate.setHours(0, 0, 0, 0);
				console.log('appointment.reserver_plage_horaire_ac', appointment.reserver_plage_horaire)
				console.log('appointment.rdv_prive', appointment.rdv_prive)

				// Exclude appointments with reserver_plage_horaire or rdv_prive
				if (appointment.reserver_plage_horaire == 1 || appointment.rdv_prive == 1) {
					return false;
				}

				// Filter appointments for today and yesterday
				return (
					(appointmentDate.getTime() == currentAppointmentDate.getTime() ||
						appointmentDate.getTime() == currentAppointmentDate.getTime() - 24 * 60 * 60 * 1000) &&
					new Date(appointment.start_date) < new Date(rdv.start_date)
				);
			});
			console.log('previousAppointments', previousAppointments)

			// Check if there are previous appointments
			if (previousAppointments.length > 0) {
				// Ensure all previous appointments have status_ac
				const allPreviousAppointmentsHaveStatusAC = previousAppointments.every(appointment => appointment.status_ac);
				console.log('allPreviousAppointmentsHaveStatusAC', allPreviousAppointmentsHaveStatusAC)


				// Display warning if not all previous appointments have status_ac
				if (!allPreviousAppointmentsHaveStatusAC || allPreviousAppointmentsHaveStatusAC == false) {
					Modal.warning({
						title: 'Impossible de mettre à jour le statut',
						content: 'Vous ne pouvez pas mettre à jour le statut si vous n\'avez pas mis à jour les rendez-vous précédents.',
					});
					return;
				}
			}

			// Open the statuer modal if conditions are met
			setShowStatuerModal(true);
			setShowDetailModal(false);
		} else {
			Modal.warning({
				title: "Ce rendez-vous a déjà été statué.",
				content: " Vous ne pouvez pas mettre à jour le statut à nouveau.",
			});
		}
	};

	const ppvNumber = parseInt(ppv, 10);
	const tarifNumber = parseInt(tarif, 10);
	const hauteTensionNumber = parseInt(haute_tension, 10);



	return (
		<div>


			<Card style={{ marginTop: "20px" }}>
				<Row justify="space-between" align="middle" style={{ marginTop: "10px" }}>
					<Col >
						<Text strong>Date du rendez-vous : </Text>
						<Text>{formatDate(start_date)} - {formatDate(end_date, false)}</Text>

					</Col>

					<Col >
						<Tag color={statusColor}>
							RDV : {status_ac ? `${status_ac} - ${status}` : status}
						</Tag>
					</Col>
					<Col>
						<Button onClick={handleOpenStatuerModal} className="update_ac">
							<EditOutlined />
							Statuer
						</Button>
					</Col>
				</Row>


			</Card>

			<Row gutter={[16, 16]} >
				<Col span={9}>
					<Card size="small" className="carddisplayrdv" ref={clientInfoCardRef} style={{ height: cardHeight }}>
						<Row >
							<Col span={24}>
								<p><strong>Agent :</strong> {agentName}</p>
							</Col>

						</Row>
						<Row>
							<Col span={24}>
								<p><strong>Client :</strong> {rdv && nom && nom.toLowerCase() !== 'null' ? `${nom} ${prenom && prenom.toLowerCase() !== 'null' ? prenom : ''}` : 'N/A'}</p>


							</Col>


						</Row>
						<Row>
							<Col span={24}>
								<p><strong>Tél :</strong>{tel ? `+32${tel}` : ' N/A'}</p>
							</Col>
							<Col span={24}>


								<p><strong>GSM :</strong>{gsm ? `+324${gsm}` : ' N/A'}</p>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<p><strong>Rue :</strong> {adresse}</p>
							</Col>
							<Col span={24}>
								<p><strong>Numéro :</strong> {porte}</p>
							</Col>
							<Col span={24} >

								<p> <strong>Code Postal :</strong> {postal}</p>
							</Col>

						</Row>
						{clientType == "Professionnel" && (
							<Row>

								<Col span={24}>
									<p><strong>Société :</strong> {nom_ste ? nom_ste : "N/A"}</p>

								</Col>


								<Col span={24}>


									<p><strong>TVA :</strong>{tva ? `BE0${tva}` : "N/A"}</p>

								</Col>
							</Row>
						)}


					</Card>
				</Col>
				<Col span={15}>
					<Card size="small" className="carddisplayrdv" ref={appointmentInfoCardRef} style={{ height: cardHeight }}>
						<Row>
							<Col span={6} offset={18}>
								<Tag color={clientTypeColor}><strong>Type :</strong> {clientType}</Tag>
							</Col>
							<Col span={24}>
								<p><strong>Fournisseur:</strong> {fournisseur}</p>
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<p><strong>Nombre de compteurs électriques :</strong> {nbr_comp_elect}</p>
							</Col>
							<Col span={12}>
								<p><strong>Nombre de compteurs gazs :</strong> {nbr_comp_gaz}</p>
							</Col>
						</Row>

						<Row gutter={[16, 16]}>
							<Col span={24}>
								{commentaire ? (
									<Card title="Commentaire" size="small" bordered={false} className="carddisplayrdvnote" style={{ backgroundColor: "#E6F4FF" }}>
										<p>{commentaire}</p>
									</Card>
								) : (
									<Card title="Commentaire" size="small" bordered={false} className="carddisplayrdvnote" style={{ backgroundColor: "#FFFAB7" }}>
										<p>Aucun commentaire</p>
									</Card>
								)}


							</Col>
						</Row>

						<Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
							<Col span={24}>
								<Space size="large">
									<Space align="baseline">
										<Text strong>PPV :</Text>
										{ppvNumber == 1 ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />}
									</Space>
									<Space align="baseline">
										<Text strong>Tarif Social :</Text>
										{tarifNumber == 1 ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />}
									</Space>
									<Space align="baseline">
										<Text strong>Haute Tension :</Text>
										{hauteTensionNumber == 1 ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />}
									</Space>
									<Space align="baseline">
										<Text strong>Tarification :</Text>
										<Text type={tarification == "fixe" ? "default" : tarification == "Variable" ? "success" : "default"}>{tarification}</Text>
									</Space>
								</Space>
							</Col>
						</Row>
					</Card>
				</Col>

				<Col span={24}>
					{note ? (
						<Card title="Notes" size="small" bordered={false} className="carddisplayrdvnote" ref={notesCardRef} style={{ backgroundColor: "#FFF2F0" }}>
							<p>{note}</p>
						</Card>
					) : (
						<Card title="Notes" size="small" bordered={false} className="carddisplayrdvnote" ref={notesCardRef} style={{ backgroundColor: "#FFF2F0" }}>
							<p>Aucune note</p>
						</Card>
					)}
				</Col>


			</Row>
		</div>
	);
};

export default AppointmentDetailsCom;