import React, { useEffect, useState, useMemo } from "react";
import { Card, Input, Form, Row, Col, ConfigProvider, DatePicker, message, Alert, Button, Modal } from "antd";
import frFR from "antd/lib/locale/fr_FR";
import moment from "moment";
import { axiosClient } from "../../../api/axios";
import { StopOutlined } from "@ant-design/icons";
import { useUser } from "../../../GlobalContext";
import { useAuth } from "../../../AuthContext";

const BlockRdv = ({ selectedDate, onFormSubmit, setShowBlockModal }) => {
	const [loading, setLoading] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [formKey, setFormKey] = useState(0);
	const [selectedStartDate, setSelectedStartDate] = useState(null);
	const [selectedEndDate, setSelectedEndDate] = useState(null);
	const { userRole } = useUser()
	const { userId } = useAuth()



	useEffect(() => {
		resetForm();
		if (selectedDate && selectedDate.date) {
			const initialStartDate = moment(selectedDate.date);
			const initialEndDate = moment(selectedDate.date).add(1, 'hour');
			setSelectedStartDate(initialStartDate);
			setSelectedEndDate(initialEndDate);
			setFormData({
				...formData,
				appointment_date: [
					initialStartDate.toDate(),
					initialEndDate.toDate()
				]
			});
		}
	}, [selectedDate]);



	const handleStartDateChange = (date) => {
		setSelectedStartDate(date);
		if (date && (!selectedEndDate || date.isAfter(selectedEndDate))) {
			const newEndDate = moment(date).add(1, 'hour');
			setSelectedEndDate(newEndDate);
			setFormData({
				...formData,
				appointment_date: [
					date.toDate(),
					newEndDate.toDate()
				]
			});
		} else {
			setFormData({
				...formData,
				appointment_date: [
					date.toDate(),
					selectedEndDate ? selectedEndDate.toDate() : null
				]
			});
		}
	};

	const handleEndDateChange = (date) => {
		setSelectedEndDate(date);
		setFormData({
			...formData,
			appointment_date: [
				selectedStartDate ? selectedStartDate.toDate() : null,
				date.toDate()
			]
		});
	};

	const [formData, setFormData] = useState({
		postal: "",
		note: "",
		bloquer: true,
		appointment_date: selectedDate
			? [
				selectedDate.date && new Date(selectedDate.date),
				selectedDate.date && new Date(selectedDate.date.getTime() + 3600000),
			]
			: null,
	});

	const resetForm = () => {
		setFormKey((prevKey) => prevKey + 1);
		setSelectedStartDate(null);
		setSelectedEndDate(null);
		setFormData({
			postal: "",
			note: "",
			bloquer: true,
			appointment_date: null,
		});
	};

	const initialValues = useMemo(() => ({
		postal: "",
		note: "",
		bloquer: true,
		appointment_date: selectedDate && selectedDate.date
			? [
				new Date(selectedDate.date),
				new Date(selectedDate.date.getTime() + 3600000),
			]
			: null,
	}), [selectedDate]);

	const handleBloquerRdv = async () => {
		console.log('handleBloquerRdv called')
		setLoading(true);

		if (!selectedStartDate || !selectedEndDate) {
			setLoading(false);
			return;
		}

		const startDate = selectedStartDate.toDate();
		const endDate = selectedEndDate.toDate();

		if (startDate && endDate && !isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
			try {
				const startDateMoment = moment(startDate);
				const endDateMoment = moment(endDate);

				if (!startDateMoment.isSame(endDateMoment, 'day')) {
					message.warning("La date de début et la date de fin doivent être le même jour.");
					setLoading(false);
					return;
				}

				const hoursDifference = endDateMoment.diff(startDateMoment, 'hours', true);
				if (userRole === "Agent") {
					if (hoursDifference < 1) {
						message.warning("Le rendez-vous doit durer au moins une heure.");
						setLoading(false);
						return;
					}
				}

				if (!formData.postal || !formData.note ) {
					Modal.warning({
						title: 'Erreur de Validation',
						content: 'Le code postal et le commentaire sont requis pour bloquer un rendez-vous.',
						okText: 'OK',
					});
					setLoading(false);
					return;
				}

				const startDateFormatted = startDateMoment.format('YYYY-MM-DD HH:mm:ss');
				const endDateFormatted = endDateMoment.format('YYYY-MM-DD HH:mm:ss');

				const formDataToSend = {
					...formData,
					start_date: startDateFormatted,
					end_date: endDateFormatted,
					id_agent: userId,
					id_agenda: selectedDate.agendaId,
					reserver_plage_horaire: false,
					rdv_prive: false,
					status: "encours",
				};
				console.log('form data To Send block rdv', formDataToSend)
				const response = await axiosClient.post("rdvs/bloquer-rdv", formDataToSend);
		console.log('"rdvs/bloquer-rdv called')

				const newAppointment = {
					...response.data,
					id: response.data.rdv.id,
				};
				console.log('res block rdv', response.data)
				setLoading(false);
				// onFormSubmit({ ...response.data, newAppointment });
				setShowBlockModal(false)
				message.success("Rendez-vous bloqué avec succès !");
				
				resetForm();
				setShowAlert(false);

			} catch (error) {
				if (error.response && error.response.status == 409) {
					console.error("Error adding appointment:", error);
					// resetForm();
					setShowAlert(true);
					setLoading(false);
					return;
				}
				setLoading(false);
				// resetForm();
				setShowAlert(false);
				console.error("Error blocking appointment:", error);
			}
		} else {
			resetForm();
			setShowAlert(false);
			setLoading(false);
		}
	};

	const disabledDate = (current) => {
		// Disable weekends (Saturday and Sunday)
		if (current && (current.day() == 0 || current.day() == 6)) {
			return true;
		}

		// Disable previous days
		if (current && current < moment().startOf('day')) {
			return true;
		}

		// Enable only today and the next two working days
		const today = moment().startOf('day');
		const diffDays = current.diff(today, 'days');
		if (moment().day() == 5) { // If today is Friday
			return diffDays !== 0 && diffDays !== 3 && diffDays !== 4;
		}
		return diffDays > 2 || diffDays < 0;
	};

	const disabledTime = (date) => {
		if (date) {
			const hours = Array.from({ length: 24 }, (_, i) => i);
			const disabledHours = hours.filter(hour => hour < 9 || hour > 18);
			return {
				disabledHours: () => disabledHours,
				disabledMinutes: (selectedHour) => selectedHour == 18 ? [15, 30, 45] : [],
			};
		}
		return {};
	};


	return (
		<Form
			onFinish={handleBloquerRdv}
			key={formKey}
			initialValues={initialValues}
		>
			{showAlert && (
				<Alert
					message="La date sélectionnée est déjà réservée."
					type="warning"
					showIcon
					closable
					onClose={() => setShowAlert(false)}
				/>
			)}
			<Card>
				<Row gutter={[16, 16]} style={{ marginBottom: "40px" }}>
					<Col span={9}>
						<ConfigProvider locale={frFR}>
							<DatePicker
								value={selectedStartDate}
								onChange={handleStartDateChange}
								clearIcon={null}
								format="YYYY-MM-DD HH:mm"
								showTime={{ format: "HH:mm", minuteStep: 15 }}
								disabledDate={disabledDate}
								disabledTime={disabledTime}


							/>
						</ConfigProvider>
					</Col>
					<Col span={9}>
						<ConfigProvider locale={frFR}>
							<DatePicker
								value={selectedEndDate}
								onChange={handleEndDateChange}
								clearIcon={null}
								format="YYYY-MM-DD HH:mm"
								showTime={{ format: "HH:mm", minuteStep: 15 }}
								disabled={userRole === "Agent"}
								disabledTime={disabledTime}

							/>
						</ConfigProvider>
					</Col>
					<Col span={6}>
						<Button
							icon={<StopOutlined />}
							loading={loading}
							onClick={handleBloquerRdv}
							style={{ border: "1px solid #FC6736", color: "#FC6736" }}
							className="block-btn"
						>
							Bloquer
						</Button>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<p><span style={{ color: 'red' }}>* </span> Entrez le code postal :</p>
						<Form.Item name="postal" rules={[{ required: true, message: 'Veuillez entrer le code postal' }]}>
							<Input
								placeholder="Code Postal"
								onChange={(e) => {
									const { value } = e.target;
									setFormData({
										...formData,
										postal: value,
									});
								}}
							/>
						</Form.Item>
					</Col>
					<Col span={24}>
						<p style={{ marginTop: "10px", width: "90%" }}><span style={{ color: 'red' }}>* </span> Ajoutez une note :</p>
						<Form.Item name="note" rules={[{ required: true, message: 'Veuillez entrer une note' }]}>
							<Input.TextArea
								rows={4}
								placeholder="note"
								onChange={(e) => {
									const { value } = e.target;
									setFormData({
										...formData,
										note: value,
									});
								}}
							/>
						</Form.Item>
					</Col>
				</Row>

			</Card>
		</Form>
	);
};

export default BlockRdv;
