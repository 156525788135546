import React from 'react';
import StatistiqueCount from './StatistiqueCount';
import { useAuth } from '../../../AuthContext';

const ACDashboard = () => {
	const {userId} = useAuth()

	return (
		<>
			<>
				<StatistiqueCount userId={userId} style={{ marginBottom: '10px' }} />
			</>
		</>
	);
};

export default ACDashboard;
