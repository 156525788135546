import { Card, Col, Row, Spin, Typography } from "antd";
import React, { useEffect, useState } from 'react';
import annulerIcon from '../../assets/images/delivery.gif';
import confirmerIcon from '../../assets/images/approved.gif';
import rdvsIcon from '../../assets/images/calendar.gif';
import clients from '../../assets/images/management.gif';
import nrpIcon from '../../assets/images/no-chat.gif';
import encours from '../../assets/images/wait.gif';
import { axiosClient } from "../../api/axios";
function StatistiquesDemain() {
	const { Title } = Typography;
	const [appointmentStatistics, setAppointmentStatistics] = useState([]);
	const [trueData, setTrueData] = useState(true);


	useEffect(() => {
		fetchAppointmentStatistics();
	}, []);

	const fetchAppointmentStatistics = async () => {
		try {
			const response = await axiosClient.get('rdvs/stats');

			setAppointmentStatistics(response.data);
			setTrueData(true)
		} catch (error) {
			console.error('Error fetching appointment statistics:', error);
		}
	};

	const count = appointmentStatistics.length > 0 ? [
		{
			today: "Rendez-vous encours",
			title: appointmentStatistics[0].encours_count,
			persent: "",
			icon: encours,
			// color: '#FFA62F', // Yellow
		},
		{
			today: "Rendez-vous confirmés",
			title: appointmentStatistics[0].confirmer_count,
			persent: "",
			icon: confirmerIcon,
			// color: '#00E396', // Green
		},
		{
			today: "Rendez-vous annulés",
			title: appointmentStatistics[0].annuler_count,
			persent: "",
			icon: annulerIcon,
			// color: '#EE4E4E', // Red
		},
		{
			today: "Rendez-vous NRP",
			title: appointmentStatistics[0].nrp_count,
			persent: "",
			icon: nrpIcon,
			// color: '#AD88C6',
		},
		{
			today: "Total des Rendez-vous",
			title: appointmentStatistics[0].confirmer_count + appointmentStatistics[0].nrp_count + appointmentStatistics[0].encours_count + appointmentStatistics[0].annuler_count,
			persent: "",
			icon: rdvsIcon,
			// color: '#5755FE', 
		},
		{
			today: "Total des rendez-vous valider",
			title: appointmentStatistics[0].confirmer_count + appointmentStatistics[0].nrp_count,
			persent: "",
			icon: clients,
			color: '', // Default color
		},
	] : [];

	// if (!(eData && lineData && histFullData && trueData)) {
	// 	return (
	// 		<div style={{ textAlign: 'center', marginTop: '50px' }}>
	// 			<Spin size="large" />
	// 		</div>
	// 	);
	// }

	return (
		<>
			<div className="layout-content">
				<Title level={3} style={{ color: "gray" }}>Statistiques des rendez-vous pour demain</Title>
				<Row className="rowgap-vbox" gutter={[24, 0]}>
					{count.map((c, index) => (
						<Col
							key={index}
							xs={24}
							sm={24}
							md={8}
							lg={8}
							xl={8}
							className="mb-24"
						>
							<Card bordered={false} className="criclebox " style={{ height: '120px' }}>
								<div className="number">
									<Row align="middle" gutter={[24, 0]}>

										<Col xs={18}   >
											<span>{c.today}</span>
											<Title level={3} style={{ color: c.color }}>
												{c.title !== undefined ? c.title : null}{" "} <span style={{ fontSize: "15px" }}>  /  {appointmentStatistics[0].date}</span>

											</Title>
										</Col>

										<Col xs={6}>
											<div className="icon-box">
												<img src={c.icon} alt={c.today} style={{ width: '40px', marginBottom: '10px' }} />
											</div>
										</Col>
									</Row>
								</div>
							</Card>
						</Col>
					))}
				</Row>	
			</div>
		</>
	);
}

export default StatistiquesDemain;
