import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Typography, Select, Col, Row, Empty } from 'antd';
import { axiosClient } from '../../api/axios';
import moment from 'moment';
import 'moment/locale/fr';
import styled from 'styled-components';
import { useCalendar } from '../../CalendarContext';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
const { Title } = Typography;
const { Option } = Select;

const StyledButton = styled.button`
    padding: 2px 4px;
    background: transparent;
    border: 2px solid ${props => props.borderColor};
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: ${props => props.borderColor};
        color: white;
    }
`;

function EChart() {
    const [appointments, setAppointments] = useState({});
    const [selectedAgents, setSelectedAgents] = useState([]);
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [currentWeek, setCurrentWeek] = useState(0);
    const { setEData } = useCalendar();

    moment.locale('fr');

    const handleNextWeek = () => {
        setCurrentWeek(prevWeek => prevWeek + 1);
    };

    const handlePreviousWeek = () => {
        setCurrentWeek(prevWeek => prevWeek - 1);
    };

    useEffect(() => {
        let isMounted = true;

        const fetchAgents = async () => {
            try {
							console.log('fetchAgents called')
                const response = await axiosClient.get('users/agent-commercial');
                if (isMounted) {
                    const agents = response.data.users.map(agent => ({
                        id: agent.id,
                        name: `${capitalizeFirstLetter(agent.prenom)} ${capitalizeFirstLetter(agent.nom)}`
                    }));
                    setSelectedAgents(agents);
                }
            } catch (error) {
                console.error('Error fetching agents:', error);
            }
        };

        const fetchAppointments = async () => {
            try {
							console.log('fetchapp called')

                const week = moment().add(currentWeek, 'weeks').format('YYYY-[W]WW');
                const selectedContact = selectedAgent ? `selectedContacts[]=${selectedAgent}` : '';
                const response = await axiosClient.get(`appointments?week=${week}&${selectedContact}`);
                if (isMounted) {
                    const appointmentsData = response.data;
                    setAppointments(appointmentsData);
                    setEData(true);
                }
            } catch (error) {
                console.error('Error fetching appointments:', error);
            }
        };

        fetchAgents();
        fetchAppointments();

        return () => {
            isMounted = false;
        };
    }, [currentWeek, selectedAgent, setEData]);

    const handleAgentChange = value => {
        setSelectedAgent(value);
    };

    const getChartData = (appointments) => {
        const daysOfWeek = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi'];
        const startOfWeek = moment().startOf('isoWeek').add(currentWeek, 'weeks');
        const chartData = {
            categories: [],
            series: []
        };

        if (selectedAgent) {
            const agentData = appointments[selectedAgent] || {};
            const agentAppointments = agentData.appointments || {};
            const data = {
                name: agentData.contact_name || `Agent ${selectedAgent}`,
                data: []
            };

            for (let i = 0; i < 5; i++) {
                const day = startOfWeek.clone().add(i, 'days');
                const formattedDate = day.format('ddd. DD MMM YYYY');
                chartData.categories.push(formattedDate);
                const appointment = agentAppointments[day.format('YYYY-MM-DD')] || { total_confirmed: 0, total_canceled: 0, total_nrp: 0, total_in_progress: 0 };
                const total = appointment.total_confirmed + appointment.total_canceled + appointment.total_nrp + appointment.total_in_progress;

                data.data.push(total);
            }

            chartData.series.push(data);
        }

        return chartData;
    };

    const chartData = getChartData(appointments);

    const chartOptions = {
        chart: {
            type: 'bar',
            width: '100%',
            height: 'auto',
            stacked: true,
            toolbar: {
                show: false,
            },
            background: '#ffffff',
            animations: {
                enabled: true,
                animateEntering: {
                    enabled: true,
                    speed: 800,
                    easing: 'easeinout',
                },
                animateLeaving: {
                    enabled: true,
                    speed: 600,
                    easing: 'easeinout',
                },
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 0,
                columnWidth: '50%', 
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 1,
            colors: ['transparent'],
        },
        grid: {
            show: true,
            borderColor: '#ccc',
            strokeDashArray: 2,
        },
        xaxis: {
            categories: chartData.categories,
            labels: {
                show: true,
                align: 'right',
                style: {
                    colors: ['#000'],
                },
            },
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return Math.floor(val);
                },
            },
            forceNiceScale: true,
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: function (val) {
                    return val.toFixed(0);
                },
            },
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
        },
        colors: ['#83B4FF', '#ff9900', '#3366cc', '#E72929'],
    };

    return (
        <>
            <div className="chart-visitor">
                <Title level={4}>
                    Statistiques du nombre des rendez-vous par semaine {selectedAgent && `/ ${selectedAgents.find(agent => agent.id === selectedAgent)?.name.toUpperCase()}`}
                </Title>
                <Row>
                    <Col span={16}>
                        <Select
                            style={{ width: 400 }}
                            placeholder="Sélectionner un agent commercial"
                            onChange={handleAgentChange}
                            value={selectedAgent}
                            allowClear
                        >
                            {selectedAgents.map((agent) => (
                                <Option key={agent.id} value={agent.id}>
                                    {agent.name}
                                </Option>
                            ))}
                        </Select>
                    </Col>
                    <Col span={1} offset={6}>
                        <StyledButton onClick={handlePreviousWeek} borderColor="#373A40">
                            <LeftOutlined />
                        </StyledButton>
                    </Col>
                    <Col span={1}>
                        <StyledButton onClick={handleNextWeek} borderColor="#373A40">
                            <RightOutlined />
                        </StyledButton>
                    </Col>
                </Row>
            </div>
            <div id="chart">
                {selectedAgent ? (
                    <ReactApexChart
                        options={chartOptions}
                        series={chartData.series}
                        type="bar"
                        height={320}
                    />
                ) : (
                    <Empty description="Aucun agent sélectionné" />
                )}
            </div>
        </>
    );
}

export default EChart;

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
