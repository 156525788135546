import React, { useEffect, useState, useMemo } from "react";
import { Card, Input, Form, Row, Col, ConfigProvider, DatePicker, message, Alert, Button } from "antd";
import frFR from "antd/lib/locale/fr_FR";
import moment from "moment";
import { axiosClient } from "../../../api/axios";
import NewButton from "../../../constants/NewButton";

const AddRdvPrvCalednar = ({ selectedDate, onFormSubmit, userId, agendaId, setShowRdvPrvCalednar }) => {
	const [loading, setLoading] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [formKey, setFormKey] = useState(0);
	const [selectedStartDate, setSelectedStartDate] = useState(null);
	const [selectedEndDate, setSelectedEndDate] = useState(null);

	useEffect(() => {
		if (selectedDate && selectedDate.date) {
			const initialStartDate = moment(selectedDate.date);
			const initialEndDate = moment(selectedDate.date).add(1, 'hour');
			setSelectedStartDate(initialStartDate);
			setSelectedEndDate(initialEndDate);
			setFormData({
				...formData,
				appointment_date: [
					initialStartDate.toDate(),
					initialEndDate.toDate()
				]
			});
		}
	}, [selectedDate]);

	const handleStartDateChange = (date) => {
		setSelectedStartDate(date);
		const endDate = date ? moment(date).add(1, 'hour') : null;
		setSelectedEndDate(endDate);
		setFormData({
			...formData,
			appointment_date: [
				date ? date.toDate() : null,
				endDate ? endDate.toDate() : null
			]
		});
	};

	const handleEndDateChange = (date) => {
		setSelectedEndDate(date);
		setFormData({
			...formData,
			appointment_date: [
				selectedStartDate ? selectedStartDate.toDate() : null,
				date ? date.toDate() : null
			]
		});
	};

	const [formData, setFormData] = useState({
		postal: "",
		appointment_date: selectedDate
			? [
				selectedDate.date && new Date(selectedDate.date),
				selectedDate.date && new Date(selectedDate.date.getTime() + 3600000),
			]
			: null,
	});

	const resetForm = () => {
		setFormKey((prevKey) => prevKey + 1);
		setSelectedStartDate(null);
		setSelectedEndDate(null);
		setFormData({
			postal: "",
			appointment_date: null,
		});
	};

	const initialValues = useMemo(() => ({
		postal: "",
		appointment_date: selectedDate && selectedDate.date
			? [
				new Date(selectedDate.date),
				new Date(selectedDate.date.getTime() + 3600000),
			]
			: null,
	}), [selectedDate]);

	const handleAddRdv = async () => {
		setLoading(true);
		if (!selectedStartDate || !selectedEndDate) {
			setLoading(false);
			return;
		}

		const startDate = selectedStartDate.toDate();
		const endDate = selectedEndDate.toDate();

		if (startDate && endDate && !isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
			const endHour = endDate.getHours();
			const endMinutes = endDate.getMinutes();
			if (endHour > 19 || (endHour == 19 && endMinutes > 0)) {
				message.warning("Attention : l'heure de fin est après 19:00 (7:00 PM).");
				setLoading(false);
				return;
			}
			if (endHour < 9) {
				message.warning("Attention : L'heure de début devrait être 9 heures (9:00 AM) ou plus.");
				setLoading(false);
				return
			}
			try {
				const startDateFormatted = moment(startDate).format('YYYY-MM-DD HH:mm:ss');
				const endDateFormatted = moment(endDate).format('YYYY-MM-DD HH:mm:ss');

				const formDataToSend = {
					...formData,
					start_date: startDateFormatted,
					end_date: endDateFormatted,
					id_agent: userId,
					id_agenda: agendaId,
					rdv_prive: true
				};

				const response = await axiosClient.post("rdvs/add-rdv-prv", formDataToSend);
				const newAppointment = {
					...response.data,
					id: response.data.rdv.id,
				};
				setLoading(false);
				// onFormSubmit({ ...response.data, newAppointment });
				setShowRdvPrvCalednar(false)
				message.success("Rendez-vous ajouté avec succès !");
				resetForm();
				setShowAlert(false);

			} catch (error) {
				if (error.response && error.response.status == 409) {
					console.error("Error adding appointment:", error);
					resetForm();
					setShowAlert(true);
					setLoading(false);
					return;
				}
				setLoading(false);
				console.error("Error adding appointment:", error);
			}
		} else {
			setLoading(false);
		}
	};


	const getMinimumAllowedDate = () => {
    const currentDate = moment().startOf('day');
    let minDate = currentDate.clone();
    let businessDaysCount = 0;

    while (businessDaysCount < 4) {
      minDate.add(1, 'days');
      if (minDate.day() !== 0 && minDate.day() != 6) {
        businessDaysCount++;
      }
    }
    return minDate;
  };

  const disabledDate = (current) => {
    const minDate = getMinimumAllowedDate();
    return current && (current < minDate || current.day() == 0 || current.day() == 6);
  };

	const disabledTime = (date) => {
		if (date) {
			const hours = Array.from({ length: 24 }, (_, i) => i);
			const disabledHours = hours.filter(hour => hour < 9 || hour > 18);
			return {
				disabledHours: () => disabledHours,
				disabledMinutes: (selectedHour) => selectedHour == 19 ? [15, 30, 45] : [],
			};
		}
		return {};
	};

	return (
		<Form
			onFinish={handleAddRdv}
			key={formKey}
			initialValues={initialValues}
		>
			{showAlert && (
				<Alert
					message="La date sélectionnée est déjà réservée pour un RDV encours."
					type="warning"
					showIcon
					closable
					onClose={() => setShowAlert(false)}
				/>
			)}
			<Card>
				<Row gutter={[16, 16]} style={{ marginBottom: "40px" }}>
					<Col span={9}>
						<ConfigProvider locale={frFR}>
							<DatePicker
								value={selectedStartDate}
								onChange={handleStartDateChange}
								clearIcon={null}
								format="YYYY-MM-DD HH:mm"
								showTime={{ format: "HH:mm", minuteStep: 15 }}
								disabledDate={disabledDate}
								disabledTime={disabledTime}
							/>
						</ConfigProvider>
					</Col>
					<Col span={9}>
						<ConfigProvider locale={frFR}>
							<DatePicker
								value={selectedEndDate}
								onChange={handleEndDateChange}
								clearIcon={null}
								format="YYYY-MM-DD HH:mm"
								showTime={{ format: "HH:mm", minuteStep: 15 }}
								disabledDate={disabledDate}
								disabledTime={disabledTime}
							/>
						</ConfigProvider>
					</Col>
					<Col span={6}>
						<NewButton
							onClick={handleAddRdv}
							loading={loading}
							buttonText="Ajouter"
						/>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<p><span style={{ color: 'red' }}>* </span> Entrez le code postal :</p>
						<Form.Item name="postal" rules={[{ required: true, message: 'Veuillez entrer le code postal' }]}>
							<Input
								placeholder="Code Postal"
								onChange={(e) => {
									const { value } = e.target;
									setFormData({
										...formData,
										postal: value,
									});
								}}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Card>
		</Form>
	);
};

export default AddRdvPrvCalednar;
