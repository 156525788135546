import React, { useState, useEffect } from 'react';
import { Button, Card, Space, Table, Tag, Tooltip, Typography } from 'antd';
import 'antd/dist/antd.css';
import { axiosClient } from '../../../api/axios';
import { EyeOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom';
import UserAppointments from '../UserRdv/UserAppointments';
const { Title } = Typography;

const DeletedUsers = () => {
	const [deletedUsers, setDeletedUsers] = useState([]);
	const [selectedUserId, setSelectedUserId] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		// Fetch deleted users from your API endpoint
		fetchDeletedUsers();
	}, []);

	const fetchDeletedUsers = async () => {
		try {
			setLoading(true);
			const response = await axiosClient.get('users/deleted-users');
			setDeletedUsers(response.data);
		} catch (error) {
			console.error('Error fetching deleted users:', error);
		} finally {
			setLoading(false);
		}
	};



	const columns = [
		{
			title: 'Nom',
			dataIndex: 'nom',
			key: 'nom',
		},
		{
			title: 'Prenom',
			dataIndex: 'prenom',
			key: 'prenom',
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
		},

		{
			title: 'Role',
			dataIndex: 'role',
			key: 'role',
			render: (role) => (
				<Tag color="orange">{role}</Tag>
			),
		},
		{
			title: "ACTION",
			key: "action",
			render: (text, record) => (
				<Space>
					<Tooltip title="Afficher les rendez-vous">
						<Link to={`/rendez-vous-utilisateur/${record.id}`}>
							<Button type="link">
								<EyeOutlined />
							</Button>
						</Link>
					</Tooltip>
				</Space>
			),
		},
	];

	return (
		<>

			<Card>
				<Title level={4}>
					Tableau des utilisateurs inactifs
				</Title>
				<Table
					dataSource={deletedUsers}
					columns={columns}
					rowKey="id"
					pagination={{ pageSize: 6 }}
					className="table-container"
					loading={loading}
				/>

				{selectedUserId && (
					<UserAppointments userId={selectedUserId} />
				)}
			</Card>
		</>
	);
};

export default DeletedUsers;
