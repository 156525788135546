import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Layout } from "antd";
import Sidenav from '../layouts/Sidenav';
import Header from "./Header";
import Footer from "./Footer";
import { useAuth } from "../../AuthContext";
import logoExpanded from '../../assets/images/logo-white.png'; // Image for expanded sidebar
import logoCollapsed from '../../assets/images/favicon.png'; // Image for collapsed sidebar
import '../../assets/styles/sidenav.css';
import { baseURL } from "../../api/axios";

function Main({ children }) {
	const { Header: AntHeader, Content, Sider } = Layout;

	// State variables for managing UI states
	const [collapsed, setCollapsed] = useState(false);
	const [visible, setVisible] = useState(false);
	const [sidenavColor, setSidenavColor] = useState("#1890ff");
	const [sidenavType, setSidenavType] = useState("transparent");
	const [fixed, setFixed] = useState(false);
	const [loading, setLoading] = useState(true);
	const [imageLoaded, setImageLoaded] = useState(false);

	const { image } = useAuth();
	let { pathname } = useLocation();
	pathname = pathname.replace("/", "");

	// Toggle the drawer visibility
	const openDrawer = () => setVisible(!visible);

	// Handle the collapse of the Sider
	const onCollapse = (collapsed) => {
		setCollapsed(collapsed);
	};

	// useEffect(() => {
	// 	const fullImageURL = `${baseURL}${image}`;
	// 	const img = new Image();
	// 	img.onload = () => {
	// 		if (img.src === fullImageURL) {
	// 			setImageLoaded(true);
	// 			setLoading(false);
	// 		}
	// 	};
	// 	img.src = fullImageURL;
	// }, [image]);

	// // Return null if image is not loaded yet
	// if (!imageLoaded) {
	// 	return null;
	// }

	return (
		<Layout
			className={`layout-dashboard ${pathname === "profile" ? "layout-profile" : ""} ${pathname === "rtl" ? "layout-dashboard-rtl" : ""}`}
		>
			<Sider
				collapsible
				collapsed={collapsed}
				onCollapse={onCollapse}
				className="side"
				style={{
					backgroundColor: '#61559F',
					position: "fixed",
					left: 0,
					zIndex: 100,
					height: '100vh',
				}}
				width={200}
			>
				<div className="logo-container" style={{
					height: collapsed ? '30px' : '60px',
					width: collapsed ? '30px' : '100px',
					margin: collapsed ?  '30px 24px' : '16px 50px',
				}}>
					<img
						src={collapsed ? logoCollapsed : logoExpanded}
						alt="logo"
						style={{
							height: '100%',
							width: '100%',
							objectFit: 'cover',
							objectPosition: 'center',
						}}
					/>
				</div>
				<Sidenav collapsed={collapsed} onPress={openDrawer} />
			</Sider>

			<Layout style={{ marginLeft: collapsed ? 80 : 200, minHeight: '100vh', overflowX: 'hidden' }}>
				<AntHeader >
					<Header
						onPress={openDrawer}
						name={pathname}
						subName={pathname}
						handleSidenavColor={setSidenavColor}
						handleSidenavType={setSidenavType}
						handleFixedNavbar={setFixed}
					/>
				</AntHeader>
				<Content className="content-ant" >
					{children}
				</Content>
				<Footer />
			</Layout>
		</Layout>
	);
}

export default Main;
