import React, { useState, useEffect, useRef } from "react";
import { useHistory, NavLink } from "react-router-dom";
import { axiosClient, baseURL2, } from "../../api/axios";
import dropdown from '../../assets/images/down-arrow.png';
import profilePic from '../../assets/images/profile-user.png'
import {
	Row,
	Col,
	Breadcrumb,
	Dropdown,
	Button,
	Avatar,
	Menu,
	message,
} from "antd";
import { LogoutOutlined, BellOutlined } from "@ant-design/icons";
import { useAuth } from "../../AuthContext";
import "../../assets/styles/main.css";
import "../../assets/styles/responsive.css";

function Header({ name }) {
	const [profileVisible, setProfileVisible] = useState(false);
	const [notificationVisible, setNotificationVisible] = useState(false);
	const [backupCompleted, setBackupCompleted] = useState(false);
	const [messageCount, setMessageCount] = useState(0);
	const [messagesRead, setMessagesRead] = useState(false);
	const { userRole, username, image } = useAuth();
	const history = useHistory();
	const dropdownRef = useRef(null);



	useEffect(() => {
		const fetchBackupStatus = async () => {
			try {
				const response = await axiosClient.get('notification/status');
				if (response.data.backup_completed && !messagesRead) {
					setBackupCompleted(true);
					setMessageCount(1);
				}
			} catch (error) {
				console.error('Error fetching backup status:', error);
			}
		};

		fetchBackupStatus();
	}, [messagesRead]);

	const logoutSubmit = async () => {
		try {
			const response = await axiosClient.post('logout');
			if (response.status === 200) {
				localStorage.removeItem("auth_token");
				localStorage.removeItem("user_id");
				localStorage.removeItem("auth_name");
				localStorage.removeItem("user_role");
				localStorage.removeItem("email");
				history.push("/login");

				// Display a logout success message
				message.success("Vous êtes déconnecté.");

			} else {
				console.error('Logout failed:', response.data.error);
			}
		} catch (error) {
			console.error('Error occurred during logout:', error);
		}
	};

	// useEffect(() => {
	// 	const handleBeforeUnload = (event) => {
	// 		event.preventDefault();
	// 		event.returnValue = '';
	// 		logoutSubmit();
	// 	};

	// 	window.addEventListener('beforeunload', handleBeforeUnload);

	// 	return () => {
	// 		window.removeEventListener('beforeunload', handleBeforeUnload);
	// 	};
	// }, []); 

	const handleMenuClick = (e) => {
		if (e.key === "logout") {
			logoutSubmit();
		}
	};

	const handleProfileClick = (e) => {
		if (dropdownRef.current && (e.target === dropdownRef.current || dropdownRef.current.contains(e.target))) {
			setProfileVisible(!profileVisible);
			setNotificationVisible(false);
		} else {
			setProfileVisible(false);
		}
	};

	const handleNotificationClick = async () => {
		setNotificationVisible(false);
		setMessagesRead(true); // Mark messages as read on notification click
		setMessageCount(0); // Reset unread message count

		history.push('/backup-files'); // Redirect to backup files page
	};


	const profileMenu = (
		<Menu onClick={handleMenuClick} style={{ width: dropdownRef.current ? dropdownRef.current.offsetWidth : "auto", marginTop: "8px", textAlign: "center" }}>
			<Menu.Item key="logout">
				<LogoutOutlined /> <span>Se déconnecter</span>
			</Menu.Item>
		</Menu>
	);

	const notificationMenu = (
		<Menu style={{ width: "180px", marginTop: "8px", textAlign: "center" }}>
			<Menu.Item key="notification">
				<Button type="link" onClick={handleNotificationClick}>
					Backup disponible
				</Button>
			</Menu.Item>
		</Menu>
	);

	return (
		<div >
			<Row gutter={[24, 0]} >
				<Col span={24} md={6}>
					<Breadcrumb>
						<Breadcrumb.Item>
							<NavLink to="/">MyGuestApp</NavLink>
						</Breadcrumb.Item>
						<Breadcrumb.Item style={{ textTransform: "capitalize" }}>
							{name.replace("/", "").replace(/-/g, ' ')}
						</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
				<Col span={24} md={18} className="header-control">
					<div ref={dropdownRef}>
						<Row gutter={[16, 16]}>
							<Col span={6}>
								<Avatar
									className="shape-avatar"
									shape="circle"
									size={40}

									// src={profilePic}
									src={`${baseURL2}/storage/${image}`}
								></Avatar>
							</Col>
							<Col span={10} style={{ alignItems: "center", textAlign: "center" }}>
								<Col style={{ marginBottom: -4, fontWeight: "700" }}><span>{username}</span> </Col>
								<Col style={{ color: "#6b7280", lineHeight: 1, fontSize: "0.8rem" }}><span>{userRole}</span></Col>
							</Col>
							{['Admin', 'superadmin'].includes(userRole) && (<Col span={4}>

								<Dropdown
									overlay={notificationMenu}
									trigger={["click"]}
									open={notificationVisible}
									onVisibleChange={(flag) => setNotificationVisible(flag)}
									placement="bottomRight"
								>
									<Button
										className={backupCompleted && !messagesRead ? "bell-icon active" : "bell-icon"}
										style={{
											border: "none",
											backgroundColor: "transparent",
											boxShadow: "none",
											padding: 0,
											margin: 0,
											display: "flex",
											alignItems: "center",
											position: "relative",
										}}
									>
										<BellOutlined style={{ fontSize: 20, color: backupCompleted && !messagesRead ? "#ffc107" : "#1890ff" }} />
										{messageCount > 0 && (
											<span className="notification-dot"></span>
										)}
									</Button>
								</Dropdown>


							</Col>)}
							<Col span={4}>
								<Dropdown
									overlay={profileMenu} 
									trigger={["click"]}
									open={profileVisible}
									onVisibleChange={(flag) => setProfileVisible(flag)}
									placement="bottomRight"
								>
									<Button
										onClick={handleProfileClick}
										style={{
											border: "none",
											backgroundColor: "transparent",
											boxShadow: "none",
											padding: 0,
											margin: 0,
											display: "flex",
											alignItems: "center",
										}}
									>
										<img
											src={dropdown}
											alt="dropdown"
											style={{ width: 20, height: 20, marginLeft: 10 }}
										/>
									</Button>
								</Dropdown>
							</Col>
						</Row>
					</div>
				</Col>
			</Row>
		</div>
	);
}

export default Header;
