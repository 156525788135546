import React, { useState, useEffect } from "react";
import { DatePicker, Input, Form, Card, Row, Col, message, Alert, ConfigProvider, Modal } from "antd";
import moment from "moment";
import { axiosClient } from "../../../api/axios";
import NewButton from "../../../constants/NewButton";
import frFR from "antd/lib/locale/fr_FR";
import SupprimerButton from "../../../constants/SupprimerButton";

const UpdatePrivateAppointmentModal = ({ appointmentData, onFormSubmit, setShowUpdate, appointments, setAppointments,setUserAppointments }) => {
	const [formData, setFormData] = useState({
		start_date: appointmentData?.start_date ? moment(appointmentData.start_date) : null,
		end_date: appointmentData?.end_date ? moment(appointmentData.end_date) : null,
		postal: appointmentData?.postal || "",
	});
	const rdvList = appointmentData.rdv || appointmentData;


	useEffect(() => {
		if (rdvList) {
			const formattedInitialValues = {
				start_date: rdvList?.start_date ? moment(rdvList.start_date) : null,
				end_date: rdvList?.end_date ? moment(rdvList.end_date) : null,
				postal: rdvList?.postal || "",
			};
			setFormData(formattedInitialValues);
			setSelectedStartDate(rdvList.start_date ? moment(rdvList.start_date) : null)
			setSelectedEndDate(rdvList?.end_date ? moment(rdvList.end_date) : null)
		}


	}, [rdvList]);


	const [loading, setLoading] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [selectedStartDate, setSelectedStartDate] = useState(null);
	const [selectedEndDate, setSelectedEndDate] = useState(null);

	const handleStartDateChange = (date) => {
		setSelectedStartDate(date);
		setFormData({
			...formData,
			appointment_date: [
				date ? date.toDate() : null,
				selectedEndDate ? selectedEndDate.toDate() : null
			]
		});
	};
	const handleEndDateChange = (date) => {
		setSelectedEndDate(date);
		setFormData({
			...formData,
			appointment_date: [
				selectedStartDate ? selectedStartDate.toDate() : null,
				date ? date.toDate() : null
			]
		});
	};
	const handleFormSubmit = async () => {
		setLoading(true);

		if (!selectedStartDate || !selectedEndDate) {
			setLoading(false);
			return;
		}

		const startDate = selectedStartDate.toDate();
		const endDate = selectedEndDate.toDate();

		if (startDate && endDate && !isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
			try {
				const startDateFormatted = moment(startDate).format('YYYY-MM-DD HH:mm:ss');
				const endDateFormatted = moment(endDate).format('YYYY-MM-DD HH:mm:ss');
				
				const formDataToSend = {
					...formData,
					start_date: startDateFormatted,
					end_date: endDateFormatted,
					id_agent: rdvList.id_agent,
					id_agenda: rdvList.id_agenda,
					postal: formData.postal,
				};
				console.log('formDataToSend', formDataToSend)
				const response = await axiosClient.put(
					`rdvs/update-rdv-prv/${rdvList.id}`,
					formDataToSend
				);
				const newAppointment = {
					...response.data,
					id: response.data.rdv.id,
				};
				console.log('response', response.data)
				setLoading(false);
				onFormSubmit({ ...response.data, newAppointment });
				setShowUpdate(false)
				message.success("Rendez-vous ajouté avec succès !");
				setShowAlert(false);

			} catch (error) {
				if (error.response && error.response.status == 409) {
					console.error("Error adding appointment:", error);
					setShowAlert(true);
					setLoading(false);
					return;
				}
				setLoading(false);
				console.error("Error adding appointment:", error);
			}
		} else {
			setLoading(false);
		}
	};

	const supprimerRdvPrv = () => {
		Modal.confirm({
			title: "Êtes-vous sûr de vouloir supprimer ce rendez-vous ?",
			content: "Cette action est irréversible.",
			okText: "Oui",
			okType: "danger",
			cancelText: "Non",
			onOk: async () => {
				try {
					setLoading(true);
					const res = await axiosClient.delete(`rdvs/${rdvList.id}`);
					console.log('res', res.data)
					setLoading(false);
					setShowUpdate(false);
					const updatedAppointments = appointments.filter(
						(appointment) => appointment.id != rdvList.id
					);
					setAppointments(updatedAppointments);
					// setDeletedAppointmentIds((prevIds) => [...prevIds, id]);
					message.success("Rendez-vous supprimé avec succès !");
				} catch (error) {
					setLoading(false);
					console.error("Erreur lors de la suppression du rendez-vous:", error);
					message.error("Erreur lors de la suppression du rendez-vous.");
				}
			}
		});
	};
	


	const handlePostalChange = (e) => {
		setFormData({ ...formData, postal: e.target.value });
	};

	const getMinimumAllowedDate = () => {
    const currentDate = moment().startOf('day');
    let minDate = currentDate.clone();
    let businessDaysCount = 0;

    while (businessDaysCount < 4) {
      minDate.add(1, 'days');
      if (minDate.day() != 0 && minDate.day() !== 6) {
        businessDaysCount++;
      }
    }
    return minDate;
  };

  const disabledDate = (current) => {
    const minDate = getMinimumAllowedDate();
    return current && (current < minDate || current.day() == 0 || current.day() == 6);
  };
	const disabledTime = (date) => {
		if (date) {
			const hours = Array.from({ length: 24 }, (_, i) => i);
			const disabledHours = hours.filter(hour => hour < 9 || hour > 19);
			return {
				disabledHours: () => disabledHours,
				disabledMinutes: (selectedHour) => selectedHour == 19 ? [15, 30, 45] : [],
			};
		}
		return {};
	};

	return (
		<Form layout="vertical" onFinish={handleFormSubmit}>
			{showAlert && (
				<Alert
					message="Erreur lors de la mise à jour du rendez-vous."
					type="error"
					showIcon
					closable
					onClose={() => setShowAlert(false)}
				/>
			)}
			<Card>
				<Row justify="end">
					<Col span={6}>

						<SupprimerButton
							onClick={supprimerRdvPrv}
							loading={loading}
							buttonText="Supprimer"
						/>
					</Col>

					<Col span={6}>

						<NewButton
							onClick={handleFormSubmit}
							loading={loading}
							buttonText="Enregistrer"
						/>
					</Col>
				</Row>

			</Card>
			<Card style={{ marginTop: "10px" }}>
				<Row gutter={[16, 16]} style={{ marginBottom: "40px" }}>
					<Col span={9}>
						<ConfigProvider locale={frFR}>
							<DatePicker
								value={selectedStartDate}
								onChange={handleStartDateChange}
								clearIcon={null}
								format="YYYY-MM-DD HH:mm"
								showTime={{ format: "HH:mm", minuteStep: 15 }}
								disabledDate={disabledDate}
								disabledTime={disabledTime}

							/>
						</ConfigProvider>

					</Col>
					<Col span={9}>
						<ConfigProvider locale={frFR}>
							<DatePicker
								value={selectedEndDate}
								onChange={handleEndDateChange}
								clearIcon={null}
								format="YYYY-MM-DD HH:mm"
								showTime={{ format: "HH:mm", minuteStep: 15 }}
								disabledDate={disabledDate}
								disabledTime={disabledTime}
							/>
						</ConfigProvider>
					</Col>

				</Row>
				<Row gutter={[16, 16]} style={{ marginBottom: "40px" }}>
					<Col span={24}>
						<p><span style={{ color: 'red' }}>* </span> Entrez le code postal :</p>
						<Input
							placeholder="Code Postal"
							value={formData.postal}
							onChange={handlePostalChange}
						/>
					</Col>
				</Row>
			</Card>
		</Form>
	);
};

export default UpdatePrivateAppointmentModal;
