// CalendarContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import Cookies from 'js-cookie';
const CalendarContext = createContext();

export const useCalendar = () => useContext(CalendarContext);

export const CalendarProvider = ({ children }) => {
	const [modifiedAgent, setModifiedAgent] = useState("");
	const [showDuplicate, setShowDuplicateModal] = useState(false);
	const [deletedAppointmentIds, setDeletedAppointmentIds] = useState([]);
	const [agentCommercialColor, setAgentCommercialColor] = useState([]);

	const [histFullData, setHistFullData] = useState(false);
	const [lineData, setLineData] = useState(false);
	const [eData, setEData] = useState(false);
	const [showAddModal, setShowAddModal] = useState(false);
	const [appointments, setAppointments] = useState([]);
	const [selectedDate, setSelectedDate] = useState(null);
	const [loading, setLoading] = useState(true);
	const [addAgendaModalVisible, setAddAgendaModalVisible] = useState(false);
	const [agentCommercialUsers, setAgentCommercialUsers] = useState([]);
	const [contacts, setContacts] = useState([]);
	const [agendas, setAgendas] = useState([]);
	const [selectedItems, setSelectedItems] = useState([]);
	const [agentId, setAgentId] = useState(null);
	const [agendaId, setAgendaId] = useState(null);
	const [selectedAppointment, setSelectedAppointment] = useState(null);
	const [showUpdateModal, setShowUpdateModal] = useState(false);
	const [showDetailsModal, setShowDetailModal] = useState(false);
	const [selectedRowData, setSelectedRowData] = useState(null);
	const [appointmentDetails, setAppointmentDetails] = useState(null);
	const [contactName, setContactName] = useState("");
	const [contactEmail, setContactEmail] = useState("");
	const [contactName2, setContactName2] = useState("");
	const [contactEmail2, setContactEmail2] = useState("");
	const [agentName, setAgentName] = useState("");
	const [selectedContacts, setSelectedContacts] = useState(null);
	const [contactAgendas, setContactAgendas] = useState([]);
	const [userAgenda, setUserAgenda] = useState(null);
	const [userAppointments, setUserAppointments] = useState([]);
	// rdv table state
	const [rdvLoading, setRdvLoading] = useState(false);
	const [selectedAgent, setSelectedAgent] = useState(undefined);
	const [selectedAgenda, setSelectedAgenda] = useState(undefined);
	const [selectedDateRange, setSelectedDateRange] = useState([]);
	const [agentOptions, setAgentOptions] = useState([]);
	const [agendaOptions, setAgendaOptions] = useState([]);
	const [timers, setTimers] = useState({});
	const [isDisabledAgendas, setIsDisabledAgendas] = useState({});
	const [clearForm, setClearForm] = useState(false);
	const [fetch, setFetch] = useState(false);



	

  const setTimer = (appointmentId, endTime) => {
    setTimers(prevTimers => ({
      ...prevTimers,
      [appointmentId]: endTime,
    }));
    Cookies.set(`timerEndTime_${appointmentId}`, endTime.toString(), { expires: 7 });
  };

  useEffect(() => {
    const storedTimers = {};
    for (const key in Cookies.get()) {
      if (key.startsWith('timerEndTime_')) {
        const appointmentId = key.split('_')[1];
        storedTimers[appointmentId] = parseInt(Cookies.get(key), 10);
      }
    }
    setTimers(storedTimers);
  }, []);



	return (
		<CalendarContext.Provider
			value={{
				clearForm, setClearForm,
				isDisabledAgendas, setIsDisabledAgendas,contacts, setContacts,
				eData, setEData,
				lineData, setLineData,
				histFullData, setHistFullData,
				rdvLoading, setRdvLoading,
				selectedAgent, setSelectedAgent,
				selectedAgenda, setSelectedAgenda,
				selectedDateRange, setSelectedDateRange,
				agentOptions, setAgentOptions,
				agendaOptions, setAgendaOptions,timers, setTimers,setTimer,
				showAddModal,
				setShowAddModal,showDuplicate, setShowDuplicateModal,
				appointments,
				setAppointments,
				selectedItems,
				setSelectedItems,
				agentId,
				setAgentId,
				agendaId,
				setAgendaId,
				selectedAppointment,
				setSelectedAppointment,
				showUpdateModal,fetch, setFetch,
				setShowUpdateModal,
				showDetailsModal,
				setShowDetailModal,
				selectedRowData,
				setSelectedRowData,
				appointmentDetails,deletedAppointmentIds, setDeletedAppointmentIds,
				setAppointmentDetails,
				selectedDate,
				setSelectedDate,
				loading,
				setLoading,
				addAgendaModalVisible,
				setAddAgendaModalVisible,
				agentCommercialUsers,
				agentCommercialColor, setAgentCommercialColor,
				setAgentCommercialUsers,
				agendas, modifiedAgent, setModifiedAgent,
				setAgendas,
				contactName,
				setContactName,
				contactEmail,
				setContactEmail, contactName2,
				setContactName2,
				contactEmail2,
				setContactEmail2,
				userAgenda, setUserAgenda, userAppointments, setUserAppointments,
				agentName, setAgentName, selectedContacts, setSelectedContacts, contactAgendas, setContactAgendas,
			}}
		>
			{children}
		</CalendarContext.Provider>
	);
};
