import React from 'react';
import { Button } from 'antd';
import './ButtonStyle.css';
import { DeleteOutlined } from "@ant-design/icons";


const SupprimerButton = ({ onClick, loading, buttonText }) => {
	return (

		<div>
			<Button className="supp-button" onClick={onClick} loading={loading} danger ghost icon={<DeleteOutlined />}>
				{buttonText}
			</Button>

		</div>
	);
};

export default SupprimerButton;
