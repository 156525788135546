import emailjs from 'emailjs-com';
import { axiosClient, axiosSanctum } from '../../../api/axios';

import { message, Modal } from "antd";

const tempId = "template_gcza85i"
const serviceId = "service_n3zi7eg"
const key = "Ki-Vc1ucXjgbwgru_"



export const handleForgotPasswordSubmit = async (form, setUserData) => {
	try {
		const email = form.getFieldValue('email');
		if (!email) {
			message.error('Veuillez saisir votre adresse e-mail avant de demander la réinitialisation du mot de passe.');
			return;
		}
		const res = await axiosClient.get(`user/${email}/id`);
		setUserData(res.data.user)

		const userId = res.data.user.id;
		// const resetLink = `https://frontend.myguestapp.com/update-user/${userId}`;
		const resetLink = `http://localhost:3000/update-user/${userId}`;

		const response = await axiosClient.get("users");
		const adminUsers = response.data.users.filter(user => user.role === 'Admin');
		const adminEmails = adminUsers.map(admin => admin.email);
		adminEmails.forEach(async (adminEmail) => {
			try {
				await emailjs.send(serviceId, tempId, {
					to_email: adminEmail,
					subject: "Demande de réinitialisation de mot de passe",
					from_email: "contact@sunlight-call.com",
					message: `Un utilisateur a demandé la réinitialisation de son mot de passe. Cliquez <a href="${resetLink}">ici</a> pour mettre à jour le mot de passe de l'utilisateur.`,
				}, key);

			} catch (error) {
				console.error("Error sending email to admin:", error);
				message.error("Une erreur s'est produite lors de l'envoi de l'email aux administrateurs.");
			}
		});
		message.info("Un email vous sera envoyé avec le nouveau mot de passe après que l'administrateur ait changé votre mot de passe.", 10);
	} catch (error) {
		console.error("Error handling forgot password:", error);
		message.error("Une erreur s'est produite lors de la demande de réinitialisation du mot de passe.");
	}
};


export const handleLogin = async (values, setLoading, handleLoginSuccess, history, setAlertMessage, setAlertVisible) => {
	console.log('handleLogin called with values:', values); 
	setLoading(true);

	const data = {
		email: values.email,
		password: values.password,
	};

	const currentTime = new Date();
	const currentHour = currentTime.getHours();
	const currentMinute = currentTime.getMinutes();

	const isWithinWorkingHours = (currentHour > 8 || (currentHour === 8 && currentMinute >= 0)) &&
								(currentHour < 17 || (currentHour === 17 && currentMinute <= 30));

	try {
		console.log('Attempting to obtain CSRF token'); 
		// Obtain CSRF token
		const csrfResponse = await axiosSanctum.get("/sanctum/csrf-cookie");
		if (csrfResponse.status === 204) {
			console.log('CSRF token obtained successfully.'); 
		} else {
			console.log('Unexpected response:', csrfResponse);
		}

		console.log('Attempting to login with data:', data); 
		// Make login request
		const loginResponse = await axiosClient.post("login", data);
		console.log('Login response:', loginResponse);

		setLoading(false);

		if (loginResponse.data.status === 200) {
			const { token, nom, role, id, email, image, isActive } = loginResponse.data;

			if (isActive == 0) {
				Modal.warning({
					title: "Votre compte est suspendu",
					content: "Veuillez contacter l'administrateur pour plus d'informations.",
					okText: "OK",
				});
				return;
			}

			if (role === "Agent" && !isWithinWorkingHours) {
				Modal.warning({
					title: "Accès non autorisé",
					content: "Les connexions pour les Agent sont autorisées uniquement pendant les heures de travail (8:00 AM - 5:30 PM).",
					okText: "OK",
				});
				return;
			}

			handleLoginSuccess({
				token,
				id,
				name: `${nom} `,
				role,
				email,
				image,
			});

			switch (role) {
				case "Admin":
					history.push("/calendrier");
					break;
				case "Superviseur":
				case "Agent":
					history.push("/calendrier");
					break;
				case "Agent Commercial":
					history.push("/AC-calendrier");
					break;
				default:
					history.push("/calendrier");
					break;
			}
			message.success(`Bienvenue, ${nom} - ${role} !`);
		} else {
			console.error("Échec de la connexion :", loginResponse.data.message);
			setAlertVisible(true);
			setAlertMessage(loginResponse.data.message);
		}
	} catch (error) {
		setLoading(false);
		if (error.response) {
			if (error.response.status === 401) {
				setAlertVisible(true);
				setAlertMessage("Adresse e-mail ou mot de passe incorrect. Veuillez réessayer.");
			} else {
				console.error("Erreur lors de la connexion :", error.response.data);
			}
		} else {
			console.error("Une erreur s'est produite lors de la connexion :", error);
		}
	}
};





export default handleLogin;
