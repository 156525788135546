import React, { useState, useEffect } from "react";
import { Table,  Card, Modal } from "antd";
import { SyncOutlined, EyeOutlined,  } from '@ant-design/icons';
import SingleRdvSupprimerDetail from "./SingleRdvSupprimerDetail"
import {handleDownloadAppointment, fetchDeletedAppointments, handleRestoreAppointment} from "../services/api"

const { confirm } = Modal;

const HistoriqueRdvSupprimer = () => {
	const [appointments, setAppointments] = useState([]);
	const [selectedAppointment, setSelectedAppointment] = useState(null);
	const [isModalVisible, setIsModalVisible] = useState(false);

	useEffect(() => {
		fetchDeletedAppointments(setAppointments);
	}, []);

	const handleRestore = (id) => {
		confirm({
				title: "Confirmation",
				content: "Êtes-vous sûr de vouloir restaurer cet rendez-vous ?",
				okText: "Oui",
				cancelText: "Non",
				onOk() {
						handleRestoreAppointment(id, setAppointments);
				},
		});
};
	

	const handleViewAppointment = (appointment) => {
		setSelectedAppointment(appointment);
		setIsModalVisible(true);
	};

	


	const columns = [
		{
			title: "Client",
			dataIndex: "client",
			key: "client",
			render: (_, record) => {
				if (record.prenom && record.prenom !== "null") {
					return `${record.nom} ${record.prenom}`;
				} else {
					return record.nom;
				}
			}
		},
		{
			title: "Agent",
			dataIndex: "agent",
			key: "agent",
			render: (_, record) => {
				return `${record.user.nom} ${record.user.prenom}`;
			}
		},
		{
			title: "Agenda",
			dataIndex: "agenda",
			key: "agenda",
			render: (_, record) => {
				return `${record.agenda?.user?.nom} ${record.agenda?.user?.prenom}`;
			}
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status"
		},
		{
			title: "TEL",
			dataIndex: "tel",
			key: "tel"
		},
		{
			title: "Postal",
			dataIndex: "postal",
			key: "postal"
		},
		{
			title: "Date du rendez-vous",
			dataIndex: "start_date",
			key: "start_date"
		},
		{
			title: "Date du suppression",
			dataIndex: "deleted_at",
			key: "deleted_at"
		},
		{
			title: "Supprimé par",
			dataIndex: "deleted_by",
			key: "deleted_by",
			render: (deletedBy) => {
				if (deletedBy) {
					return `${deletedBy.nom} ${deletedBy.prenom}`;
				} else {
					return "Inconnu";
				}
			}
		},
		{
			title: 'Actions',
			dataIndex: 'actions',
			fixed: 'right',
			key: 'actions',
			render: (_, record) => (
					<>
							<EyeOutlined style={{ color: 'blue', marginRight: 8, fontSize: "16px" }} onClick={() => handleViewAppointment(record)} />
							{/* <SyncOutlined style={{ color: 'orange', marginRight: 8, fontSize: "16px" }} onClick={() => handleRestore(record.id)} /> */}
					</>
			),
	},
	];
	
	const handleDownload = (appointment) => {
    handleDownloadAppointment(appointment);
  };

	return (
    <Card>
      <Table
        dataSource={appointments}
        columns={columns}
        pagination={{ pageSize: 6 }}
        className="table-container"
        rowKey="id"
      />

      <SingleRdvSupprimerDetail
        appointment={selectedAppointment}
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        onDownload={handleDownload}
      />
    </Card>
  );
};

export default HistoriqueRdvSupprimer;